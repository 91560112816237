import IPaciente from "../types/Paciente";
import moment from 'moment';
import 'moment/locale/es';
import ILog from "../types/Log";
import LogService from "../services/LogService";
import * as constantes from "../common/Constantes";
import jsPDF from 'jspdf';
import IDocumento from "../types/Documento";
import ICita from "../types/Cita";
import AgendaUtils from "./Agenda";
import ConfigUtils from "./Config";
import Utilities from "../common/Utilities";
import { resources } from "../common/Resources";
import IConsentimientoDoc from "../types/CI";

moment.locale(constantes.momentLocale);

const formatCI = (paciente: IPaciente, signature: any, esMenor: boolean, email: boolean, whatsApp: boolean, CI: IConsentimientoDoc | null, clinicDomicilio: string, clinicTelefono: string, clinicEmail: string, clinicName: string, clinicCity: string): ArrayBuffer => {
    try {

        const doc = new jsPDF('p', 'mm', 'a4');

        doc.addFileToVFS('Montserrat-Medium.ttf', constantes.montserrat_font);
        doc.addFont('Montserrat-Medium.ttf', 'Montserrat', 'normal');
        doc.addFileToVFS('Montserrat-Bold.ttf', constantes.montserrat_fontBold);
        doc.addFont('Montserrat-Bold.ttf', 'Montserrat', 'bold');

        doc.setFont('Montserrat', 'bold');
        doc.setFontSize(22);
        doc.text('Protección de datos', 21.5, 28.5, {maxWidth: 150});

        doc.setFont('Montserrat', 'normal');
        doc.setFontSize(11);
        doc.text(resources.CI.title, 21.5, 42, {maxWidth: 160});

        doc.setFontSize(9);

        doc.text(resources.CI.responsable, 21.5,52, {maxWidth: 165});
        doc.text(resources.CI.responsableTexto
            .replace("{0}", CI?.responsable != undefined ? CI.responsable : '')
            .replace("{1}", CI?.responsableNIF != undefined ? CI.responsableNIF : '')
            .replace("{2}", clinicDomicilio)
            .replace("{3}", clinicTelefono)
            .replace("{4}", clinicEmail)
            .replace("{7}", CI?.responsableNIF != undefined ? Utilities.getSpanichIDType(CI.responsableNIF) : '')
        , 21.5,57, {maxWidth: 165});
        doc.text(resources.CI.finalidad, 21.5,67, {maxWidth: 165});
        doc.text(resources.CI.finalidadTexto, 21.5,72, {maxWidth: 165});

        doc.text(resources.CI.datos, 21.5,90, {maxWidth: 165});
        doc.text(resources.CI.datosTexto, 21.5,95, {maxWidth: 165});

        doc.text(resources.CI.acceso, 21.5,105, {maxWidth: 165});
        doc.text(resources.CI.accesoTexto.replace("{5}", clinicName), 21.5,110, {maxWidth: 165});

        doc.text(resources.CI.legitimacion, 21.5,124, {maxWidth: 165});
        doc.text(resources.CI.legitimacionTexto, 21.5,129, {maxWidth: 165});

        doc.text(resources.CI.destinatarios, 21.5,135, {maxWidth: 165});
        doc.text(resources.CI.destinatariosTexto, 21.5,140, {maxWidth: 165});

        doc.text(resources.CI.plazo, 21.5,150, {maxWidth: 165});
        doc.text(resources.CI.plazoTexto, 21.5,155, {maxWidth: 165});

        doc.text(resources.CI.derechos, 21.5,165, {maxWidth: 165});
        doc.text(resources.CI.derechosTexto, 21.5,170, {maxWidth: 165});

        doc.text(resources.CI.seguridad, 21.5,184, {maxWidth: 165});
        doc.text(resources.CI.seguridadTexto, 21.5,189, {maxWidth: 165});

        doc.text(resources.CI.autorizacion, 21.5,199, {maxWidth: 165});
        
        doc.rect(21.5, 202, 3, 3);
        doc.line(21.5, 202, 24.5, 205);
        doc.line(21.5, 205, 24.5, 202);
        doc.text(resources.CI.authIncorporacion, 26, 204.5, {maxWidth: 165});

        doc.rect(21.5, 207.5, 3, 3);
        doc.line(21.5, 207.5, 24.5, 210.5);
        doc.line(21.5, 210.5, 24.5, 207.5);
        doc.text(resources.CI.authConfirmacion, 26, 210, {maxWidth: 165});

        if (email) {
            doc.rect(21.5, 213, 3, 3);
            doc.line(21.5, 213, 24.5, 216);
            doc.line(21.5, 216, 24.5, 213);
            doc.text(resources.CI.authEmail, 26, 215.5, {maxWidth: 165});
        } else {
            doc.rect(21.5, 213, 3, 3);
            doc.text(resources.CI.authEmail, 26, 215.5, {maxWidth: 165});
        }

        if (whatsApp) {
            doc.rect(21.5, 218.5, 3, 3);
            doc.line(21.5, 218.5, 24.5, 221.5);
            doc.line(21.5, 221.5, 24.5, 218.5);
            doc.text(resources.CI.authWhatsApp, 26, 221, {maxWidth: 165});
        } else {
            doc.rect(21.5, 218.5, 3, 3);
            doc.text(resources.CI.authWhatsApp, 26, 221, {maxWidth: 165});
        }

        doc.text(resources.CI.tratamiento, 21.5,228, {maxWidth: 165});
        doc.text(resources.CI.tratamientoTexto.replace("{6}", CI?.texto != undefined ? CI.texto : ''), 21.5,233, {maxWidth: 165});

        //En función de las líneas de tratamientoTexto
        const height = doc.getTextDimensions(resources.CI.tratamientoTexto.replace("{6}", CI?.texto != undefined ? CI.texto : '')).h;
        const height2 = doc.getTextDimensions(resources.CI.tratamientoTexto.replace("{6}", CI?.texto != undefined ? CI.texto : ''), { fontSize: 9, maxWidth: 165 }).h;

        doc.rect(21.5, 233 + height2, 3, 3);
        doc.line(21.5, 233 + height2, 24.5, 233 + height2 + height);
        doc.line(21.5, 233 + height2 + height, 24.5, 233 + height2);
        doc.text(resources.CI.tratamientoConfirmacion, 26, 233 + height2 + height - 0.5, {maxWidth: 165});

        doc.text(`${clinicCity.toLocaleUpperCase()}, a ${new Date().getDate()} de ${constantes.monthNames[new Date().getMonth()]} de ${new Date().getFullYear()}`, 75,260, {maxWidth: 170});
        doc.text(`Firmado: ${esMenor ? `${paciente.nombreTutor} ${paciente.apellidosTutor}, tutor legal de ${paciente.nombre} ${paciente.apellidos}` : `${paciente.nombre} ${paciente.apellidos}`}`, 21.5,273, {maxWidth: 170});
        doc.text(`${esMenor ? Utilities.getSpanichIDType(paciente.DNItutor) : Utilities.getSpanichIDType(paciente.DNI)}: ${esMenor ? paciente.DNItutor : paciente.DNI}`, 21.5,278, {maxWidth: 170});
        doc.addImage(signature, 84, 240, 50, 50);
        
        let document = doc.output('arraybuffer');
        return document;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Doc.formatCI', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        throw e;
    }
};

const formatJustificanteCita = (paciente: IPaciente, cita: ICita, esMenor: boolean) => {
    try {
        const doc = new jsPDF('l', 'mm', 'a5');

        doc.addFileToVFS('Montserrat-Medium.ttf', constantes.montserrat_font);
        doc.addFont('Montserrat-Medium.ttf', 'Montserrat', 'normal');
        doc.addFileToVFS('Montserrat-Bold.ttf', constantes.montserrat_fontBold);
        doc.addFont('Montserrat-Bold.ttf', 'Montserrat', 'bold');

        if (Utilities.isMoheUser()) {
            doc.addImage(constantes.justificante_template, 0, 0, 210, 148);

            doc.setTextColor(34, 136, 72);
            doc.setFont('Montserrat', 'bold');
            doc.setFontSize(22);
            doc.text('Justificante de asistencia', 21.5, 25, {maxWidth: 150});
    
            doc.setFont('Montserrat', 'normal');
            doc.setFontSize(9);
            doc.text(`El paciente ${paciente.nombre} ${paciente.apellidos} con ${esMenor ? Utilities.getSpanichIDType(paciente.DNItutor) : Utilities.getSpanichIDType(paciente.DNI)}: ${esMenor ? `${paciente.DNItutor} (tutor legal)` : paciente.DNI}`, 21.5,45, {maxWidth: 170, align: "justify"});
            doc.text(`Acudió a esta clínica de fisioterapia el ${AgendaUtils.formatCitaDate(cita)} ${AgendaUtils.formatCitaTime(cita)} horas.`, 21.5,50, {maxWidth: 170, align: "justify"});
            doc.text('Sirva este documento para acreditar la presencia en la clínica mohe al paciente antes mencionado y como justificante de falta de asistencia.', 21.5,60, {maxWidth: 160, align: "justify"});
    
            doc.text(`En Logroño, a ${new Date().getDate()} de ${constantes.monthNames[new Date().getMonth()]} de ${new Date().getFullYear()}`, 125,87, {maxWidth: 170});
            doc.addImage(constantes.logo_img, 21.5, 95, 25.92, 30.15);
            doc.addImage(constantes.sello_img, 130, 90, 50.45, 32.73);
        } else {
            const asistenciaDoc = ConfigUtils.getAsistenciaDoc();

            if (!asistenciaDoc) return;

            doc.setFont('Montserrat', 'bold');
            doc.setFontSize(22);
            doc.text('Justificante de asistencia', 21.5, 25, {maxWidth: 150});

            doc.setFont('Montserrat', 'normal');
            doc.setFontSize(9);
            doc.text(`El paciente ${paciente.nombre} ${paciente.apellidos} con ${esMenor ? Utilities.getSpanichIDType(paciente.DNItutor) : Utilities.getSpanichIDType(paciente.DNI)}: ${esMenor ? `${paciente.DNItutor} (tutor legal)` : paciente.DNI}`, 21.5,45, {maxWidth: 170, align: "justify"});
            doc.text(`Acudió a esta clínica de fisioterapia el ${AgendaUtils.formatCitaDate(cita)} ${AgendaUtils.formatCitaTime(cita)} horas.`, 21.5,50, {maxWidth: 170, align: "justify"});
            doc.text(asistenciaDoc.texto, 21.5,60, {maxWidth: 160, align: "justify"});

            doc.text(`En ${ConfigUtils.getClinicCity()}, a ${new Date().getDate()} de ${constantes.monthNames[new Date().getMonth()]} de ${new Date().getFullYear()}`, 125,87, {maxWidth: 170});
            doc.addImage(asistenciaDoc.sello, 130, 90, 50.45, 32.73);
        }
        
        let document = doc.output('datauristring');
        return document;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Doc.formatJustificanteCita', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return 'Error';
    }
};

const formatDoc = async (file: File, pacienteId: string | undefined) => {

    try {

        if (pacienteId === undefined) return null;

        let documento: IDocumento = { nombre: '', tipo: '', documento: null, pacienteId: '' };

        const parts = file.name.split('.');
        const fileExtension = parts.length > 1 ? parts.pop()?.toLowerCase() || '' : '';

        await getBinary(file).then((data) => {
            documento = {
                nombre: file.name,
                tipo: fileExtension,
                documento: data,
                pacienteId: pacienteId
            };
            return documento;
        });

        return documento;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Doc.formatDoc', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return null;
    }
};

const getBinary = (file: File): Promise<ArrayBuffer> => {

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    return new Promise((resolve, reject) => {
        reader.onload = () => {
            resolve(reader.result as ArrayBuffer);
        }
        reader.onerror = () => {
            reject(reader.error);
        };
    });

};

const DocUtils = {
    formatCI,
    formatDoc,
    formatJustificanteCita
};

export default DocUtils;