import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import AgendaAct from './components/activities/AgendaAct';
import Agenda from './components/agenda/Agenda';
import CitaForm from './components/agenda/CitaForm';
import ResetPass from './components/auth/ResetPass';
import Consentimiento from './components/consentimiento/Consentimiento';
import TutorForm from './components/consentimiento/TutorForm';
import Home from './components/home/Home';
import Navbar from './components/navbar/Navbar';
import HistoriaClinicaForm from './components/pacientes/paciente/HistoriaClinicaForm';
import Paciente from './components/pacientes/paciente/Paciente';
import PacienteForm from './components/pacientes/paciente/PacienteForm';
import Pacientes from './components/pacientes/Pacientes';
import Config from './components/config/Config';
import * as constantes from "./common/Constantes";
import AddToCalendar from './components/agenda/AddToCalendar';
import CreatePassEmpleado from './components/auth/CreatePassEmpleado';

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path={constantes.R_HOME} element={<Home />} />
        <Route path={constantes.R_PACIENTES} element={<Pacientes />} />
        <Route path={constantes.R_PACIENTE} element={<Paciente />} />
        <Route path={constantes.R_NUEVOPACIENTE} element={<PacienteForm />} />
        <Route path={constantes.R_FIRMARCI} element={<Consentimiento />} />
        <Route path={constantes.R_TUTORLEGAL} element={<TutorForm />} />
        <Route path={constantes.R_HISTORIACLINICA} element={<HistoriaClinicaForm />} />
        <Route path={constantes.R_AGENDA} element={<Agenda />} />
        <Route path={constantes.R_ACTIVIDADES} element={<AgendaAct />} />
        <Route path={constantes.R_CITA} element={<CitaForm />} />
        <Route path={constantes.R_RESETPASS} element={<ResetPass />} />
        <Route path={constantes.R_CONFIG} element={<Config />}/>
        <Route path={constantes.R_ADDTOCALENDAR} element={<AddToCalendar />} />
        <Route path={constantes.R_CREATEPASS} element={<CreatePassEmpleado />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
