import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import IHistoriaClinica from "../../../types/HistoriaClinica";
import { createTheme } from '@mui/material/styles';
import PacienteService from "../../../services/PacienteService";
import { Typography, TextField, Button, Avatar, Paper, Container, Grid, CircularProgress, Alert, Snackbar } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ILog from "../../../types/Log";
import LogService from "../../../services/LogService";
import IPaciente from "../../../types/Paciente";
import Utilities from "../../../common/Utilities";
import IAlert from "../../../types/Alert";
import * as constantes from "../../../common/Constantes";
import ConfigUtils from "../../../utils/Config";

const HistoriaClinicaForm = () => {
    const theme = createTheme();
    const history = useNavigate();

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const {register, handleSubmit, reset, setValue} = useForm<IHistoriaClinica>();

    let location = useLocation();
    const [paciente] = useState<IPaciente>(location.state.paciente);
    const [historiaClinica] = useState<IHistoriaClinica>(location.state.historiaClinica);

    const userRole: number = ConfigUtils.getUserRole();

    const onSubmit = (data: IHistoriaClinica) => {
        setLoading(true);
        historiaClinica ? updateHistoriaClinica(data) : saveHistoriaClinica(data);
    };

    const clear = () => {
        reset();
        history(-1);
    };

    const saveHistoriaClinica = (data: IHistoriaClinica) => {
        if (Object.values(data).every(x => Utilities.isNullEmptyUndefined(x))) {
            setAlert({ open: true, severity: 'warning', msg: 'No hay datos que guardar' });
            setLoading(false);
            return;
        }

        data.pacienteId = paciente._id;
        PacienteService.createHClinica(data)
        .then((response: any) => {
            setLoading(false);
            history(`${constantes.R_PACIENTE}?${constantes.QP_ID}=${paciente._id}`, { state: { open: true, severity: 'success', msg: 'Historia clínica guardada correctamente' } });
        })
        .catch ((e: any) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'HistoriaClinicaForm.Component.saveHistoriaClinica', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                setLoading(false);
            }
        });
    };

    const updateHistoriaClinica = (data: IHistoriaClinica) => {
        PacienteService.updateHClinica(paciente._id, data)
        .then((response: any) => {
            setLoading(false);
            history(`${constantes.R_PACIENTE}?${constantes.QP_ID}=${paciente._id}`, { state: { open: true, severity: 'success', msg: 'Historia clínica actualizada correctamente' } });
        })
        .catch ((e: any) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'HistoriaClinicaForm.Component.updateHistoriaClinica', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                setLoading(false);
            }
        });
    };

    const handleChange = (e: any) => {
        setValue(e.target.name, e.target.value);
    };

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    useEffect(() => {
        if (historiaClinica) reset(historiaClinica);
    }, [historiaClinica, reset]);

    try {
        return (
            userRole != constantes.ROLES.SECRETARIO ?
            <>
                <Container maxWidth="lg">
                <Paper elevation={3} sx={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3), padding: theme.spacing(3), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    
                    <Typography variant="h4" sx={{ display: 'inline-flex', margin: theme.spacing(1, 0, 0) }}><Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><EditIcon /></Avatar><div style={{ margin: '10px auto' }}>{historiaClinica ? 'Editar historia clínica' : 'Añadir historia clínica'}</div></Typography>
                    <Typography variant="h6" >{`Paciente: ${paciente.nombre} ${paciente.apellidos}`}</Typography>
                        <form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '90%' }}>
                            <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">Antecedentes personales:</Typography>

                            <Grid container spacing={1}>
                                <Grid item md={6}>
                                    <TextField multiline rows={2} defaultValue={historiaClinica?.medicacion} {...register("medicacion")} onChange={handleChange} variant="outlined" label="Medicación" margin="normal" fullWidth autoFocus />
                                    <TextField multiline rows={2} defaultValue={historiaClinica?.traumaticas} {...register("traumaticas")} onChange={handleChange} variant="outlined" label="Traumáticas" margin="normal" fullWidth />
                                    <TextField multiline rows={2} defaultValue={historiaClinica?.intervenciones} {...register("intervenciones")} onChange={handleChange} variant="outlined" label="Intervenciones quirúrgicas, cicatrices (diagnóstico y evolución)" margin="normal" fullWidth />

                                </Grid>
                                <Grid item md={6}>
                                    <TextField multiline rows={2} defaultValue={historiaClinica?.alergias} {...register("alergias")} onChange={handleChange} variant="outlined" label="Alergias" margin="normal" fullWidth />
                                    <TextField multiline rows={2} defaultValue={historiaClinica?.enfermedades} {...register("enfermedades")} onChange={handleChange} variant="outlined" label="Enfermedades (año, diagnóstico, tratamiento y evolución)" margin="normal" fullWidth />

                                </Grid>
                            </Grid>
                            <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">Antecedentes familiares:</Typography>
                            <TextField multiline rows={3} defaultValue={historiaClinica?.antecedentesFamilliares} {...register("antecedentesFamilliares")} onChange={handleChange} variant="outlined" margin="normal" fullWidth />
                            
                            <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">Anamnesis por aparatos:</Typography>

                            <Grid container spacing={1}>
                                <Grid item md={6}>
                                    <TextField multiline rows={2} defaultValue={historiaClinica?.sistemaNervioso} {...register("sistemaNervioso")} onChange={handleChange} variant="outlined" label="Sistema nervioso (sueño/SNC/SNP)" margin="normal" fullWidth />
                                    <TextField multiline rows={2} defaultValue={historiaClinica?.aparatoCirculatorio} {...register("aparatoCirculatorio")} onChange={handleChange} variant="outlined" label="Aparato circulatorio" margin="normal" fullWidth />
                                    <TextField multiline rows={2} defaultValue={historiaClinica?.aparatoRespiratorio} {...register("aparatoRespiratorio")} onChange={handleChange} variant="outlined" label="Aparato respiratorio" margin="normal" fullWidth />
                                    <TextField multiline rows={2} defaultValue={historiaClinica?.aparatoGenitoUrinario} {...register("aparatoGenitoUrinario")} onChange={handleChange} variant="outlined" label="Aparato genito-urinario" margin="normal" fullWidth />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField multiline rows={2} defaultValue={historiaClinica?.aparatoLocomotor} {...register("aparatoLocomotor")} onChange={handleChange} variant="outlined" label="Aparato locomotor" margin="normal" fullWidth />
                                    <TextField multiline rows={2} defaultValue={historiaClinica?.aparatoDigestivo} {...register("aparatoDigestivo")} onChange={handleChange} variant="outlined" label="Aparato digestivo" margin="normal" fullWidth />
                                    <TextField multiline rows={2} defaultValue={historiaClinica?.sistemaEndocrino} {...register("sistemaEndocrino")} onChange={handleChange} variant="outlined" label="Sistema endocrino" margin="normal" fullWidth />
                                </Grid>

                                <Grid style={{ margin: '10px auto' }} justifyContent="center" alignItems="center">
                                { loading ? <CircularProgress /> : 
                                <>
                                    <Button sx={{ margin: theme.spacing(2, 3, 1, 0) }} variant="contained" color="primary" type="submit" >Guardar</Button>
                                    <Button sx={{ margin: theme.spacing(2, 3, 1, 0) }} variant="contained" color="secondary" onClick={clear}>Cancelar</Button>
                                </>
                                }
                                </Grid>
                            </Grid>
                        </form>
                    
                </Paper>
                </Container>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </>
            :
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open>
                <Alert severity="warning" sx={{ width: '100%' }} >Usuario no autorizado</Alert>
            </Snackbar>
        );
    } catch (e: any) {
        const error: ILog = { method: 'HistoriaClinicaForm.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }
    
};

export default HistoriaClinicaForm;