import { useState } from "react";
import { useForm } from "react-hook-form";
import { Typography, TextField, Button, CircularProgress, Alert, Snackbar } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AuthService from "../../services/AuthService";
import LogService from "../../services/LogService";
import ILog from "../../types/Log";
import { createTheme } from '@mui/material/styles';
import IAlert from "../../types/Alert";

const schema = yup.object().shape({
    email: yup.string().required('El correo electrónico es obligatorio').email('Deber ser un correo electrónico válido'),
});

const ChangePass = () => {
    const theme = createTheme();

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const {register, handleSubmit, formState: { errors }} = useForm<any>({ resolver: yupResolver(schema) });

    const onSubmit = (data: any) => {
        setLoading(true);
        resetPass(data);
    };

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const resetPass = (data: any) => {
        AuthService.resetPass(data.email)
        .then((response: any) => {
            setAlert({ open: true, severity: 'success', msg: response.data.message });
            setLoading(false);
        })
        .catch ((e: any) => {
            const error: ILog = { method: 'ChangePass.Component.resetPass', level: 'error', message: e.message, meta: e.response };
            LogService.logError(error);
            setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            setLoading(false);
        });
    };

    try {
        return (
            <>
            <Typography variant="h4" sx={{ textAlign: 'center', margin: theme.spacing(1, 0, 1) }}>Cambio de contraseña</Typography>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <TextField {...register("email")} variant="outlined" label="Email" margin="normal" fullWidth required autoFocus helperText={errors.email?.message?.toString()} error={!!errors.email?.message} />
                <Button sx={{ margin: theme.spacing(3, 0, 2) }} variant="contained" color="primary" fullWidth type="submit" >{loading ? <CircularProgress size={25} color="inherit" /> : `Enviar`}</Button>
            </form>
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
            </Snackbar>
            </>
        );
    } catch (e: any) {
        const error: ILog = { method: 'ChangePass.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }

};

export default ChangePass;