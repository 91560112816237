import { Alert, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Snackbar, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import PacienteService from "../../../services/PacienteService";
import IPaciente from "../../../types/Paciente";
import { createTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPerson from '@mui/icons-material/PersonAdd';
import HClinicaIcon from '@mui/icons-material/ListAlt';
import FolderIcon from '@mui/icons-material/Folder';
import PacientesUtils from "../../../utils/Pacientes";
import Documentos from "./Documentos";
import ILog from "../../../types/Log";
import LogService from "../../../services/LogService";
import HistoriaClinica from "./HistoriaClinica";
import Pacientes from "../../../utils/Pacientes";
import Citas from "./citas/Citas";
import IDocumento from "../../../types/Documento";
import CitasPasadas from "./citas/CitasPasadas";
import AgendaService from "../../../services/AgendaService";
import ICita from "../../../types/Cita";
import CitaActual from "./citas/CitaActual";
import IAlert from "../../../types/Alert";
import * as constantes from "../../../common/Constantes";
import Utilities from "../../../common/Utilities";
import ConfigUtils from "../../../utils/Config";
import Clases from "./Clases";

const Paciente = () => {
    const theme = createTheme();
    const history = useNavigate();
    let location = useLocation();

    const [loading, setLoading] = useState(true);
    const [paciente, setPaciente] = useState<IPaciente>();
    const [alert, setAlert] = useState<IAlert>({ open: location.state ? location.state.open : false, severity: location.state ? location.state.severity :  'info', msg: location.state ? location.state.msg :  '' });
    const [openDialog, setOpenDialog] = useState(false);
    const [verHClinica, setVerHClinica] = useState(true);
    const [verDocs, setVerDocs] = useState(false);
    const [verMas, setVerMas] = useState(false);
    const [openWarning, setOpenWarning] = useState(false);
    const [messageWarning, setMessageWarning] = useState('');
    const [linkWarning, setLinkWarning] = useState('');
    const [pacienteCI, setPacienteCI] = useState<IDocumento>();
    const [citaToday, setCitaToday] = useState<Array<ICita>>();
    const [consentimientoDoc] = useState(ConfigUtils.getConsentientoDoc());

    const userRole: number = ConfigUtils.getUserRole();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get(constantes.QP_ID);

        if (id) {
            const promisePaciente = PacienteService.get(id);
            const promiseDocCI = PacienteService.getDocCI(id);
            const promiseCitaActual = AgendaService.getCitaActualPaciente(id);

            Promise.all([promisePaciente, promiseDocCI, promiseCitaActual])
            .then((response: any) => {
                setPaciente(response[0].data);
                if (response[2].data.length !== 0) setCitaToday(response[2].data);
                if (response[1].data === null) {
                    if (Utilities.isNullEmptyUndefined(response[0].data.fechaNacimiento)) {
                        setOpenWarning(true);
                        setMessageWarning('Es necesario añadir la fecha de nacimiento del paciente');
                        setLinkWarning(constantes.R_NUEVOPACIENTE);
                        return;
                    }
                    if (PacientesUtils.calcularEdad(response[0].data?.fechaNacimiento) as number > 17) {
                        if (Utilities.isNullEmptyUndefined(response[0].data.DNI)) {
                            setOpenWarning(true);
                            setMessageWarning('El paciente no tiene DNI, NIE o pasaporte guardado para poder firmar el consentimiento informado');
                            setLinkWarning(constantes.R_NUEVOPACIENTE);
                            return;
                        }
                    } else {
                        if (Utilities.isNullEmptyUndefined(response[0].data.DNItutor)) {
                            setOpenWarning(true);
                            setMessageWarning('Es necesario añadir un tutor legal para poder firmar el consentimiento informado');
                            setLinkWarning(constantes.R_TUTORLEGAL);
                            return;
                        }
                    }
                    setOpenWarning(true);
                    setMessageWarning('Es necesario firmar el consentimiento informado del paciente');
                    setLinkWarning(constantes.R_FIRMARCI);
                    
                } else {
                    setPacienteCI(response[1].data);
                }
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Paciente.Component.useEffect', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoading(false);
            });
                
        } else {
            history(constantes.R_PACIENTES);
        }
    }, [setPaciente, setLoading, setAlert, history]);

    const deletePaciente = () => {
        if (!paciente?._id) return;
        PacienteService.deleteP(paciente?._id)
            .then((response: any) => {
                history(constantes.R_PACIENTES, {state: { open: true, severity: 'success', msg: response.data.message }});
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Paciente.Component.deletePaciente', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            });
    };

    const updateAlert = (id: string) => {
        if (!pacienteCI?._id) {
            return;
        }
        if (id === pacienteCI?._id) {
            setOpenWarning(true);
            setMessageWarning('Es necesario firmar el consentimiento informado del paciente');
            setLinkWarning(constantes.R_FIRMARCI);
        }
    };

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    try {
        let edad;
        let esMenor: boolean = false;

        if (paciente) {
            edad = PacientesUtils.calcularEdad(paciente.fechaNacimiento);
            if (edad as number < 18) esMenor = true;
        }

        return (
            <Grid>
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ width: '55%' }} open={openWarning && consentimientoDoc != null} onClose={() => {}}>
                    <Alert severity="warning" sx={{ width: '100%' }} onClose={() => {setOpenWarning(false)}}>
                        {messageWarning}
                        <Link to={linkWarning} state={linkWarning === constantes.R_FIRMARCI ? {paciente, esMenor} : paciente} style={{ textDecoration: 'none' }}>
                            <Button color="warning" size="small">IR</Button>
                        </Link>
                    </Alert>
                </Snackbar>
                    
                <Grid container>
                    <Grid item md={1}>
                        {esMenor && !paciente?.DNItutor &&
                            <Link to={constantes.R_TUTORLEGAL} state={paciente}>
                                <Tooltip title="AÑADIR TUTOR LEGAL" >
                                    <IconButton sx={{ marginTop: theme.spacing(2), marginLeft: theme.spacing(5) }} color="primary" style={{ backgroundColor: '#3f51b5'}}>
                                        <AddPerson style={{ color: 'white', float: 'right' }} />
                                    </IconButton>
                                </Tooltip>
                            </Link>}
                        {userRole != constantes.ROLES.SECRETARIO && <Tooltip title={verHClinica ? "OCULTAR HISTORIA CLINICA" : "VER HISTORIA CLINICA"} >
                            <IconButton sx={{ marginTop: theme.spacing(2), marginLeft: theme.spacing(5) }} color="primary" style={{ backgroundColor: `${verHClinica ? 'white' : '#3f51b5'}` }} onClick={() => setVerHClinica(!verHClinica)}>
                                <HClinicaIcon style={{ color: `${!verHClinica ? 'white' : '#3f51b5'}`, float: 'right' }} />
                            </IconButton>
                        </Tooltip>}

                        <Tooltip title={verDocs ? "OCULTAR DOCUMENTOS" : "VER DOCUMENTOS"} >
                            <IconButton sx={{ marginTop: theme.spacing(2), marginLeft: theme.spacing(5) }} color="primary" style={{ backgroundColor: `${verDocs ? 'white' : '#3f51b5'}` }} onClick={() => setVerDocs(!verDocs)}>
                                <FolderIcon style={{ color: `${!verDocs ? 'white' : '#3f51b5'}`, float: 'right' }} />
                            </IconButton>
                        </Tooltip>
                        {userRole == constantes.ROLES.ADMINISTRADOR && <Tooltip title="BORRAR PACIENTE" >
                            <IconButton sx={{ marginTop: theme.spacing(2), marginLeft: theme.spacing(5) }} color="primary" style={{ backgroundColor: '#ef5350' }} onClick={() => setOpenDialog(true)}>
                                <DeleteIcon style={{ color: 'white', float: 'right' }} />
                            </IconButton>
                        </Tooltip>}
                    </Grid>

                    <Grid item md={7} xs={12}>
                        <Container>
                            <Paper elevation={3} sx={{ marginTop: theme.spacing(3), padding: theme.spacing(3), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', marginLeft: '10px' }} >DATOS PERSONALES</Typography>
                                    <Link to={constantes.R_NUEVOPACIENTE} state={paciente} style={{ textDecoration: 'none' }}>
                                        <Tooltip title="EDITAR DATOS PERSONALES" >
                                            <IconButton>
                                                <EditIcon style={{ float: 'right', cursor: 'pointer', color: 'grey' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                </div>

                                {loading ? <CircularProgress /> :
                                    <Grid container>
                                        <>
                                        <Grid item md={2} xs={12}>
                                            <TextField label="Nº de paciente" value={paciente?.internalID != null ? paciente?.internalID.toString() : '#'} style={{ marginLeft: '20px', marginTop: '15px', width: '90%' }} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item md={7} xs={12}>
                                            <TextField label="Nombre" value={paciente?.nombre != null && paciente?.apellidos != null ? `${paciente?.nombre} ${paciente?.apellidos}` : ''} style={{ marginLeft: '20px', marginTop: '15px', width: '90%' }} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <TextField label="Edad" value={paciente?.fechaNacimiento != null ? `${PacientesUtils.calcularEdad(paciente.fechaNacimiento)} años` : ''} style={{ marginLeft: '20px', marginTop: '15px', width: '90%' }} InputProps={{ readOnly: true }} />
                                        </Grid>

                                        { paciente?.telefonoAdicional ?
                                        <>
                                        <Grid item md={4} xs={12}>
                                            <TextField label="Teléfono" value={paciente?.telefono != null ? paciente?.telefono : ''} style={{ marginLeft: '20px', marginTop: '15px', width: '90%' }} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <TextField label="Teléfono adicional" value={paciente?.telefonoAdicional != null ? paciente?.telefonoAdicional : ''} style={{ marginLeft: '20px', marginTop: '15px', width: '90%' }} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <TextField label="DNI o NIE" value={paciente?.DNI != null ? paciente?.DNI : ''} style={{ marginLeft: '20px', marginTop: '15px', marginBottom: '10px', width: '90%' }} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        </>
                                        :
                                        <>
                                        <Grid item md={6} xs={12}>
                                            <TextField label="Teléfono" value={paciente?.telefono != null ? paciente?.telefono : ''} style={{ marginLeft: '20px', marginTop: '15px', width: '90%' }} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <TextField label={paciente?.DNI ? Utilities.getSpanichIDType(paciente?.DNI) : "DNI, NIE o pasaporte"} value={paciente?.DNI != null ? paciente?.DNI : ''} style={{ marginLeft: '20px', marginTop: '15px', marginBottom: '10px', width: '90%' }} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        </>
                                        }

                                        </>
                                        {edad as number < 18 && paciente?.DNItutor &&
                                            <>
                                                <Grid item xs={12}>

                                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', marginLeft: '10px' }} >TUTOR LEGAL</Typography>
                                                        <Link to={constantes.R_TUTORLEGAL} state={paciente} style={{ textDecoration: 'none' }}>
                                                            <Tooltip title="EDITAR TUTOR LEGAL">
                                                                <IconButton>
                                                                    <EditIcon style={{ float: 'right', cursor: 'pointer', color: 'grey' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Link>
                                                    </div>

                                                    {loading ? <CircularProgress /> :

                                                        <Grid container>
                                                            <Grid item md={7} lg={6} xs={12}>
                                                                <TextField label="Nombre" value={paciente?.nombreTutor != null && paciente?.apellidosTutor != null ? `${paciente?.nombreTutor} ${paciente?.apellidosTutor}` : ''} style={{ marginLeft: '20px', marginTop: '15px', width: '90%' }} InputProps={{ readOnly: true }} />
                                                            </Grid>
                                                            <Grid item md={4} xs={12}>
                                                                <TextField label={Utilities.getSpanichIDType(paciente?.DNItutor)} value={paciente?.DNItutor != null ? paciente?.DNItutor : ''} style={{ marginLeft: '20px', marginTop: '15px', width: '90%' }} InputProps={{ readOnly: true }} />
                                                            </Grid>
                                                        </Grid>
                                                    }


                                                </Grid>
                                            </>
                                        }
                                        <Grid item md={12}>
                                            {verMas ?
                                                <Tooltip title="VER MENOS INFORMACIÓN" >
                                                    <IconButton onClick={() => setVerMas(false)}>
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                :
                                                <Tooltip title="VER MÁS INFORMACIÓN" >
                                                    <IconButton onClick={() => setVerMas(true)}>
                                                        <AddIcon />
                                                    </IconButton>
                                                </Tooltip>}
                                        </Grid>
                                        {verMas &&
                                            <>
                                                <Grid item md={6} xs={12}>
                                                    <TextField label="Domicilio" value={paciente?.domicilio != null ? paciente?.domicilio : ''} style={{ marginLeft: '20px', marginTop: '15px', width: '90%' }} InputProps={{ readOnly: true }} />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <TextField label="Email" value={paciente?.email != null ? paciente?.email : ''} style={{ marginLeft: '20px', marginTop: '15px', width: '90%' }} InputProps={{ readOnly: true }} />
                                                </Grid>
                                                <Grid item md={2} xs={12}>
                                                    <TextField label="Sexo" value={paciente?.sexo != null ? Pacientes.getSexo(paciente?.sexo) : ''} style={{ marginLeft: '20px', marginTop: '15px', width: '90%' }} InputProps={{ readOnly: true }} />
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <TextField label="¿Cómo nos ha conocido?" value={paciente?.conocido != null ? Pacientes.getConocido(paciente?.conocido) : ''} style={{ marginLeft: '20px', marginTop: '15px', width: '90%' }} InputProps={{ readOnly: true }} />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <TextField label="Más info" value={paciente?.masConocido != null ? paciente?.masConocido : ''} style={{ marginLeft: '20px', marginTop: '15px', width: '90%' }} InputProps={{ readOnly: true }} />
                                                </Grid>
                                                <Grid item md={12} xs={11}>
                                                    <TextField multiline label="Deportes, aficiciones, actividad física habital" value={paciente?.actividadFisica != null ? paciente?.actividadFisica : ''} style={{ marginLeft: '20px', marginTop: '15px', width: '95%' }} InputProps={{ readOnly: true }} />
                                                    <TextField multiline label="Ocupación laboral y condiciones del lugar laboral" value={paciente?.ocupacion != null ? paciente?.ocupacion : ''} style={{ marginLeft: '20px', marginTop: '15px', width: '95%' }} InputProps={{ readOnly: true }} />
                                                    <TextField multiline label="¿Has ido a un fisioterapeuta antes u otro especialista de la salud? ¿Por qué? ¿Cuándo?" value={paciente?.especialistaSalud != null ? paciente?.especialistaSalud : ''} style={{ marginLeft: '20px', marginTop: '15px', width: '95%' }} InputProps={{ readOnly: true }} />
                                                </Grid>
                                            </>}
                                    </Grid>
                                }
                            </Paper>
                        </Container>
                    </Grid>

                    {paciente &&
                        <>
                            <Grid item md={4} xs={12}>
                                <Citas paciente={paciente} />
                                <Clases paciente={paciente} />
                                { verDocs && <Documentos paciente={paciente} callBack={updateAlert} />}
                            </Grid>

                            {verHClinica && <>
                                {userRole != constantes.ROLES.SECRETARIO && <HistoriaClinica paciente={paciente} />}
                                {userRole != constantes.ROLES.SECRETARIO && citaToday && citaToday?.length > 0 && citaToday.map((cita: ICita) => {
                                    return (
                                        <CitaActual paciente={paciente} cita={cita} esMenor={esMenor} key={cita._id} />
                                    );
                                })}
                                <CitasPasadas paciente={paciente} esMenor={esMenor} />
                            </>}
                        </>
                    }

                </Grid>

                {/* BORRAR PACIENTE */}
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                    <DialogTitle>BORRAR PACIENTE: {paciente?.nombre} {paciente?.apellidos}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>¿Está seguro de que desea borrar el paciente?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => deletePaciente()} color="primary">SI</Button>
                        <Button onClick={() => setOpenDialog(false)} color="primary" autoFocus>NO</Button>
                    </DialogActions>
                </Dialog>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </Grid>
        );
    } catch (e: any) {
        const error: ILog = { method: 'Paciente.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }


};

export default Paciente;