import ICita from "../../../../types/Cita";
import { createTheme } from '@mui/material/styles';
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import ILog from "../../../../types/Log";
import LogService from "../../../../services/LogService";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Snackbar, Tooltip, Typography, Alert, CircularProgress } from "@mui/material";
import * as constantes from "../../../../common/Constantes";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import IPaciente from "../../../../types/Paciente";
import DocUtils from "../../../../utils/Doc";
import Utilities from "../../../../common/Utilities";
import IAlert from "../../../../types/Alert";
import AgendaService from "../../../../services/AgendaService";
import ConfigUtils from "../../../../utils/Config";


type CitaPasadaProps = {
    cita: ICita;
    paciente: IPaciente;
    esMenor: boolean;
}

const CitaPasada = (props: CitaPasadaProps) => {
    const theme = createTheme();
    const history = useNavigate();

    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const [loading, setLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [correo] = useState(ConfigUtils.getMail());
    const [asistenciaDoc] = useState(ConfigUtils.getAsistenciaDoc());
    const [sanitarios] = useState(ConfigUtils.getSanitarios());

    const [verMas, setVerMas] = useState(false);
    const [justificanteSent, setJustificanteSent] = useState(props.cita.justificanteSent);

    const userRole: number = ConfigUtils.getUserRole();

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const sendJustificante = () => {

        if (Utilities.isNullEmptyUndefined(props.paciente.email)) {
            setOpenDialog(false);
            setAlert({ open: true, severity: 'warning', msg: 'El paciente no tiene un correo electrónico donde enviar el justificante' });
            return;
        }
        if (props.esMenor) {
            if (Utilities.isNullEmptyUndefined(props.paciente.DNItutor)) {
                setOpenDialog(false);
                setAlert({ open: true, severity: 'warning', msg: 'El tutor del paciente no tiene DNI guardado' });
                return;
            }
        } else {
            if (Utilities.isNullEmptyUndefined(props.paciente.DNI)) {
                setOpenDialog(false);
                setAlert({ open: true, severity: 'warning', msg: 'El paciente no tiene DNI guardado' });
                return;
            }
        }
    
        setLoading(true);
        const justificante = DocUtils.formatJustificanteCita(props.paciente, props.cita, props.esMenor);
    
        AgendaService.sendJustificante({ email: props.paciente.email, doc: justificante, citaId: props.cita._id })
        .then(response => {
            setAlert({ open: true, severity: 'success', msg: response.data.message });
            setJustificanteSent(true);
        })
        .catch (e => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'CitaActual.Component.sendJustificante', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        })
        .finally(() => {
            setLoading(false);
            setOpenDialog(false);
        });
    };

    try {
        return (
        <>
            <Paper elevation={2} sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1), padding: theme.spacing(2), backgroundColor: props.cita.asistencia === false ? '#ffbaba' : '#fff', borderLeft: `5px solid ${sanitarios.filter((s) => s.internalID === props.cita.especialistaId)[0] == undefined ? 'grey' : sanitarios.filter((s) => s.internalID === props.cita.especialistaId)[0]?.color}` }} key={props.cita._id}>
                <Grid container sx={{ minWidth: '75vw', maxWidth: '75vw' }}>
                    { userRole != constantes.ROLES.SECRETARIO && props.cita.asistencia !== false && <Grid item md={1} xs={2}>
                        {verMas ?
                            <Tooltip title="VER MENOS INFORMACIÓN" >
                                <IconButton onClick={() => setVerMas(false)}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip title="VER MÁS INFORMACIÓN" >
                                <IconButton onClick={() => setVerMas(true)}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>}
                    </Grid>}

                    <Grid item md={10} xs ={6}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Typography variant="h5">{new Date(props.cita.start).getDate()}&nbsp;</Typography>
                            <Typography variant="body2">{`${constantes.monthNames[new Date(props.cita.start).getMonth()].toUpperCase()} ${new Date(props.cita.start).getFullYear()}`}</Typography>
                        </div>
                    </Grid>

                    { userRole != constantes.ROLES.SECRETARIO && props.cita.asistencia !== false && <Grid item md={1} xs={4}>
                        <Link to={constantes.R_CITA} state={{cita: props.cita, paciente: props.paciente}} style={{ textDecoration: 'none' }} >
                            <Tooltip title="EDITAR CITA" >
                                <IconButton>
                                    <EditIcon style={{ float: 'right', cursor: 'pointer', color: 'grey' }} />
                                </IconButton>
                            </Tooltip>
                        </Link>
                        {props.paciente.authEmail && correo && (asistenciaDoc || Utilities.isMoheUser()) && <Tooltip title={justificanteSent ? "VOLVER A ENVIAR JUSTIFICANTE" : "ENVIAR JUSTIFICANTE"} >
                            <IconButton onClick={() => setOpenDialog(true)}>
                                <EmailIcon color={justificanteSent ? "success" : "inherit"} />
                            </IconButton>
                        </Tooltip>}
                    </Grid>}

                    {verMas &&
                        <>
                            <Grid item md={12} xs={10}>
                                <Typography sx={{ fontWeight: 'bold' }}>Motivo: </Typography><Typography>{props.cita.motivo}</Typography>
                            </Grid>
                            <Grid item md={12} xs={10}>
                                <Typography sx={{ fontWeight: 'bold' }}>Valoración: </Typography><Typography>{props.cita.valoracion}</Typography>
                            </Grid>
                            <Grid item md={12} xs={10}>
                                <Typography sx={{ fontWeight: 'bold' }}>Razonamiento: </Typography><Typography>{props.cita.razonamiento}</Typography>
                            </Grid>
                            <Grid item md={12} xs={10}>
                                <Typography sx={{ fontWeight: 'bold' }}>Tratamiento: </Typography><Typography>{props.cita.tratamiento}</Typography>
                            </Grid>
                        </>
                    }


                </Grid>
            </Paper>

            {/* ENVIAR JUSTIFICANTE */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>ENVIAR JUSTIFICANTE POR EMAIL</DialogTitle>
                <DialogContent>
                    <DialogContentText>¿Está seguro de que desea enviar el justificante de asistencia?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {loading ? 
                    <Grid container>
                        <CircularProgress style={{ margin: '10px auto' }} />
                    </Grid> : 
                    <><Button onClick={sendJustificante} color="primary">SI</Button>
                    <Button onClick={() => setOpenDialog(false)} color="primary" autoFocus>NO</Button></>}
                </DialogActions>
            </Dialog>

            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
            </Snackbar>
        </>
        );
    } catch (e: any) {
        const error: ILog = { method: 'CitaPasada.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }
};

export default CitaPasada;