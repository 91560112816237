import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import ILog from "../../types/Log";
import ICita from "../../types/Cita";
import LogService from "../../services/LogService";
import { Typography, TextField, Button, Avatar, Paper, Container, Grid, MenuItem, CircularProgress, IconButton, Alert, Snackbar } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Add from '@mui/icons-material/Add';
import { createTheme } from '@mui/material/styles';
import AgendaUtils from "../../utils/Agenda";
import AgendaService from "../../services/AgendaService";
import Utilities from "../../common/Utilities";
import ConfigUtils from "../../utils/Config";
import IAlert from "../../types/Alert";
import ISala from "../../types/Sala";
import * as constantes from "../../common/Constantes";
import IPaciente from "../../types/Paciente";

const CitaForm = () => {
    const theme = createTheme();
    const history = useNavigate();

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const {register, handleSubmit, reset, setValue} = useForm<ICita>();
    const [salas] = useState<Array<ISala>>(ConfigUtils.getSalas());

    let location = useLocation();
    const [cita] = useState<ICita>(location.state.cita);
    const [paciente] = useState<IPaciente>(location.state.paciente);

    const userRole: number = ConfigUtils.getUserRole();

    const onSubmit = (data: ICita) => {
        setLoading(true);
        updateCita(data);
    };

    const clear = () => {
        reset();
        history(-1);
    };

    const updateCita = (data: ICita) => {
        if (!cita._id) return;

        if (Utilities.isNullEmptyUndefined(data.motivo) && Utilities.isNullEmptyUndefined(data.valoracion) && Utilities.isNullEmptyUndefined(data.razonamiento) && Utilities.isNullEmptyUndefined(data.tratamiento)) {
            setAlert({ open: true, severity: 'warning', msg: 'No hay datos que guardar' });
            setLoading(false);
            return;
        }

        AgendaService.update(cita._id, data, false)
        .then(response => {
            setLoading(false);
            history(`${constantes.R_PACIENTE}?${constantes.QP_ID}=${cita.pacienteId}`, { state: { open: true, severity: 'success', msg: 'Cita actualizada correctamente' } });
        })
        .catch (e => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'CitaForm.Component.updateCita', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                setLoading(false);
            }
        });
    };

    const handleChange = (e: any) => {
        setValue(e.target.name, e.target.value);
    };

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    useEffect(() => {
        reset(cita);
    }, [cita, reset]);

    try {
        return (
            userRole != constantes.ROLES.SECRETARIO ?
            <>
            <Container maxWidth="lg">
                <Paper elevation={3} sx={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3), padding: theme.spacing(3), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <Typography variant="h4" sx={{ display: 'inline-flex', margin: theme.spacing(1, 0, 1) }}>
                            <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><Add /></Avatar><div style={{ margin: '10px auto' }}>CITA: {paciente.nombre} {paciente.apellidos}</div>
                        </Typography>
                        <IconButton onClick={() => history(`${constantes.R_PACIENTE}?${constantes.QP_ID}=${cita.pacienteId}`)}>
                            <VisibilityIcon titleAccess="Ver paciente" />
                        </IconButton>
                    </div>

                    

                    <form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '90%' }}>
                    <Grid container spacing={1}>
                        
                        <Grid item md={2} xs={12} style={{ margin: 'auto' }}>
                            <TextField disabled select value={cita.especialistaId} {...register("especialistaId")} variant="outlined" label="Especialista" margin="dense" fullWidth >
                                {
                                    ConfigUtils.getSanitarios().map((empleado: any) => {
                                        return (
                                            <MenuItem key={empleado.internalID} value={empleado.internalID}>{empleado.firstName}</MenuItem>
                                        );
                                    })
                                }
                            </TextField>
                        </Grid>
                        <Grid item md={3} xs={12} style={{ margin: 'auto' }}>
                            <TextField disabled select value={cita.salaId} {...register("salaId")} variant="outlined" label="Sala" margin="dense" fullWidth >
                                {
                                    salas.map((sala, index) => {
                                        return (
                                            <MenuItem key={index} value={sala.internalID ? sala.internalID : index}>{sala.title}</MenuItem>
                                        );
                                    })
                                }
                            </TextField>
                        </Grid>

                        <Grid item md={4} xs={12} style={{ margin: 'auto' }}>
                            <TextField disabled value={AgendaUtils.formatCitaDate(cita)} variant="outlined" label="Día" margin="dense" fullWidth />
                        </Grid>

                        <Grid item md={3} xs={12} style={{ margin: 'auto' }}>
                            <TextField disabled value={AgendaUtils.formatCitaTime(cita)} variant="outlined" label="Hora" margin="dense" fullWidth />
                        </Grid>

                    </Grid>
                    </form>
                    
                </Paper>

                <Paper elevation={3} sx={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3), padding: theme.spacing(3), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: '90%' }}>
                        <Grid container >
                            <Grid item md={12} xs={12} style={{ margin: 'auto' }}>
                                <TextField multiline rows={3} defaultValue={cita?.motivo} {...register("motivo")} onChange={handleChange} variant="outlined" label="Motivo de la consulta" margin="normal" fullWidth autoFocus />
                                <TextField multiline rows={3} defaultValue={cita?.valoracion} {...register("valoracion")} onChange={handleChange} variant="outlined" label="Valoración" margin="normal" fullWidth />
                                <TextField multiline rows={3} defaultValue={cita?.razonamiento} {...register("razonamiento")} onChange={handleChange} variant="outlined" label="Razonamiento clínico" margin="normal" fullWidth />
                                <TextField multiline rows={3} defaultValue={cita?.tratamiento} {...register("tratamiento")} onChange={handleChange} variant="outlined" label="Tratamiento" margin="normal" fullWidth />
                            </Grid>

                            <Grid style={{ margin: 'auto' }}>
                            { loading ? <CircularProgress /> : 
                                <>
                                    <Button sx={{ margin: theme.spacing(2, 3, 1, 0) }} variant="contained" color="primary" type="submit" >Guardar</Button>
                                    <Button sx={{ margin: theme.spacing(2, 3, 1, 0) }} variant="contained" color="secondary" onClick={clear}>Cancelar</Button>
                                </>
                                }
                            </Grid>

                        </Grid>
                    </form>
                </Paper>
            </Container>

            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
            </Snackbar>
            </>
            :
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open>
                <Alert severity="warning" sx={{ width: '100%' }} >Usuario no autorizado</Alert>
            </Snackbar>
        );
    } catch (e: any) {
        const error: ILog = { method: 'CitaForm.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }

};

export default CitaForm;