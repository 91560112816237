import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Paper, Container, Grid, Typography, Button, Dialog, DialogContent, DialogActions, TextField, Avatar, MenuItem, CircularProgress, DialogTitle, DialogContentText, Tooltip, IconButton, Autocomplete, FormControlLabel, Checkbox, FormGroup, List, Alert, InputAdornment, Snackbar } from "@mui/material";
import { DatePicker, LocalizationProvider, TimePicker, DateTimePicker } from '@mui/x-date-pickers';
import ILog from "../../types/Log";
import IEvent from "../../types/Event";
import LogService from "../../services/LogService";
import { Calendar, momentLocalizer, SlotInfo } from "react-big-calendar";
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from "moment";
import * as constantes from "../../common/Constantes";
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import FilterOn from '@mui/icons-material/FilterList';
import FilterOff from '@mui/icons-material/FilterListOff';
import DeleteIcon from '@mui/icons-material/Delete';
import LogoutIcon from '@mui/icons-material/Logout';
import { createTheme } from '@mui/material/styles';
import ICita from "../../types/Cita";
import PacienteService from "../../services/PacienteService";
import PacientesUtils from "../../utils/Pacientes";
import AgendaUtils from "../../utils/Agenda";
import IPaciente from "../../types/Paciente";
import AgendaService from "../../services/AgendaService";
import 'moment/locale/es';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import IClass from "../../types/Class";
import MinPaciente from "../../types/MinPaciente";
import ClaseUtils from "../../utils/Clase";
import { isMobile } from "react-device-detect";
import IOutOffice from "../../types/OutOffice";
import ConfigUtils from "../../utils/Config";
import IAlert from "../../types/Alert";
import ISala from "../../types/Sala";
import IActividad from "../../types/Actividad";
import Holiday from 'date-holidays';
import IHoliday from "../../types/Holiday";
import Utilities from "../../common/Utilities";

import WavesIcon from '@mui/icons-material/Waves';
import LandscapeIcon from '@mui/icons-material/Landscape';
import DiamondIcon from '@mui/icons-material/Diamond';
import GrassIcon from '@mui/icons-material/Grass';
import AcUnitIcon from '@mui/icons-material/AcUnit';

const locale = constantes.locale;

const Agenda = () => {
    const theme = createTheme();
    const history = useNavigate();
    const localizer = momentLocalizer(moment);

    const [errorPaciente, setErrorPaciente] = useState<string>('');

    const [sanitarios] = useState(ConfigUtils.getSanitarios());
    const [checkedSanitarios, setCheckedSanitarios] = useState(new Array(sanitarios.length).fill(true));

    const [showCitas, setShowCitas] = useState(true);
    const [showClasses, setShowClasses] = useState(true);
    const [shownEvents, setShownEvents] = useState<Array<IEvent>>([]);
    const [showFilters, setShowFilters] = useState(false);
    const [showOutOffice, setShowOutOffice] = useState(true);

    const [outOffices] = useState<Array<IOutOffice>>(ConfigUtils.getOutOffices());
    const [salas] = useState<Array<ISala>>(ConfigUtils.getSalas());
    const [actividades] = useState<Array<IActividad>>(ConfigUtils.getActividades());
    const [holidays, setHolidays] = useState<Array<IHoliday>>(ConfigUtils.getHolidays());

    const [loading, setLoading] = useState(true);
    const [loadingCita, setLoadingCita] = useState(false);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const [events, setEvents] = useState<Array<IEvent>>([]);
    const [pacientes, setPacientes] = useState<Array<IPaciente>>([]);
    const [cita, setCita] = useState<ICita>();
    const [isEditCita, setIsEditCita] = useState(false);
    const [event, setEvent] = useState<IEvent>();
    const [modalEvent, setModalEvent] = useState(false);
    const [modalEventDelete, setModalEventDelete] = useState(false);
    const [modalCita, setModalCita] = useState(false);
    const [start, setStart] = useState<Date>(new Date());
    const [startTime, setStartTime] = useState<Date>(new Date());
    const [endTime, setEndTime] = useState<Date>(new Date());
    const [especialistaId, setEspecialistaId] = useState<number>(0);
    const [salaId, setSalaId] = useState<number>(0);
    const [pacienteId, setPacienteId] = useState<string>('');
    const [repetirId, setRepetirId] = useState<number>(0);
    const [terminarRepetir, setTerminarRepetir] = useState<Date>(new Date());
    const [modalAusencia, setModalAusencia] = useState(false);

    //CONFIRMACION CITA
    const [modalConfirmacion, setModalConfirmacion] = useState(false);
    const [loadingConfirmacion, setLoadinConfirmacion] = useState(false);
    const [correo] = useState(ConfigUtils.getMail());
    const [citaConfirmacion, setCitaConfirmacion] = useState<ICita>();

    //CLASES
    const [modalClass, setModalClass] = useState(false);
    const [profesorId, setProfesorId] = useState<number>(0);
    const [salaClassId, setSalaClassId] = useState<number>(0);
    const [duracion, setDuracion] = useState<number>(60);
    const [pacientesIds, setPacientesIds] = useState<Array<IPaciente>>([]);
    const [startClass, setStartClass] = useState<Date>(new Date());
    const [loadingClass, setLoadingClass] = useState(false);
    const [modalClassEvent, setModalClassEvent] = useState(false);
    const [modalClassDelete, setModalClassDelete] = useState(false);
    const [modalClassComentarios, setModalClassComentarios] = useState(false);
    const [comentariosClass, setComentariosClass] = useState('');
    const [modifiedComment, setModifiedComment] = useState('');
    
    //PACIENTE
    const [modalPaciente, setModalPaciente] = useState(false);
    const [nombrePaciente, setNombrePaciente] = useState('');
    const [apellidosPaciente, setApellidosPaciente] = useState('');
    const [telefonoPaciente, setTelefonoPaciente] = useState('');
    const [loadingPaciente, setLoadingPaciente] = useState(false);
    const [errorNombrePaciente, setErrorNombrePaciente] = useState(false);
    const [errorApellidosPaciente, setErrorApellidosPaciente] = useState(false);
    const [errorTelefonoPaciente, setErrorTelefonoPaciente] = useState(false);

    const [modalOutOffice, setModalOutOffice] = useState(false);

    //MODAL SALA
    const [salaAvailable, setSalaAvailable] = useState(false);

    let location = useLocation();
    const [citaEdicion] = useState<ICita>(location.state);

    const minTime = new Date();
    minTime.setHours(8, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(22, 0, 0);

    useEffect(() => {

        if (holidays.length === 0) {
            const h = new Holiday(constantes.holidaysCountry, ConfigUtils.getProvincia()).getHolidays().filter(x => x.type === 'public');
            setHolidays(ConfigUtils.formatHolidays(h));
        }

        const promisePacientes = PacienteService.getAll();
        const promiseCitas = AgendaService.getAll();
        const promiseClasses = AgendaService.getAllClasses();

        Promise.all([promiseCitas, promisePacientes, promiseClasses])
            .then((response) => {
                const citas = AgendaUtils.formatCitas(response[0].data, response[1].data);
                const classes = ClaseUtils.formatClasses(response[2].data, actividades);
                setEvents([...citas, ...classes, ...ConfigUtils.formatEvents(holidays), ...ConfigUtils.formatOutOffice(outOffices)]);
                setShownEvents([...citas, ...classes, ...ConfigUtils.formatEvents(holidays), ...ConfigUtils.formatOutOffice(outOffices)]);
                const res = PacientesUtils.sortPacientesAlphabetically(response[1].data);
                setPacientes(res);

                if (citaEdicion) {
                    let e = AgendaUtils.formatCitas([citaEdicion], res);

                    setEvent(e[0]);
                    setModalEvent(true);
                    const selectedCita = AgendaUtils.formatEvent(e[0]);
                    setCita(selectedCita);

                    if (selectedCita === undefined) return;
                    setIsEditCita(true);

                    setPacienteId(selectedCita.pacienteId);
                    setEspecialistaId(selectedCita.especialistaId);
                    setSalaId(selectedCita.salaId);
                    setStart(selectedCita.start);
                    setStartTime(selectedCita.start);
                    setEndTime(selectedCita.end);
                    setRepetirId(selectedCita?.repetirId);
                }

            })
            .catch((e) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Agenda.Component.useEffect', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoading(false);
            });

    }, [setAlert, history, citaEdicion, outOffices, actividades, holidays]);

    const closeModalCita = () => {

        if (!isEditCita) {
            setStart(new Date());
            setStartTime(new Date());
            setEndTime(new Date());
            setEspecialistaId(0);
            setSalaId(0);
            setPacienteId('');
            setIsEditCita(false);
            setRepetirId(0);
        }
        setErrorPaciente('');
        setModalCita(false);
    };

    const closeModalClass = () => {

        setStartClass(new Date());
        setProfesorId(0);
        setSalaClassId(0);
        setDuracion(60);
        setPacientesIds([]);
        setModalClass(false);
        setComentariosClass('');
    };

    const closeModalPaciente = () => {

        setNombrePaciente('');
        setApellidosPaciente('');
        setTelefonoPaciente('');
        setNombrePaciente('');
        setApellidosPaciente('');
        setTelefonoPaciente('');
        setErrorNombrePaciente(false);
        setErrorApellidosPaciente(false);
        setErrorTelefonoPaciente(false);
        setModalPaciente(false);
    };

    const closeModalEvent = () => {
        setModalEvent(false);
        setStart(new Date());
        setStartTime(new Date());
        setEndTime(new Date());
        setEspecialistaId(0);
        setSalaId(0);
        setPacienteId('');
        setIsEditCita(false);
        setRepetirId(0);
    };

    const closeModalClassEvent = () => {
        setModalClassEvent(false);
        setStartClass(new Date());
        setProfesorId(0);
        setSalaClassId(0);
        setDuracion(60);
        setPacientesIds([]);
        setComentariosClass('');
    };

    const closeModalOutOffice = () => {
        setModalOutOffice(false);
        setEvent(undefined);
    };

    const createCita = (slotInfo: SlotInfo) => {
        setStart(new Date(slotInfo.start));
        setStartTime(new Date(slotInfo.start));
        setEndTime(new Date(slotInfo.end));
        setModalCita(true);
    };

    const saveCita = () => {
        setLoadingCita(true);
        if (pacienteId === '') {
            setErrorPaciente('El paciente es obligatorio');
            setLoadingCita(false);
            return;
        }

        if (repetirId !== 0) {

            const inicio = new Date(start);
            inicio.setHours(new Date(startTime).getHours());
            inicio.setMinutes(new Date(startTime).getMinutes());
            const final = new Date(start);
            final.setHours(new Date(endTime).getHours());
            final.setMinutes(new Date(endTime).getMinutes());

            const newCita: ICita = {
                pacienteId,
                especialistaId,
                salaId,
                start: inicio,
                end: final,
                repetirId,
                terminarRepetir,
                userId: ConfigUtils.getUserId()
            };

            AgendaService.createRecurrence(newCita)
                .then((response: any) => {
                    setModalCita(false);
                    const createdCitas = AgendaUtils.formatCitas(response.data, pacientes);
                    setEvents([...events, ...createdCitas]);
                    setShownEvents([...events, ...createdCitas]);
                    setAlert({ open: true, severity: 'success', msg: 'Citas guardadas correctamente' });
                    setEspecialistaId(0);
                    setSalaId(0);
                    setPacienteId('');
                    setRepetirId(0);
                })
                .catch((e: any) => {
                    if (e.response.status === 401) {
                        localStorage.clear();
                        history(constantes.R_HOME);
                    } else {
                        const error: ILog = { method: 'Agenda.Component.saveCitaRecurrence', level: 'error', message: e.message, meta: e.response };
                        LogService.logError(error);
                        setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                    }
                })
                .finally(() => {
                    setLoadingCita(false);
                });

        } else {

            const inicio = new Date(start);
            inicio.setHours(new Date(startTime).getHours());
            inicio.setMinutes(new Date(startTime).getMinutes());
            const final = new Date(start);
            final.setHours(new Date(endTime).getHours());
            final.setMinutes(new Date(endTime).getMinutes());

            const newCita: ICita = {
                pacienteId,
                especialistaId,
                salaId,
                start: inicio,
                end: final,
                repetirId,
                userId: ConfigUtils.getUserId()
            };

            AgendaService.create(newCita, !salaAvailable)
                .then((response: any) => {
                    setModalCita(false);
                    setSalaAvailable(false);
                    const createdCita = AgendaUtils.formatCitas([response.data], pacientes);
                    setEvents([...events, ...createdCita]);
                    setShownEvents([...events, ...createdCita]);
                    setAlert({ open: true, severity: 'success', msg: 'Cita guardada correctamente' });
                    setEspecialistaId(0);
                    setSalaId(0);
                    setPacienteId('');
                    setRepetirId(0);

                    if (correo && pacientes.filter(p => p._id == pacienteId)[0].authEmail) {
                        setModalConfirmacion(true);
                        setCitaConfirmacion(response.data);
                    }
                })
                .catch((e: any) => {
                    if (e.response.status === 401) {
                        localStorage.clear();
                        history(constantes.R_HOME);
                    } else if (e.response.status === 418) {
                        setSalaAvailable(true);
                    } else if (e.response.status === 400) {
                        setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                    } else {
                        const error: ILog = { method: 'Agenda.Component.saveCita', level: 'error', message: e.message, meta: e.response };
                        LogService.logError(error);
                        setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                    }
                })
                .finally(() => {
                    setLoadingCita(false);
                });

        }

    };

    const updateCita = () => {

        if (pacienteId === '') {
            setErrorPaciente('El paciente es obligatorio');
            return;
        }

        if (!event || !event.id) return;

        setLoadingCita(true);

        const inicio = new Date(start);
        inicio.setHours(new Date(startTime).getHours());
        inicio.setMinutes(new Date(startTime).getMinutes());
        const final = new Date(start);
        final.setHours(new Date(endTime).getHours());
        final.setMinutes(new Date(endTime).getMinutes());

        const updatedCita: ICita = {
            pacienteId,
            especialistaId,
            salaId,
            start: inicio,
            end: final,
            repetirId,
            motivo: event.motivo,
            valoracion: event.valoracion,
            razonamiento: event.razonamiento,
            tratamiento: event.tratamiento,
            userId: ConfigUtils.getUserId()
        };

        AgendaService.update(event.id.toString(), updatedCita, !salaAvailable)
            .then((response: any) => {
                setModalCita(false);
                setSalaAvailable(false);
                closeModalEvent();
                const updatedEvent = AgendaUtils.formatCitas([response.data], pacientes);
                setEvents([...events.filter(e => e.id !== event.id), ...updatedEvent]);
                setShownEvents([...events.filter(e => e.id !== event.id), ...updatedEvent]);
                setEvent(updatedEvent[0]);
                setAlert({ open: true, severity: 'success', msg: 'Cita actualizada correctamente' });

                if (correo && pacientes.filter(p => p._id == pacienteId)[0].authEmail) {
                    setModalConfirmacion(true);
                    setCitaConfirmacion(response.data);
                }
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else if (e.response.status === 418) {
                    setSalaAvailable(true);
                } else if (e.response.status === 400) {
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                } else {
                    const error: ILog = { method: 'Agenda.Component.updateCita', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoadingCita(false);
            });
    };

    const updateClass = () => {
        setLoadingClass(true);

        if (!event || !event.id) return;
        const selectedClass = ClaseUtils.formatClassEvent(event);
        if (!selectedClass) return;

        const updatedClass: IClass = {
            title: actividades.filter(a => a.title === selectedClass.title)[0].internalID!.toString(),
            pacientesIds: pacientesIds.map(x => x._id),
            especialistaId: profesorId,
            salaId: salaClassId,
            start: new Date(startClass),
            end: new Date(new Date(startClass).setMinutes(new Date(startClass).getMinutes() + duracion)),
            repId: selectedClass.repId,
            userId: ConfigUtils.getUserId()
        };

        AgendaService.updateClass(event.id.toString(), updatedClass, !salaAvailable)
            .then((response: any) => {
                closeModalClassEvent();
                setModalClass(false);
                setSalaAvailable(false);
                const updatedEvent = ClaseUtils.formatClasses([response.data], actividades);
                setEvents([...events.filter(e => e.id !== event.id), ...updatedEvent]);
                setShownEvents([...events.filter(e => e.id !== event.id), ...updatedEvent]);
                setEvent(updatedEvent[0]);
                setAlert({ open: true, severity: 'success', msg: 'Clase actualizada correctamente' });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else if (e.response.status === 418) {
                    setSalaAvailable(true);
                } else if (e.response.status === 400) {
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                } else {
                    const error: ILog = { method: 'Agenda.Component.updateClass', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoadingClass(false);
            });
    };

    const deleteCita = () => {
        if (!event?.id) return;
        AgendaService.deleteCita(event?.id.toString())
            .then((response: any) => {
                setEvents(events.filter(x => x.id !== event.id));
                setShownEvents(events.filter(x => x.id !== event.id));
                setEvent(undefined);
                setAlert({ open: true, severity: 'success', msg: response.data.message });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Agenda.Component.deleteCita', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setModalEventDelete(false);
                closeModalEvent();
            });
    };

    const deleteClase = () => {
        if (!event?.id) return;
        AgendaService.deleteClase(event?.id.toString())
            .then((response: any) => {
                setEvents(events.filter(x => x.id !== event.id));
                setShownEvents(events.filter(x => x.id !== event.id));
                setEvent(undefined);
                setAlert({ open: true, severity: 'success', msg: response.data.message });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Agenda.Component.deleteClase', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setModalClassDelete(false);
                closeModalClassEvent();
            });
    };

    const deleteCitaRecurrente = () => {
        if (!event?.id) return;
        AgendaService.deleteRecurrence(event?.id.toString())
            .then((response: any) => {
                let updatedEvents = events;
                for (let e of response.data) {
                    updatedEvents = updatedEvents.filter(x => x.id !== e._id);
                }
                setEvents(updatedEvents);
                setShownEvents(updatedEvents);
                setEvent(undefined);
                setAlert({ open: true, severity: 'success', msg: 'Citas eliminadas correctamente' });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Agenda.Component.deleteCitaRecurrente', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setModalEventDelete(false);
                closeModalEvent();
            });
    };

    const selectCita = (event: IEvent) => {
        if (!event) return;
        if (event.allDay) return;
        if (Object.keys(event.resource).length === 0) {
            setEvent(event);
            setModalOutOffice(true);
            return;
        }

        if (ConfigUtils.getActividadesArray(actividades).includes(event.title.toLowerCase())) {

            setEvent(event);
            setModalClassEvent(true);
            const selectedClass = ClaseUtils.formatClassEvent(event);

            if (selectedClass === undefined || selectedClass.end === undefined) return;

            setPacientesIds(pacientes.filter((p) => selectedClass.pacientesIds.includes(p._id)));
            setProfesorId(selectedClass.especialistaId);
            setSalaClassId(selectedClass?.salaId);
            setStartClass(selectedClass.start);
            setDuracion(Math.abs(Math.round(((selectedClass.end.getTime() - selectedClass.start.getTime()) / 1000) / 60)));
            setComentariosClass(selectedClass.comentarios ? selectedClass.comentarios : '');
        } else {
            setEvent(event);
            setModalEvent(true);
            const selectedCita = AgendaUtils.formatEvent(event);
            setCita(selectedCita);

            if (selectedCita === undefined) return;
            setIsEditCita(true);

            setPacienteId(selectedCita.pacienteId);
            setEspecialistaId(selectedCita?.especialistaId);
            setSalaId(selectedCita?.salaId);
            setStart(selectedCita.start);
            setStartTime(selectedCita.start);
            setEndTime(selectedCita.end);
            setRepetirId(selectedCita?.repetirId);
        }
    };

    const savePaciente = () => {

        if (nombrePaciente === '') setErrorNombrePaciente(true);
        if (apellidosPaciente === '') setErrorApellidosPaciente(true);
        if (telefonoPaciente === '' || telefonoPaciente.length !== 9) setErrorTelefonoPaciente(true);
        if (nombrePaciente === '' || apellidosPaciente === '' || telefonoPaciente === '' || telefonoPaciente.length !== 9) return;

        setLoadingPaciente(true);

        const newPaciente: MinPaciente = {
            nombre: nombrePaciente,
            apellidos: apellidosPaciente,
            telefono: telefonoPaciente,
            userId: ConfigUtils.getUserId()
        };

        PacienteService.create(newPaciente)
            .then((response: any) => {
                const res = PacientesUtils.sortPacientesAlphabetically([...pacientes, response.data]);
                setPacientes(res);
                setPacienteId(response.data._id.toString());
                setAlert({ open: true, severity: 'success', msg: 'Paciente guardado correctamente' });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Agenda.Component.savePaciente', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoadingPaciente(false);
                closeModalPaciente();
            })
    };

    const onChangeStartTime = (event: Date) => {
        const time = new Date(event)
        setStartTime(event);
        setEndTime(new Date(time.setMinutes(time.getMinutes() + 60)));
    };

    const filter = (filterType: string, value: boolean) => {
        let filteredEvents: IEvent[] = [];

        switch (filterType) {
            case 'Citas':
                if (value) {
                    //Poner
                    checkedSanitarios.map((s, index) => {
                        if (s) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && x.resource.especialista === sanitarios[index].internalID && !ConfigUtils.getActividadesArray(actividades).includes(x.title.toLowerCase()))];
                        if (s && showClasses) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && x.resource.especialista === sanitarios[index].internalID && ConfigUtils.getActividadesArray(actividades).includes(x.title.toLowerCase()))];
                        if (s && showOutOffice) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && Object.keys(x.resource).length === 0 && x.title.includes(sanitarios[index].firstName))];
                        return filteredEvents;
                    });
                } else {
                    //Quitar
                    filteredEvents = shownEvents.filter(x => (ConfigUtils.getActividadesArray(actividades).includes(x.title.toLowerCase())) || (x.resource && Object.keys(x.resource).length === 0));
                }
                break;
            case 'Clases':
                if (value) {
                    //Poner
                    checkedSanitarios.map((s, index) => {
                        if (s) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && x.resource.especialista === sanitarios[index].internalID && ConfigUtils.getActividadesArray(actividades).includes(x.title.toLowerCase()))];
                        if (s && showCitas) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && x.resource.especialista === sanitarios[index].internalID)];
                        if (s && showOutOffice) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && Object.keys(x.resource).length === 0)];
                        return filteredEvents;
                    });
                } else {
                    //Quitar
                    filteredEvents = shownEvents.filter(x => !ConfigUtils.getActividadesArray(actividades).includes(x.title.toLowerCase()));
                }
                break;
            case 'OutOffice':
                if (value) {
                    //Poner
                    checkedSanitarios.map((s, index) => {
                        if (s) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && Object.keys(x.resource).length === 0 && x.title.includes(sanitarios[index].firstName))];
                        if (s && showCitas) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && x.resource.especialista === sanitarios[index].internalID && !ConfigUtils.getActividadesArray(actividades).includes(x.title.toLowerCase()))];
                        if (s && showClasses) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && x.resource.especialista === sanitarios[index].internalID && ConfigUtils.getActividadesArray(actividades).includes(x.title.toLowerCase()))];
                        return filteredEvents;
                    });
                } else {
                    //Quitar
                    filteredEvents = shownEvents.filter(x => x.resource && Object.keys(x.resource).length !== 0);
                }
                break;
            default:
                sanitarios.filter(e => e.activo == true).map((sanitario: any) => {
                    if (sanitario.firstName === filterType) {
                        if (value) {
                            //Poner
                            if (showCitas) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && x.resource.especialista === sanitario.internalID && !ConfigUtils.getActividadesArray(actividades).includes(x.title.toLowerCase()))];
                            if (showClasses) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && x.resource.especialista === sanitario.internalID && ConfigUtils.getActividadesArray(actividades).includes(x.title.toLowerCase()))];
                            if (showOutOffice) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && Object.keys(x.resource).length === 0 && x.title.includes(sanitario.firstName))];

                            checkedSanitarios.map((s: any, index: number) => {
                                if (s && showCitas) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && x.resource.especialista === sanitarios[index].internalID && !ConfigUtils.getActividadesArray(actividades).includes(x.title.toLowerCase()))];
                                if (s && showClasses) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && x.resource.especialista === sanitarios[index].internalID && ConfigUtils.getActividadesArray(actividades).includes(x.title.toLowerCase()))];
                                if (s && showOutOffice) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && Object.keys(x.resource).length === 0 && x.title.includes(sanitarios[index].firstName))];

                                return filteredEvents;
                            });
                        } else {
                            //Quitar
                            filteredEvents = shownEvents.filter(x => x.resource && x.resource.especialista !== sanitario.internalID);
                            filteredEvents = filteredEvents.filter(x => x.resource && !x.title.includes(sanitario.firstName));
                        }
                    }
                    return filteredEvents;
                });
                break;
        }

        setShownEvents(filteredEvents);
    };

    const handleChange = (empleado: any, checked: boolean, index: number) => {
        const z = [...checkedSanitarios];
        z[index] = checked;
        setCheckedSanitarios(z);
        filter(empleado.firstName, checked);
    };

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const handleFilters = () => {
        setCheckedSanitarios(new Array(sanitarios.length).fill(true));
        setShowCitas(true);
        setShowClasses(true);
        setShownEvents([...events]);
        setShowFilters(!showFilters);
    };

    const addComentarios = () => {
        if (modifiedComment === '') {
            setAlert({ open: true, severity: 'warning', msg: 'No hay comentarios que guardar' });
            return;
        }
        if (!event || !event.id) return;
        setLoadingClass(true);

        const update = {
            comentarios: modifiedComment
        };

        AgendaService.addComentarioClass(event.id.toString(), update)
            .then((response: any) => {
                const updatedEvent = ClaseUtils.formatClasses([response.data], actividades);
                setEvents([...events.filter(e => e.id !== event.id), ...updatedEvent]);
                setShownEvents([...events.filter(e => e.id !== event.id), ...updatedEvent]);
                setAlert({ open: true, severity: 'success', msg: 'Comentarios añadidos correctamente' });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Agenda.Component.addComentarios', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setModalClassComentarios(false);
                setLoadingClass(false);
                closeModalClassEvent();
            });
    };

    const setCitaAusente = () => {
        if (!event || !event.id) return;

        AgendaService.setCitaNoAsistida(event.id.toString())
        .then((response: any) => {
            setModalCita(false);
            const updatedEvent = AgendaUtils.formatCitas([response.data], pacientes);
            setEvents([...events.filter(e => e.id !== event.id), ...updatedEvent]);
            setShownEvents([...events.filter(e => e.id !== event.id), ...updatedEvent]);
            setEvent(updatedEvent[0]);
            setAlert({ open: true, severity: 'success', msg: 'Cita actualizada correctamente' });
        })
        .catch((e: any) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'Agenda.Component.setCitaAusente', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        })
        .finally(() => {
            setModalAusencia(false);
            closeModalEvent();
        });
    };

    const sendConfirmacion = () => {
        if (!citaConfirmacion) return;

        const paciente = pacientes.filter(p => p._id == citaConfirmacion.pacienteId)[0];
        if (Utilities.isNullEmptyUndefined(paciente.email)) {
            setModalConfirmacion(false);
            setAlert({ open: true, severity: 'warning', msg: 'El paciente no tiene un correo electrónico donde enviar la confirmación de cita' });
            return;
        }

        setLoadinConfirmacion(true);

        AgendaService.sendConfirmacion({ email: paciente.email, cita: citaConfirmacion, paciente, sanitarios, citaTime: AgendaUtils.formatCitaDateTime(citaConfirmacion) })
        .then(response => {
            setAlert({ open: true, severity: 'success', msg: response.data.message });
            setCitaConfirmacion(undefined);
        })
        .catch (e => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'CitaActual.Component.sendConfirmacion', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        })
        .finally(() => {
            setModalConfirmacion(false);
            setLoadinConfirmacion(false);
        });
    };

    const Event: React.FC<{ event: IEvent }> = ({ event }) => {
        const salaId = event?.resource?.sala;
        if (salaId == null) return <div><div>{event.title}</div></div>;
        const sala = salas.find(s => s.internalID == salaId);
        if (sala == null) return <div><div>{event.title}</div></div>;
        let icon;
        switch (sala?.icon) {
            case "0":
                icon = <WavesIcon sx={{ fontSize: '15px' }} />;
                break;
            case "1":
                icon = <DiamondIcon sx={{ fontSize: '15px' }} />;
                break;
            case "2":
                icon = <LandscapeIcon sx={{ fontSize: '15px' }} />;
                break;
            case "3":
                icon = <GrassIcon sx={{ fontSize: '15px' }} />;
                break;
            case "4":
                icon = <AcUnitIcon sx={{ fontSize: '15px' }} />;
                break;
            default:
                break;
        };
        
        return (
            <div>
            {/* Primera línea: hora e ícono */}
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                <span style={{ fontSize: '80%', marginRight: '5px' }}>
                    {moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}
                </span>
                {icon && <span>{icon}</span>}
            </div>
            {/* Segunda línea: título del evento */}
            <div>{event.title}</div>
        </div>
         );
    };

    try {
        return (
            <>
                <Grid>
                    <Grid container>
                        <Grid item md={12}>
                            <Container>
                                <Paper elevation={3} sx={{ marginTop: theme.spacing(3), padding: theme.spacing(1), marginBottom: theme.spacing(3) }}>
                                    {loading ? <>
                                        <Grid container>
                                            <CircularProgress style={{ margin: '20px auto' }} />
                                        </Grid>
                                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography variant="caption">Cargando agenda...</Typography>
                                        </Grid></> :
                                        <>
                                            {showFilters ?
                                                <>
                                                    <Button color="info" size="small" variant="contained" onClick={handleFilters} startIcon={<FilterOff />}>Quitar filtros</Button>
                                                    <FormGroup aria-label="position" row>
                                                        <FormControlLabel control={<Checkbox size={isMobile ? "small" : "medium"} checked={showOutOffice} onChange={() => { setShowOutOffice(!showOutOffice); filter('OutOffice', !showOutOffice) }} inputProps={{ 'aria-label': 'controlled' }} />} label="No disponible" />
                                                        {actividades.length !== 0 && <><FormControlLabel control={<Checkbox size={isMobile ? "small" : "medium"} checked={showCitas} onChange={() => { setShowCitas(!showCitas); filter('Citas', !showCitas) }} inputProps={{ 'aria-label': 'controlled' }} />} label="Citas" />
                                                        <FormControlLabel control={<Checkbox size={isMobile ? "small" : "medium"} checked={showClasses} onChange={() => { setShowClasses(!showClasses); filter('Clases', !showClasses) }} inputProps={{ 'aria-label': 'controlled' }} />} label="Clases" /></>}

                                                        {
                                                            sanitarios.filter(e => e.activo == true).map((empleado: any, index: number) => {
                                                                return (
                                                                    <FormControlLabel key={empleado.internalID} control={<Checkbox size={isMobile ? "small" : "medium"} style={{ color: empleado.color }} checked={checkedSanitarios[index]} inputProps={{ 'aria-label': 'controlled' }} />} label={empleado.firstName} onChange={(e, checked) => handleChange(empleado, checked, index)} />
                                                                );
                                                            })
                                                        }

                                                    </FormGroup>
                                                </> :
                                                <Button sx={{ marginBottom: theme.spacing(1) }} color="info" size="small" variant="contained" onClick={() => setShowFilters(!showFilters)} startIcon={<FilterOn />}>Mostrar filtros</Button>
                                            }
                                            <style>
                                                {`
                                                    /* Oculta la hora del evento */
                                                    .rbc-event-label {
                                                        display: none;
                                                    }
                                                `}
                                            </style>
                                            <Calendar
                                                selectable
                                                popup
                                                defaultDate={citaEdicion ? new Date(citaEdicion.start) : new Date()}
                                                localizer={localizer}
                                                defaultView={isMobile ? "day" : "work_week"}
                                                views={['day', 'work_week', 'week', 'month']}
                                                events={shownEvents}
                                                min={minTime}
                                                max={maxTime}
                                                style={{ height: '95vh', fontSize: '12px', wordBreak: 'break-word' }}
                                                onSelectEvent={selectCita}
                                                onSelectSlot={createCita}
                                                components={{ event: Event }}
                                                messages={{
                                                    next: ">",
                                                    previous: "<",
                                                    today: "Hoy",
                                                    month: "Mes",
                                                    work_week: "Semana laboral",
                                                    week: 'Semana',
                                                    day: "Día",
                                                    showMore: (total) => `${total} más`
                                                }}
                                                eventPropGetter={event => ({
                                                    style: {
                                                        backgroundColor: event.resource === null ? constantes.colorFestivos : Object.keys(event.resource).length === 0 ? constantes.colorNoDisponible : sanitarios.filter((s: any) => s.internalID === event.resource.especialista)[0]?.color,
                                                        border: 'none',
                                                        opacity: event?.resource?.asistencia === false ? '0.5' : '1'
                                                    }
                                                })}
                                                dayPropGetter={day => ({
                                                    style: {
                                                        backgroundColor: ConfigUtils.formatEvents(holidays).filter(festivo => festivo.start.getTime() === day.getTime()).length > 0 ? constantes.colorBackgroundFestivos : 'transparent'
                                                    }
                                                })}
                                            />
                                        </>}
                                </Paper>
                            </Container>
                        </Grid>
                    </Grid>

                    {/* AÑADIR CITA */}
                    <Dialog open={modalCita} maxWidth="lg" fullWidth onClose={closeModalCita}>

                        <DialogContent>

                            <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                                <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><AddIcon /></Avatar><div style={{ margin: '10px auto' }}>{isEditCita ? 'Cambiar cita' : 'Nueva cita'}</div>
                            </Typography>
                            <Grid container spacing={1}>

                                <Grid item md={5} xs={12}>
                                    <Autocomplete
                                        disablePortal
                                        autoComplete
                                        id="pacientes"
                                        options={pacientes}
                                        value={pacientes.filter(p => p._id === pacienteId)[0] || null}
                                        noOptionsText={'No se han encontrado pacientes'}
                                        renderOption={(props, option) => { return (<li {...props} key={option._id}>{`${option.nombre} ${option.apellidos}`}</li>) }}
                                        renderInput={(params) => <TextField {...params} autoFocus label="Paciente" value={pacienteId} variant="outlined" margin="dense" fullWidth required error={errorPaciente !== '' ? true : false} helperText={errorPaciente} InputProps={{ ...params.InputProps, startAdornment: (<InputAdornment position="start"><IconButton onClick={() => { setModalPaciente(true) }}><AddIcon titleAccess="Nuevo paciente" /></IconButton></InputAdornment>) }} />}
                                        onChange={(event, value) => { value && setPacienteId(value._id); setErrorPaciente(''); }}
                                        getOptionLabel={(option) => `${option.nombre} ${option.apellidos}` || ""}
                                    />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <TextField select value={especialistaId} variant="outlined" label="Especialista" margin="dense" fullWidth required onChange={(event) => setEspecialistaId(parseInt(event.target.value))} >
                                    {sanitarios
                                        .filter(({ activo, internalID }) => {
                                          if (isEditCita) {
                                            return activo || internalID === especialistaId; // Mostrar activos o seleccionado si está en edición
                                          }
                                          return activo; // Mostrar solo activos si es un alta
                                        })
                                        .map(({ internalID, firstName, activo }) => (
                                          <MenuItem
                                            key={internalID}
                                            value={internalID}
                                            disabled={isEditCita && internalID === especialistaId && !activo} // Deshabilitar si está seleccionado y no activo en edición
                                          >
                                            {firstName}
                                          </MenuItem>
                                    ))}
                                    </TextField>
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <TextField select value={salaId} variant="outlined" label="Sala" margin="dense" fullWidth required onChange={(event) => setSalaId(parseInt(event.target.value))} >
                                        {
                                            salas.map((sala, index) => {
                                                return (
                                                    <MenuItem key={index} value={sala.internalID ? sala.internalID : index}>{sala.title}</MenuItem>
                                                );
                                            })
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item md={2} xs={12}>

                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                        <DatePicker
                                            renderInput={(props: any) => <TextField required fullWidth {...props} />}
                                            label="Fecha"
                                            toolbarFormat="dd-mm-yyyy"
                                            value={start}
                                            onChange={(date: any) => { date && setStart(date) }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                        <TimePicker
                                            renderInput={(props: any) => <TextField required fullWidth {...props} />}
                                            label="Inicio"
                                            minutesStep={5}
                                            value={startTime}
                                            ampm={false}
                                            onChange={(date: any) => date && onChangeStartTime(date)}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                        <TimePicker
                                            renderInput={(props: any) => <TextField required fullWidth {...props} />}
                                            label="Final"
                                            minutesStep={5}
                                            value={endTime}
                                            ampm={false}
                                            onChange={(date: any) => { date && setEndTime(date); }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField disabled={isEditCita} select value={repetirId} variant="outlined" label="Repetir" fullWidth onChange={(event) => setRepetirId(parseInt(event.target.value))}>
                                        {
                                            constantes.repetir.map((r, index) => {
                                                return (
                                                    <MenuItem key={index} value={index}>{r}</MenuItem>
                                                );
                                            })
                                        }
                                    </TextField>
                                </Grid>
                                {repetirId !== 0 && <Grid item md={3} xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                        <DatePicker
                                            disabled={isEditCita}
                                            renderInput={(props: any) => <TextField fullWidth {...props} />}
                                            label="Terminar repetición"
                                            value={terminarRepetir}
                                            onChange={(date: any) => { date && setTerminarRepetir(date); }}
                                        />
                                    </LocalizationProvider>

                                </Grid>}

                            </Grid>
                        </DialogContent>
                        {loadingCita ?
                            <Grid container>
                                <CircularProgress style={{ margin: '20px auto' }} />
                            </Grid> :
                            <DialogActions>
                                <Button onClick={isEditCita ? updateCita : saveCita}>Guardar</Button>
                                <Button onClick={closeModalCita}>Cancelar</Button>
                            </DialogActions>
                        }

                    </Dialog>

                    {/* SELECCIONAR CITA */}
                    <Dialog open={modalEvent} maxWidth="lg" onClose={closeModalEvent}>
                        <DialogTitle>{`${event?.title}`}</DialogTitle>
                        <DialogContent>
                            <div>{AgendaUtils.formatDateEvent(event)}</div>
                            <div>{AgendaUtils.formatTimeEvent(event)}</div>
                        </DialogContent>
                        {event && !event?.allDay && !ConfigUtils.getActividadesArray(actividades).includes(event!.title) &&
                            <DialogActions sx={{ justifyContent: 'space-evenly' }}>
                                <Tooltip title="VER PACIENTE" placement="top">
                                    <IconButton onClick={() => history(`${constantes.R_PACIENTE}?${constantes.QP_ID}=${cita?.pacienteId}`)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="CAMBIAR CITA" placement="top">
                                    <IconButton onClick={() => setModalCita(true)}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="ELIMINAR CITA" placement="top">
                                    <IconButton onClick={() => setModalEventDelete(true)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                                {cita?.asistencia !== false && <Tooltip title="SIN ASISTENCIA A LA CITA" placement="top">
                                    <IconButton onClick={() => setModalAusencia(true)}>
                                        <LogoutIcon />
                                    </IconButton>
                                </Tooltip>}
                            </DialogActions>
                        }
                    </Dialog>

                    {/* SELECCIONAR NO DISPONIBLE */}
                    <Dialog open={modalOutOffice} maxWidth="lg" onClose={closeModalOutOffice}>
                        <DialogTitle>{`${event?.title}`}</DialogTitle>
                        <DialogContent>
                            <div>{`Desde el ${ConfigUtils.formatOutOfficeDate(event?.start)} hasta el ${ConfigUtils.formatOutOfficeDate(event?.end)}`}</div>
                        </DialogContent>
                    </Dialog>

                    {/* BORRAR CITA */}
                    <Dialog open={modalEventDelete} maxWidth="lg" onClose={() => setModalEventDelete(false)}>
                        <DialogTitle>ELIMINAR CITA</DialogTitle>
                        {

                            event?.resource.repetirId !== 0 ?

                                <><DialogContent>
                                    <DialogContentText>¿Está seguro de que desea eliminar la cita? Es una cita recurrente.</DialogContentText>
                                </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => deleteCita()} color="error">ELIMINAR SOLO ESTA CITA</Button>
                                        <Button onClick={() => deleteCitaRecurrente()} color="error">ELIMINAR TODOS LAS CITAS FUTURAS</Button>
                                        <Button onClick={() => setModalEventDelete(false)} color="primary" autoFocus>NO</Button>
                                    </DialogActions></> :

                                <><DialogContent>
                                    <DialogContentText id="alert-dialog-description">¿Está seguro de que desea eliminar la cita?</DialogContentText>
                                </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => deleteCita()} color="primary">SI</Button>
                                        <Button onClick={() => setModalEventDelete(false)} color="primary" autoFocus>NO</Button>
                                    </DialogActions></>
                        }

                    </Dialog>

                    {/* EDITAR CLASE */}
                    <Dialog open={modalClass} maxWidth="lg" fullWidth onClose={closeModalClass}>

                        <DialogContent>

                            <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                                <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><AddIcon /></Avatar><div style={{ margin: '10px auto' }}>{`Modificar clase de ${event?.title}`}</div>
                            </Typography>

                            <Grid container spacing={1}>
                                <Grid item md={6}>
                                    <Autocomplete
                                        disablePortal
                                        autoComplete
                                        id="pacientes"
                                        multiple
                                        options={pacientes}
                                        getOptionDisabled={(option) => !pacientesIds.includes(option) && pacientesIds.length === event?.resource.capacity}
                                        value={pacientesIds}
                                        noOptionsText={'No se han encontrado pacientes'}
                                        renderOption={(props, option) => { return (<li {...props} key={option._id}>{`${option.nombre} ${option.apellidos}`}</li>) }}
                                        renderInput={(params) => <TextField required {...params} label="Pacientes" value={pacienteId} variant="outlined" margin="dense" fullWidth error={pacientesIds.length > event?.resource.capacity ? true : false} helperText={`${pacientesIds.length} de ${event?.resource.capacity} pacientes por clase`} />}
                                        onChange={(event, value) => { value && setPacientesIds(value) }}
                                        getOptionLabel={(option) => `${option.nombre} ${option.apellidos}` || ""}
                                    />
                                </Grid>
                                <Grid container item md={6} spacing={1}>
                                    <Grid item md={6} xs={12}>
                                        <TextField select value={profesorId} variant="outlined" label="Especialista" margin="dense" fullWidth required onChange={(event) => setProfesorId(parseInt(event.target.value))} >
                                        {sanitarios
                                            .filter(({ activo, internalID }) => activo || internalID === profesorId) // Mostrar solo los activos o el seleccionado
                                            .map(({ internalID, firstName, activo }) => (
                                              <MenuItem
                                                key={internalID}
                                                value={internalID}
                                                disabled={internalID === profesorId && !activo} // Deshabilitar si está seleccionado y no activo
                                              >
                                                {firstName}
                                              </MenuItem>
                                        ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField select value={salaClassId} variant="outlined" label="Sala" margin="dense" fullWidth required onChange={(event) => setSalaClassId(parseInt(event.target.value))} >
                                            {
                                                salas.map((sala, index) => {
                                                    return (
                                                        <MenuItem key={index} value={sala.internalID ? sala.internalID : index}>{sala.title}</MenuItem>
                                                    );
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item md={6} xs={12} sx={{ marginTop: 1 }}>
                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                            <DateTimePicker
                                                renderInput={(props: any) => <TextField required fullWidth {...props} />}
                                                label="Fecha y hora de inicio"
                                                toolbarFormat="dd-mm-yyyy"
                                                value={startClass}
                                                ampm={false}
                                                onChange={(date: any) => { date && setStartClass(date) }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField select value={duracion} variant="outlined" label="Duración" margin="dense" fullWidth required onChange={(event) => setDuracion(parseInt(event.target.value))} >
                                            {
                                                constantes.duracion.map((item, index) => {
                                                    return (
                                                        <MenuItem key={index} value={item}>{`${item.toString()} minutos`}</MenuItem>
                                                    );
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </DialogContent>
                        {loadingClass ?
                            <Grid container>
                                <CircularProgress style={{ margin: '20px auto' }} />
                            </Grid> :
                            <DialogActions>
                                <Button onClick={updateClass}>Guardar</Button>
                                <Button onClick={closeModalClass}>Cancelar</Button>
                            </DialogActions>
                        }

                    </Dialog>

                    {/* SELECCIONAR CLASE */}
                    <Dialog open={modalClassEvent} maxWidth="lg" onClose={closeModalClassEvent}>
                        <DialogTitle>{`${event?.title} - ${sanitarios.filter((s: any) => s.internalID === event?.resource.especialista)[0]?.firstName != undefined ? sanitarios.filter((s: any) => s.internalID === event?.resource.especialista)[0]?.firstName : ''}`}</DialogTitle>
                        <DialogContent>
                            <div>{AgendaUtils.formatDateEvent(event)}</div>
                            <div>{AgendaUtils.formatTimeEvent(event)}</div>
                            <div>
                                <List dense={true}>
                                    <div>
                                        <ul>
                                        {event?.resource.pacientesIds && event?.resource.pacientesIds.map((x: string, index: number) => {

                                            return (
                                                <div key={index}>
                                                    <li>{`${pacientes.find(p => p._id === x)?.nombre} ${pacientes.find(p => p._id === x)?.apellidos}`}</li>
                                                </div>
                                            );
                                        })}
                                        </ul>
                                    </div>
                                </List>
                            </div>
                            {comentariosClass !== '' && <p>{comentariosClass}</p>}
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: 'space-evenly' }}>
                            <Button onClick={() => { setModalClassComentarios(true); setModifiedComment(comentariosClass) }} color="primary">{`${comentariosClass === '' ? 'AÑADIR COMENTARIOS' : 'ACTUALIZAR COMENTARIOS'}`}</Button>
                            <Button onClick={() => setModalClass(true)} color="primary">EDITAR ESTA CLASE</Button>
                            <Button onClick={() => setModalClassDelete(true)} color="error">BORRAR ESTA CLASE</Button>
                        </DialogActions>
                    </Dialog>

                    {/* AÑADIR COMENTARIOS A LA CLASE */}
                    <Dialog open={modalClassComentarios} maxWidth="lg" onClose={() => { setModalClassComentarios(false); setModifiedComment('') }}>
                        <DialogTitle>Añadir comentarios a la clase</DialogTitle>
                        <DialogContent>
                            <TextField multiline rows="3" value={modifiedComment} variant="outlined" label="Comentarios" margin="dense" fullWidth autoFocus onChange={(event) => setModifiedComment(event.target.value)} />
                        </DialogContent>
                        {loadingClass ?
                            <Grid container>
                                <CircularProgress style={{ margin: '20px auto' }} />
                            </Grid> :
                            <DialogActions>
                                <Button onClick={addComentarios}>Guardar</Button>
                                <Button onClick={() => { setModalClassComentarios(false); setModifiedComment('') }}>Cancelar</Button>
                            </DialogActions>
                        }
                    </Dialog>

                    {/* AÑADIR PACIENTE */}
                    <Dialog open={modalPaciente} maxWidth="lg" fullWidth onClose={closeModalPaciente}>

                        <DialogContent>

                            <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                                <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><AddIcon /></Avatar><div style={{ margin: '10px auto' }}>Nuevo paciente</div>
                            </Typography>

                            <Grid container spacing={1}>
                                <Grid item md={4} xs={12} style={{ margin: 'auto' }}>
                                    <TextField onChange={(event) => setNombrePaciente(event.target.value)} variant="outlined" label="Nombre" margin="dense" fullWidth required autoFocus error={errorNombrePaciente} helperText={errorNombrePaciente ? "El nombre es obligatorio" : ""} />
                                </Grid>
                                <Grid item md={4} xs={12} style={{ margin: 'auto' }}>
                                    <TextField onChange={(event) => setApellidosPaciente(event.target.value)} variant="outlined" label="Apellidos" margin="dense" fullWidth required error={errorApellidosPaciente} helperText={errorApellidosPaciente ? "Los apellidos son obligatorios" : ""} />
                                </Grid>
                                <Grid item md={4} xs={12} style={{ margin: 'auto' }}>
                                    <TextField onChange={(event) => setTelefonoPaciente(event.target.value)} variant="outlined" label="Teléfono" margin="dense" fullWidth required error={errorTelefonoPaciente} helperText={errorTelefonoPaciente ? "El teléfono es obligatorio" : ""} />
                                </Grid>

                            </Grid>

                        </DialogContent>
                        {loadingPaciente ?
                            <Grid container>
                                <CircularProgress style={{ margin: '20px auto' }} />
                            </Grid> :
                            <DialogActions>
                                <Button onClick={savePaciente}>Guardar</Button>
                                <Button onClick={closeModalPaciente}>Cancelar</Button>
                            </DialogActions>
                        }

                    </Dialog>

                    {/* BORRAR CLASE */}
                    <Dialog open={modalClassDelete} maxWidth="lg" onClose={() => setModalClassDelete(false)}>
                        <DialogTitle>ELIMINAR CLASE</DialogTitle>
                        <>
                            <DialogContent>
                                <DialogContentText>¿Está seguro de que desea eliminar la clase?</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => deleteClase()} color="primary">SI</Button>
                                <Button onClick={() => setModalClassDelete(false)} color="primary" autoFocus>NO</Button>
                            </DialogActions>
                        </>
                    </Dialog>

                    {/* CITA AUSENTE */}
                    <Dialog open={modalAusencia} maxWidth="lg" onClose={() => setModalAusencia(false)}>
                        <DialogTitle>MARCAR CITA COMO NO ASISTIDA</DialogTitle>
                        <>
                            <DialogContent>
                                <DialogContentText>¿Está seguro de que desea marcar la cita como no asistida?</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={setCitaAusente} color="primary">SI</Button>
                                <Button onClick={() => setModalAusencia(false)} color="primary" autoFocus>NO</Button>
                            </DialogActions>
                        </>
                    </Dialog>

                    {/* ENVIAR CONFIRMACION */}
                    <Dialog open={modalConfirmacion} onClose={() => setModalConfirmacion(false)}>
                        <DialogTitle>ENVIAR CONFIRMACION DE CITA</DialogTitle>
                        <DialogContent>
                            <DialogContentText>¿Quiere mandar una confirmación de cita por correo?</DialogContentText>
                        </DialogContent>
                    
                        {loadingConfirmacion ? 
                        <Grid container>
                            <CircularProgress style={{ margin: '20px auto' }} />
                        </Grid> :  
                        <DialogActions>
                            <Button onClick={() => sendConfirmacion()} color="primary">SI</Button>
                            <Button onClick={() => setModalConfirmacion(false)} color="primary" autoFocus>NO</Button>
                        </DialogActions>
                    }
                    </Dialog>
                
                    {/* CONFIRMAR SALA DISPONIBLE */}
                    <Dialog open={salaAvailable} maxWidth="lg" onClose={() => setSalaAvailable(false)}>
                        <DialogTitle>SALA OCUPADA</DialogTitle>
                        <>
                            <DialogContent>
                                <DialogContentText>Esta sala está ocupada por otra actividad ¿Está seguro de que desea juntar las actividades en la misma sala?</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={event && ConfigUtils.getActividadesArray(actividades).includes(event.title.toLowerCase()) ? updateClass : isEditCita ? updateCita : saveCita} color="primary">SI</Button>
                                <Button onClick={() => setSalaAvailable(false)} color="primary" autoFocus>NO</Button>
                            </DialogActions>
                        </>
                    </Dialog>
                </Grid>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </>
        );
    } catch (e: any) {
        const error: ILog = { method: 'Agenda.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }

};

export default Agenda;