import { createTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Avatar, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, InputAdornment, List, ListItem, ListItemText, MenuItem, Snackbar, TextField, Typography } from "@mui/material";
import LogService from "../../services/LogService";
import ILog from "../../types/Log";
import IMasterClass from "../../types/MasterClass";
import IPaciente from "../../types/Paciente";
import EditIcon from '@mui/icons-material/Edit';
import IAlert from '../../types/Alert';
import IActividad from '../../types/Actividad';
import ConfigUtils from '../../utils/Config';
import AgendaService from '../../services/AgendaService';
import IListaEspera from '../../types/ListaEspera';
import * as constantes from "../../common/Constantes";
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';

type ListaEsperaProps = {
    masterClasses: IMasterClass[],
    pacientes: IPaciente[],
    onClose: () => void,
    callBack: () => void
}

const ListaEspera = (props: ListaEsperaProps) => {
    const theme = createTheme();
    const history = useNavigate();

    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const [actividades] = useState<Array<IActividad>>(ConfigUtils.getActividades());

    const [listaEspera, setListaEspera] = useState<Array<IListaEspera>>([]);
    const [listaEsperaFiltered, setListaEsperaFiltered] = useState<Array<IListaEspera>>([]);

    const [modal, setModal] = useState(false);
    const [pacientesLista, setPacientesLista] = useState<IPaciente[]>([]);
    const [actividadIds, setActividadIds] = useState<number[]>([]);
    const [weekdays, setWeekdays] = useState<number[]>([]);
    const [horarioId, setHorarioId] = useState(0);
    const [comentarios, setComentarios] = useState("");

    const [modalConfirm, setModalConfirm] = useState(false);
    const [idToDelete, setIdToDelete] = useState("");

    const [searchString, setSearchString] = useState("");

    const [isEdit, setIsEdit] = useState(false);
    const [pacienteId, setPacienteId] = useState("");
    const [listaId, setListaId] = useState("");

    useEffect(() => {
        AgendaService.getAllListaEspera()
        .then((response: any) => {
            setListaEspera(response.data);
            setListaEsperaFiltered(response.data);
        })
        .catch((e: any) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'ListaEspera.Component.useEffect', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        })
        .finally(() => {
            setLoading(false);
        });
    }, [loading]);

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const close = () => {
        props.onClose();
    };

    const saveListaEspera = () => {
        //Validaciones
        if (pacientesLista.length === 0) {
            setAlert({ open: true, severity: 'warning', msg: 'Debe seleccionar al menos un paciente' });
            return;
        }

        if (actividadIds.length === 0) {
            setAlert({ open: true, severity: 'warning', msg: 'Debe seleccionar al menos una actividad' });
            return;
        }

        if (weekdays.length === 0) {
            setAlert({ open: true, severity: 'warning', msg: 'Debe seleccionar al menos un día de la semana' });
            return;
        }

        setLoading(true);

        const newListaEspera: IListaEspera[] = [];
        pacientesLista.map((paciente) => {
            const newLista: IListaEspera = {
                pacienteId: paciente._id,
                actividadIds,
                weekdays,
                horarioId,
                comentarios
            };
            newListaEspera.push(newLista);
            return newLista;
        });

        AgendaService.createListaEspera(newListaEspera)
        .then((response: any) => { 
            setAlert({ open: true, severity: 'success', msg: "Paciente creado en la lista de espera correctamente" });
        })
        .catch((e: any) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'ListaEspera.Component.createListaEspera', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        })
        .finally(() => {
            setPacientesLista([]);
            setActividadIds([]);
            setWeekdays([]);
            setHorarioId(0);
            setComentarios("");
            setModal(false);
            setLoading(false);
        });
    };

    const updateListaEspera = () => {
        if (actividadIds.length === 0) {
            setAlert({ open: true, severity: 'warning', msg: 'Debe seleccionar al menos una actividad' });
            return;
        }

        if (weekdays.length === 0) {
            setAlert({ open: true, severity: 'warning', msg: 'Debe seleccionar al menos un día de la semana' });
            return;
        }

        setLoading(true);

        const updatedLista: IListaEspera = {
            pacienteId,
            actividadIds,
            weekdays,
            horarioId,
            comentarios
        };

        AgendaService.updateListaEspera(listaId, updatedLista)
        .then((response: any) => { 
            setAlert({ open: true, severity: 'success', msg: "Paciente actualizado en la lista de espera correctamente" });
        })
        .catch((e: any) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'ListaEspera.Component.updateListaEspera', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        })
        .finally(() => {
            setPacientesLista([]);
            setActividadIds([]);
            setWeekdays([]);
            setHorarioId(0);
            setComentarios("");
            setModal(false);
            setLoading(false);
        });
    }

    const deleteListaEspera = (id: string | undefined | null) => {
        if (!id || id === null) return;

        setLoading(true);

        AgendaService.deleteListaEspera(id)
        .then((response: any) => {
            setAlert({ open: true, severity: 'success', msg: response.data.message });
        })
        .catch ((e: any) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'ListaEspera.Component.deleteListaEspera', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        })
        .finally(() => {
            setIdToDelete("");
            setModalConfirm(false);
            setLoading(false);
        });
    };

    const getClaseTitles = (listaEspera: IListaEspera): string => {

        let actividadesTitle = "";

        listaEspera.actividadIds.forEach(id => {
            const actividad = actividades.find(a => a.internalID?.toString() === id.toString());
            actividadesTitle += ` ${actividad?.title}`;
        });

        let weekDaysTitle = "";

        listaEspera.weekdays.sort((a, b) => a - b).forEach(id => {
            const dia = constantes.weekNames[id];
            weekDaysTitle += ` ${dia}`;
        });

        const result = actividadesTitle.concat('\n').concat(weekDaysTitle).concat(` de ${constantes.horarioClases[listaEspera.horarioId].toLocaleLowerCase()}`);

        return result;
    };

    const search = (e: any) => {
        setSearchString(e.target.value);
        const value = e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        let results: IListaEspera[] = [];
        const filteredPacientes = props.pacientes.filter((paciente) => paciente.nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(value) || paciente.apellidos.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(value));
        const filteredActividades = actividades.filter((actividad) => actividad.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(value));

        results = listaEspera.filter(l => 
            filteredPacientes.some(f => l.pacienteId === f._id) ||
            filteredActividades.some(a => {
                const actividadId = Number(a.internalID);
                return a.internalID !== undefined &&
                    !isNaN(actividadId) &&
                    l.actividadIds.includes(actividadId)
            })
        );

        setListaEsperaFiltered(results);
    };

    const clearFilter = () => {
        setSearchString('');
        setListaEsperaFiltered(listaEspera);
    };

    const closeModal = () => {
        setPacientesLista([]);
        setActividadIds([]);
        setWeekdays([]);
        setHorarioId(0);
        setComentarios("");
        setIsEdit(false);
        setModal(false);
    };

    const modifyListaEspera = (lista: IListaEspera) => {
        if (lista._id) setListaId(lista._id);
        setPacienteId(lista.pacienteId);
        setActividadIds(lista.actividadIds);
        setWeekdays(lista.weekdays);
        setHorarioId(lista.horarioId);
        if (lista.comentarios) setComentarios(lista.comentarios);
        setIsEdit(true);
        setModal(true);
    };
    
    try
    {
        return (
            <>
                {/* LISTA DE ESPERA */}
                <Dialog open={true} PaperProps={{ sx: { minHeight: '50vh', minWidth: '50vw' } }} onClose={close}>
                    <DialogContent>
                        <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                            <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><EditIcon /></Avatar><div style={{ margin: '10px auto' }}>Lista de espera</div>
                        </Typography>

                        <TextField variant="outlined" placeholder="Busca paciente o actividad..." fullWidth autoFocus onChange={(e) => search(e)} value={searchString} InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>), endAdornment: (<InputAdornment position="end"><IconButton onClick={clearFilter}><ClearIcon /></IconButton></InputAdornment>) }} />

                        {loading ? 
                            <Grid container>
                                <CircularProgress style={{ margin: '10px auto' }} />
                            </Grid>
                             : 
                                listaEsperaFiltered && listaEsperaFiltered.length === 0 ? <div>No se han encontrado pacientes en la lista de espera</div> : 
                                <List>
                                    {listaEsperaFiltered.map(lista => {
                                        const paciente = props.pacientes.find((p) => p._id === lista.pacienteId );
                                        return (
                                            <ListItem key={lista._id} secondaryAction={
                                                <Box display="flex" gap={2}>
                                                    <IconButton
                                                        edge="end" 
                                                        aria-label="edit" 
                                                        onClick={() => modifyListaEspera(lista)}
                                                    ><EditIcon /></IconButton>
                                                    <IconButton 
                                                        edge="end" 
                                                        aria-label="delete" 
                                                        onClick={() => { lista._id && setIdToDelete(lista._id), setModalConfirm(true)}}
                                                    ><DeleteIcon /></IconButton>
                                                </Box>
                                                }>
                                                <ListItemText style={{ maxWidth: '15vw' }} primary={`${paciente?.nombre} ${paciente?.apellidos}`} secondary={lista.comentarios} />
                                                <Typography variant="subtitle2" style={{ whiteSpace: 'pre-line', marginLeft: 50 }} >{getClaseTitles(lista)}</Typography>              
                                            </ListItem>
                                        );
                                    })}
                                </List>
                        }

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setModal(true)}>Añadir pacientes</Button>
                        <Button onClick={close}>Cancelar</Button>
                    </DialogActions>
                </Dialog>

                {/* AÑADIR PACIENTES A LA LISTA DE ESPERA */}
                <Dialog open={modal} maxWidth="lg" fullWidth PaperProps={{ sx: { minHeight: '50vh' } }} onClose={() => setModal(false)}>
                    <DialogContent>
                        <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                            <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><EditIcon /></Avatar><div style={{ margin: '10px auto' }}>{isEdit ? "Modificar lista de espera" : "Crear lista de espera"}</div>
                        </Typography>
                        {!isEdit && <Typography>Selecciona los pacientes que deseas añadir a la lista de espera</Typography>}
                    
                    {loading ? 
                            <Grid container>
                                <CircularProgress style={{ margin: '10px auto' }} />
                            </Grid>
                             : <>
                            <Grid container spacing={2}>
                                <Grid item md={5}>
                                    {!isEdit ? <Autocomplete 
                                        disablePortal
                                        autoComplete
                                        id="pacientes"
                                        multiple
                                        options={props.pacientes.filter(p => !listaEspera.some(l => l.pacienteId === p._id))}
                                        noOptionsText={'No se han encontrado pacientes'}
                                        renderOption={(props, option) => { return (<li {...props} key={option._id}>{`${option.nombre} ${option.apellidos}`}</li>) }}
                                        renderInput={(params) => <TextField required {...params} label="Pacientes" variant="outlined" margin="dense" fullWidth />}
                                        onChange={(event, value) => { value && setPacientesLista(value) }}
                                        getOptionLabel={(option) => `${option.nombre} ${option.apellidos}` || ""}
                                    /> : <TextField sx={{ mt: 1 }} label={"Paciente"} disabled fullWidth value={`${props.pacientes.find(p => p._id === pacienteId)?.nombre} ${props.pacientes.find(p => p._id === pacienteId)?.apellidos}`} />}
                                </Grid>
                                <Grid item md={5}>
                                    <TextField select SelectProps={{ multiple: true, value: actividadIds, onChange: (e: any) => setActividadIds(e.target.value) }} variant="outlined" label="Selecciona la actividad o actividades" margin="dense" fullWidth required>
                                    {
                                        actividades.map((actividad: any) => {
                                            return (
                                                <MenuItem key={actividad.internalID} value={actividad.internalID}>{actividad.title}</MenuItem>
                                            );
                                        })
                                    }
                                    </TextField>
                                </Grid>
                                <Grid item md={3} sx={{ pt: 1, pl: 2 }}>
                                    <FormControl>
                                        <FormHelperText>{"Selecciona los días de la semana *"}</FormHelperText>
                                        <FormGroup sx={{ ml: 2 }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={weekdays.includes(0) ? true : false} onChange={() => { weekdays.includes(0) ? setWeekdays(weekdays.filter(x => x !== 0)) : setWeekdays([...weekdays, 0]) }} inputProps={{ 'aria-label': 'controlled' }} name="Lunes" />
                                                }
                                                label="Lunes"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={weekdays.includes(1) ? true : false} onChange={() => { weekdays.includes(1) ? setWeekdays(weekdays.filter(x => x !== 1)) : setWeekdays([...weekdays, 1]) }} inputProps={{ 'aria-label': 'controlled' }} name="Martes" />
                                                }
                                                label="Martes"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={weekdays.includes(2) ? true : false} onChange={() => { weekdays.includes(2) ? setWeekdays(weekdays.filter(x => x !== 2)) : setWeekdays([...weekdays, 2]) }} inputProps={{ 'aria-label': 'controlled' }} name="Miércoles" />
                                                }
                                                label="Miércoles"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={weekdays.includes(3) ? true : false} onChange={() => { weekdays.includes(3) ? setWeekdays(weekdays.filter(x => x !== 3)) : setWeekdays([...weekdays, 3]) }} inputProps={{ 'aria-label': 'controlled' }} name="Jueves" />
                                                }
                                                label="Jueves"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={weekdays.includes(4) ? true : false} onChange={() => { weekdays.includes(4) ? setWeekdays(weekdays.filter(x => x !== 4)) : setWeekdays([...weekdays, 4]) }} inputProps={{ 'aria-label': 'controlled' }} name="Viernes" />
                                                }
                                                label="Viernes"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item md={2} sx={{ marginTop: 1 }}>
                                    <TextField select value={horarioId} variant="outlined" label="Horario" fullWidth onChange={(event) => setHorarioId(parseInt(event.target.value))}>
                                        {
                                            constantes.horarioClases.map((r, index) => {
                                                return (
                                                    <MenuItem key={index} value={index}>{r}</MenuItem>
                                                );
                                            })
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item md={5}>
                                    <TextField multiline rows={8} value={comentarios} onChange={(e) => setComentarios(e.target.value)} variant="outlined" label="Indica cualquier información adicional que consideres" margin="dense" fullWidth />
                                </Grid>
                        </Grid>
                        
                    </>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={!isEdit ? saveListaEspera : updateListaEspera}>Guardar</Button>
                        <Button onClick={closeModal}>Cancelar</Button>
                    </DialogActions>
                </Dialog>

                {/* CONFIRMAR BORRADO */}
                <Dialog open={modalConfirm} onClose={() => setModalConfirm(false)}>
                    <DialogTitle>BORRAR PACIENTE</DialogTitle>
                    <DialogContent>
                        <DialogContentText>¿Está seguro de que desea borrar el paciente de la lista de espera?</DialogContentText>
                    </DialogContent>
                      
                    {loading ? 
                    <Grid container>
                        <CircularProgress style={{ margin: '20px auto' }} />
                    </Grid> :   
                    <DialogActions>
                        <Button onClick={() => deleteListaEspera(idToDelete)} color="primary">SI</Button>
                        <Button onClick={() => setModalConfirm(false)} color="primary" autoFocus>NO</Button>
                    </DialogActions>} 
                </Dialog>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </>
        );
    } catch (e: any) {
        const error: ILog = { method: 'ListaEspera.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }
};

export default ListaEspera;