import { useEffect, useState } from "react";
import ConfigUtils from "../../utils/Config";
import Agenda from "../agenda/Agenda";
import Auth from "../auth/Auth";
import Config from "../config/Config";

const Home = () => {
    const [user, setUser] = useState(ConfigUtils.getCurrentUser());
    const [salas, setSalas] = useState(ConfigUtils.getSalas());

    useEffect(() => {
        const onLocationChange = () => {
            setUser(ConfigUtils.getCurrentUser());
            setSalas(ConfigUtils.getSalas());
        };
        window.addEventListener('popstate', onLocationChange);
    }, []);

    return (
        !user ? <Auth /> : salas.length === 0 ? <Config selectedTab="2" alert={{ open: true, severity: 'warning', msg: 'Es necesario crear una sala' }} /> : <Agenda />
    );
};

export default Home;