import axios from 'axios';
import * as constantes from '../common/Constantes';

const baseURL = process.env.REACT_APP_API_URL || "http://localhost:3000/";

const API = axios.create({
    baseURL: baseURL,
    headers: { 'Content-types': 'application/json' }
});

API.interceptors.request.use((req) => {
    
    if (localStorage.getItem(constantes.LS_profile)) {
        const token = localStorage.getItem(constantes.LS_profile);
        req.headers!.Authorization = token ? `Bearer ${JSON.parse(token).token}` : '';
    }

    return req;
});

export default API;