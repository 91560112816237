import { Container, Grid, IconButton, Paper, Snackbar, Tooltip, Typography, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LogService from "../../../../services/LogService";
import ILog from "../../../../types/Log";
import IPaciente from "../../../../types/Paciente";
import { createTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ICita from "../../../../types/Cita";
import AgendaService from "../../../../services/AgendaService";
import CitaPasada from "./CitaPasada";
import IAlert from "../../../../types/Alert";
import * as constantes from "../../../../common/Constantes";

type CitasPasadasProps = {
    paciente: IPaciente;
    esMenor: boolean;
}

const CitasPasadas = (props: CitasPasadasProps) => {
    const theme = createTheme();
    const history = useNavigate();

    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const [citasPasadas, setCitasPasadas] = useState<Array<ICita>>([]);
    const [verMasCitasPasadas, setVerMasCitasPasadas] = useState(false);

    useEffect(() => {

        AgendaService.getCitasPasadasPaciente(props.paciente._id)
        .then((response) => {
             setCitasPasadas(response.data);
        })
        .catch((e) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'CitasPasadas.Component.useEffect', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        });

    }, [setCitasPasadas, setAlert, history, props.paciente]);

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    try {
        return (
            <>
            <Grid item md={1}></Grid>
            <Grid item md={11} xs={12}>
                <Container maxWidth="xl">
                <Paper elevation={3} sx={{ marginBottom: theme.spacing(3), marginTop: theme.spacing(3), padding: theme.spacing(3), display: 'flex', flexDirection: 'column', alignItems: 'center' }} >

                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        {citasPasadas.length === 0 ? null : verMasCitasPasadas ?
                        <Tooltip title="VER MENOS INFORMACIÓN" >
                            <IconButton onClick={() => setVerMasCitasPasadas(false)}>
                                <RemoveIcon />
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title="VER MÁS INFORMACIÓN" >
                            <IconButton onClick={() => setVerMasCitasPasadas(true)}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>}

                        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', marginLeft: '10px' }} >CITAS PASADAS</Typography>

                        <AddIcon style={{ color: 'white' }} />
                    </div>
                    { 
                        citasPasadas && verMasCitasPasadas && citasPasadas.map((cita: ICita) => {
                            return (
                                <CitaPasada cita={cita} key={cita._id} esMenor={props.esMenor} paciente={props.paciente} />
                            );
                        })
                    }
                </Paper>
                </Container>
            </Grid>

            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
            </Snackbar>
            </>
        );

    } catch (e: any) {
        const error: ILog = { method: 'CitasPasadas.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }
};

export default CitasPasadas;