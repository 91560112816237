import { Alert, Avatar, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LogService from "../../services/LogService";
import IAlert from "../../types/Alert";
import ILog from "../../types/Log";
import IMail from "../../types/Mail";
import ConfigUtils from "../../utils/Config";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ConfigService from "../../services/ConfigService";
import Utilities from "../../common/Utilities";
import { CONFIGTYPES } from "../../types/Config";
import * as constantes from "../../common/Constantes";

const Mail = () => {
    const theme = createTheme();
    const history = useNavigate();

    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const [loading, setLoading] = useState(false);
    const [correo, setCorreo] = useState<IMail | undefined>(ConfigUtils.getMail());
    const [isEdit, setIsEdit] = useState(ConfigUtils.getMail() === undefined ? false : true);
    const [modalCorreo, setModalCorreo] = useState(false);

    useEffect(() => {
        setCorreo(ConfigUtils.getMail());
    }, [isEdit]);

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const handleChange = (type: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        let mail: IMail = {
            _id: correo ? correo._id ? correo._id : undefined : undefined,
            nombre: correo ? correo.nombre ? correo?.nombre : '' : '',
            smtp: correo ? correo.smtp ? correo.smtp : '' : '',
            user: correo ? correo.user ? correo.user : '' : '',
            pass: correo ? correo.pass ? correo.pass : '' : '',
            userId: correo ? correo.userId ? correo.userId : '' : ''
        }

        switch (type) {
            case 'nombre':
                mail.nombre = event.target.value;
                break;
            case 'smtp':
                mail.smtp = event.target.value;
                break;
            case 'user':
                mail.user = event.target.value;
                break;
            case 'pass':
                mail.pass = event.target.value;
                break;
        }

        setCorreo(mail);
    };

    const closeModalCorreo = () => {
        if (isEdit) setCorreo(ConfigUtils.getMail());
        else setCorreo(undefined);
        setModalCorreo(false);
    };

    const saveCorreo = () => {
        if (!correo) return;

        if (Utilities.isNullEmptyUndefined(correo.nombre) || Utilities.isNullEmptyUndefined(correo.smtp) || Utilities.isNullEmptyUndefined(correo.user) || Utilities.isNullEmptyUndefined(correo.pass)) {
            setAlert({ open: true, severity: 'warning', msg: 'Falta configuración' });
            return;
        }

        if (!correo.user.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            setAlert({ open: true, severity: 'warning', msg: 'El usuario debe ser un email válido' });
            return;
        }

        setLoading(true);

        if (isEdit) {
            if (!correo._id) return;

            ConfigService.updateMail(correo?._id, correo)
                .then((response: any) => {
                    ConfigUtils.editConfig(CONFIGTYPES.mail.toString(), response.data);
                    setAlert({ open: true, severity: 'success', msg: 'Configuración de correo actualizada correctamente' });
                })
                .catch((e: any) => {
                    if (e.response.status === 401) {
                        localStorage.clear();
                        history(constantes.R_HOME);
                    } else {
                        const error: ILog = { method: 'Mail.Component.updateMail', level: 'error', message: e.message, meta: e.response };
                        LogService.logError(error);
                        setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                    }
                })
                .finally(() => {
                    setLoading(false);
                    closeModalCorreo();
                });
        } else {
            correo.userId = ConfigUtils.getUserId();
            ConfigService.createMail(correo)
                .then((response: any) => {
                    ConfigUtils.addConfig(CONFIGTYPES.mail.toString(), response.data);
                    setIsEdit(true);
                    setAlert({ open: true, severity: 'success', msg: 'Configuración de correo guardada correctamente' });
                })
                .catch((e: any) => {
                    if (e.response.status === 401) {
                        localStorage.clear();
                        history(constantes.R_HOME);
                    } else {
                        const error: ILog = { method: 'Mail.Component.createMail', level: 'error', message: e.message, meta: e.response };
                        LogService.logError(error);
                        setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                    }
                })
                .finally(() => {
                    setLoading(false);
                    closeModalCorreo();
                });
        }
    };

    try {
        return (
            <Grid>
                <Grid container>
                    <Grid item md={12}>
                        <Container>
                            <Grid>
                                <Grid container spacing={2}>
                                    <Grid item md={3} xs={12}>
                                        <Button onClick={() => setModalCorreo(true)} sx={{ backgroundColor: '#63ad3c', "&:hover": { backgroundColor: '#7dc557' } }} variant="contained" >
                                            <Typography variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', color: 'white' }}>{`${isEdit ? 'Editar configuración' : 'Configurar correo'}`}</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                                {isEdit && <Grid container spacing={2} sx={{ mt: theme.spacing(1) }}>
                                    <Grid item md={3} xs={12} style={{ margin: 'auto' }}>
                                        <TextField value={correo?.nombre ? correo.nombre : ''} variant="filled" label="Nombre" margin="normal" fullWidth inputProps={{ readOnly: true }} />
                                    </Grid>
                                    <Grid item md={3} xs={12} style={{ margin: 'auto' }}>
                                        <TextField value={correo?.smtp ? correo.smtp : ''} variant="filled" label="SMTP" margin="normal" fullWidth inputProps={{ readOnly: true }} />
                                    </Grid>
                                    <Grid item md={3} xs={12} style={{ margin: 'auto' }}>
                                        <TextField value={correo?.user ? correo.user : ''} variant="filled" label="Usuario" margin="normal" fullWidth inputProps={{ readOnly: true }} />
                                    </Grid>
                                    <Grid item md={3} xs={12} style={{ margin: 'auto' }}>
                                        <TextField type={'password'} value={correo?.pass ? correo.pass : ''} variant="filled" label="Contraseña" margin="normal" fullWidth inputProps={{ readOnly: true }} />
                                    </Grid>
                                </Grid>}
                            </Grid>
                        </Container>
                    </Grid>
                </Grid>

                {/* AÑADIR CORREO */}
                <Dialog open={modalCorreo} maxWidth="lg" fullWidth onClose={closeModalCorreo}>
                    <DialogContent>
                        <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                            <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}>{isEdit ? <EditIcon /> : <AddIcon />}</Avatar><div style={{ margin: '10px auto' }}>{isEdit ? 'Editar configuración' : 'Configurar correo'}</div>
                        </Typography>

                        <Grid container spacing={1}>
                            <Grid item md={3} xs={12} style={{ margin: 'auto' }}>
                                <TextField value={correo?.nombre ? correo.nombre : ''} onChange={(event) => handleChange('nombre', event)} variant="outlined" label="Nombre" margin="dense" fullWidth required autoFocus />
                            </Grid>
                            <Grid item md={3} xs={12} style={{ margin: 'auto' }}>
                                <TextField value={correo?.smtp ? correo.smtp : ''} onChange={(event) => handleChange('smtp', event)} variant="outlined" label="SMTP" margin="dense" fullWidth required />
                            </Grid>
                            <Grid item md={3} xs={12} style={{ margin: 'auto' }}>
                                <TextField value={correo?.user ? correo.user : ''} onChange={(event) => handleChange('user', event)} variant="outlined" label="Usuario" margin="dense" fullWidth required />
                            </Grid>
                            <Grid item md={3} xs={12} style={{ margin: 'auto' }}>
                                <TextField type={'password'} value={correo?.pass ? correo.pass : ''} onChange={(event) => handleChange('pass', event)} variant="outlined" label="Contraseña" margin="dense" fullWidth required />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    {loading ?
                        <Grid container>
                            <CircularProgress style={{ margin: '20px auto' }} />
                        </Grid> :
                        <DialogActions>
                            <Button onClick={saveCorreo}>Guardar</Button>
                            <Button onClick={closeModalCorreo}>Cancelar</Button>
                        </DialogActions>}

                </Dialog>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </Grid>
        );
    } catch (e: any) {
        const error: ILog = { method: 'Mail.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }
};

export default Mail;