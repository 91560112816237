import { CircularProgress, Container, Grid, IconButton, Paper, Snackbar, TextField, Tooltip, Typography, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogService from "../../../services/LogService";
import IHistoriaClinica from "../../../types/HistoriaClinica";
import ILog from "../../../types/Log";
import IPaciente from "../../../types/Paciente";
import { createTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PacienteService from "../../../services/PacienteService";
import IAlert from "../../../types/Alert";
import * as constantes from "../../../common/Constantes";

type HistoriaClinicaProps = {
    paciente: IPaciente
}

type HistoriaClinicaForm = {
    paciente: IPaciente,
    historiaClinica?: IHistoriaClinica
}

const HistoriaClinica = (props: HistoriaClinicaProps) => {
    const theme = createTheme();
    const history = useNavigate();

    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const [historiaClinica, setHistoriaClinica] = useState<IHistoriaClinica>();
    const [historiaClinicaForm, setHistoriaClinicaForm] = useState<HistoriaClinicaForm>();
    const [verMasHC, setVerMasHC] = useState(false);

    useEffect(() => {

        setHistoriaClinicaForm({ paciente: props.paciente });

        PacienteService.getHClinica(props.paciente._id)
            .then((response: any) => {
                setHistoriaClinica(response.data);
                setHistoriaClinicaForm({ paciente: props.paciente, historiaClinica: response.data });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'HistoriaClinica.Component.useEffect', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoading(false);
            });

    }, [setHistoriaClinica, setLoading, setAlert, history, props.paciente, setHistoriaClinicaForm]);

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    try {
        return (
            <>
                <Grid item md={1}></Grid>
                <Grid item md={11} xs={12}>
                    <Container maxWidth="xl">
                        <Paper elevation={3} sx={{ marginTop: theme.spacing(3), padding: theme.spacing(3), display: 'flex', flexDirection: 'column', alignItems: 'center' }} >

                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                {!historiaClinica ? null : verMasHC ?
                                    <Tooltip title="VER MENOS INFORMACIÓN" >
                                        <IconButton onClick={() => setVerMasHC(false)}>
                                            <RemoveIcon />
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <Tooltip title="VER MÁS INFORMACIÓN" >
                                        <IconButton onClick={() => setVerMasHC(true)}>
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>}

                                <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', marginLeft: '10px' }} >HISTORIA CLÍNICA</Typography>

                                {historiaClinica ?
                                    <Link to={constantes.R_HISTORIACLINICA} state={historiaClinicaForm} style={{ textDecoration: 'none' }} >
                                        <Tooltip title="EDITAR HISTORIA CLÍNICA" >
                                            <IconButton>
                                                <EditIcon style={{ float: 'right', cursor: 'pointer', color: 'grey' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Link> :
                                    <Link to={constantes.R_HISTORIACLINICA} state={historiaClinicaForm} style={{ textDecoration: 'none' }} >
                                        <Tooltip title="AÑADIR HISTORIA CLÍNICA" >
                                            <IconButton>
                                                <AddIcon style={{ float: 'right', cursor: 'pointer', color: 'grey' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                }
                            </div>

                            {loading ? <CircularProgress /> :
                                historiaClinica && verMasHC &&
                                <Grid container spacing={2}>
                                        <Grid item md={12} xs={12}>
                                            <Typography style={{ fontWeight: 'bold', marginLeft: '10px' }} variant="subtitle1">Antecedentes personales:</Typography>
                                        </Grid>  
                                        <Grid item md={6} xs={12}>
                                            <TextField fullWidth multiline rows={2} label="Medicación" value={historiaClinica?.medicacion != null ? historiaClinica?.medicacion : ''} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField fullWidth multiline rows={2} label="Traumáticas" value={historiaClinica?.traumaticas != null ? historiaClinica?.traumaticas : ''} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField fullWidth multiline rows={2} label="Intervenciones quirúrgicas, cicatrices (diagnóstico y evolución)" value={historiaClinica?.intervenciones != null ? historiaClinica?.intervenciones : ''} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField fullWidth multiline rows={2} label="Alergias" value={historiaClinica?.alergias != null ? historiaClinica?.alergias : ''} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField fullWidth multiline rows={2} label="Enfermedades (año, diagnóstico, tratamiento y evolución)" value={historiaClinica?.enfermedades != null ? historiaClinica?.enfermedades : ''} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <Typography style={{ fontWeight: 'bold', marginLeft: '10px' }} variant="subtitle1">Antecedentes familiares:</Typography>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <TextField fullWidth multiline rows={2} value={historiaClinica?.antecedentesFamilliares != null ? historiaClinica?.antecedentesFamilliares : ''} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <Typography style={{ fontWeight: 'bold', marginLeft: '10px' }} variant="subtitle1">Anamnesis por aparatos:</Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField fullWidth multiline rows={2} label="Sistema nervioso (sueño/SNC/SNP)" value={historiaClinica?.sistemaNervioso != null ? historiaClinica?.sistemaNervioso : ''} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField fullWidth multiline rows={2} label="Aparato circulatorio" value={historiaClinica?.aparatoCirculatorio != null ? historiaClinica?.aparatoCirculatorio : ''} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField fullWidth multiline rows={2} label="Aparato respiratorio" value={historiaClinica?.aparatoRespiratorio != null ? historiaClinica?.aparatoRespiratorio : ''} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField fullWidth multiline rows={2} label="Aparato genito-urinario" value={historiaClinica?.aparatoGenitoUrinario != null ? historiaClinica?.aparatoGenitoUrinario : ''} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField fullWidth multiline rows={2} label="Aparato locomotor" value={historiaClinica?.aparatoLocomotor != null ? historiaClinica?.aparatoLocomotor : ''} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField fullWidth multiline rows={2} label="Aparato digestivo" value={historiaClinica?.aparatoDigestivo != null ? historiaClinica?.aparatoDigestivo : ''} InputProps={{ readOnly: true }} />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField fullWidth multiline rows={2} label="Sistema endocrino" value={historiaClinica?.sistemaEndocrino != null ? historiaClinica?.sistemaEndocrino : ''} InputProps={{ readOnly: true }} />
                                        </Grid>
                                </Grid>
                            }
                        </Paper>
                    </Container>
                </Grid>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </>
        );

    } catch (e: any) {
        const error: ILog = { method: 'HistoriaClinica.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }
};

export default HistoriaClinica;