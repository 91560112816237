import decode from 'jwt-decode';
import LogService from "../services/LogService";
import IEvent from "../types/Event";
import ILog from "../types/Log";
import IOutOffice from "../types/OutOffice";
import IConfig from "../types/Config";
import ISala from '../types/Sala';
import Utilities from '../common/Utilities';
import IActividad from '../types/Actividad';
import IHoliday from '../types/Holiday';
import { HolidaysTypes } from 'date-holidays';
import * as constantes from "../common/Constantes";

const formatOutOfficeDate = (date: Date | undefined) => {
    try {
        if (!date) return;
        
        let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = '' + (d.getHours()),
        minutes = '' + d.getMinutes();

        if (minutes.length < 2) minutes = '0' + minutes;

        return `${day}/${month}/${year} a las ${hours}:${minutes}`;
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.formatOutOfficeDate', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const formatOutOffice = (data: IOutOffice[]): IEvent[] => {
    try {
        const formattedOutOffices: IEvent[] = [];

        if (data.length === 0) return formattedOutOffices;

        data.map(x => {

            let formattedOutOffice: IEvent = {
                id: x._id,
                title: `NO DISPONIBLE - ${getEmpleados().filter((z: any) => z.internalID === x.especialistaId)[0]?.firstName != undefined ? getEmpleados().filter((z: any) => z.internalID === x.especialistaId)[0]?.firstName : ''}`,
                start: new Date(x.start),
                end: new Date (x.end),
                resource: {  }
            };
            formattedOutOffices.push(formattedOutOffice);
            return formattedOutOffice;
        });
        return formattedOutOffices;
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.formatOutOffice', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return [];
    }
};

const addConfig = (configType: string, newConfig: any) => {
    try {
        const config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);

        if (configType === 'mail') {
            config.mail = newConfig;
            localStorage.setItem(constantes.LS_config, JSON.stringify(config));
            return;
        }
        if (configType === 'asistenciaDoc') {
            config.asistenciaDoc = newConfig;
            localStorage.setItem(constantes.LS_config, JSON.stringify(config));
            return;
        }
        if (configType === 'consentimientoDoc') {
            config.consentimientoDoc = newConfig;
            localStorage.setItem(constantes.LS_config, JSON.stringify(config));
            return;
        }

        type ObjectKey = keyof typeof config;
        const prop = configType as ObjectKey;
        config[prop].push(newConfig);
        localStorage.setItem(constantes.LS_config, JSON.stringify(config));
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.addConfig', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
    
};

const editConfig = (configType: string, newConfig: any) => {
    try {
        let config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        if (configType === 'clinicName') {
            config.clinicName = newConfig;
            localStorage.setItem(constantes.LS_config, JSON.stringify(config));
            return;
        } else if (configType === 'clinicCity') {
            config.clinicCity = newConfig;
            localStorage.setItem(constantes.LS_config, JSON.stringify(config));
            return; 
        } else if (configType === 'provincia') {
            config.provincia = newConfig;
            localStorage.setItem(constantes.LS_config, JSON.stringify(config));
            return;
        } else if (configType === 'mail') {
            config.mail = newConfig;
            localStorage.setItem(constantes.LS_config, JSON.stringify(config));
            return;
        } else if (configType === 'clinicDomicilio') {
            config.clinicDomicilio = newConfig;
            localStorage.setItem(constantes.LS_config, JSON.stringify(config));
            return;
        } else if (configType === 'clinicEmail') {
            config.clinicEmail = newConfig;
            localStorage.setItem(constantes.LS_config, JSON.stringify(config));
            return;
        } else if (configType === 'clinicTelefono') {
            config.clinicTelefono = newConfig;
            localStorage.setItem(constantes.LS_config, JSON.stringify(config));
            return;
        } else if (configType === 'consentimientoDoc') {
            config.consentimientoDoc = newConfig;
            localStorage.setItem(constantes.LS_config, JSON.stringify(config));
            return;
        }

        type ObjectKey = keyof typeof config;
        const prop = configType as ObjectKey;
        const x = [newConfig, ...config[prop].filter((u: any) => u._id !== newConfig._id)];
        //Sort segun types
        if (configType === 'users' || configType === 'actividades' || configType === 'salas') {
            x.sort((a: any,b: any) => {
                return a.internalID - b.internalID;
            });
        }
        config[prop] = x;
        localStorage.setItem(constantes.LS_config, JSON.stringify(config));
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.editConfig', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const editFestivos = (festivos: IHoliday[]) => {
    try {
        let config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        config.holidays = festivos;
        localStorage.setItem(constantes.LS_config, JSON.stringify(config));
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.editFestivos', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
}

const deleteConfig = (configType: string, id: string | null | undefined) => {
    try {
        if (id === undefined) return;
        
        let config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        type ObjectKey = keyof typeof config;
        const prop = configType as ObjectKey;

        if (id === null) {
            //Se borra todo
            config[prop] = [];
            localStorage.setItem(constantes.LS_config, JSON.stringify(config));
            return;
        }

        if (id === '') {
            //Se elemina el justificante de asistencia
            config[prop] = null;
            localStorage.setItem(constantes.LS_config, JSON.stringify(config));
            return;
        }
        
        const x = [...config[prop].filter((u: any) => u._id !== id)];
        config[prop] = x;
        localStorage.setItem(constantes.LS_config, JSON.stringify(config));
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.deleteConfig', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const getCurrentUser = () => {
    try {
        const user = JSON.parse(localStorage.getItem(constantes.LS_profile)!);
        if (!user) return null;

        const token = user?.token;
        const decodedToken: any = decode(token);

        if (decodedToken.exp * 1000 < new Date().getTime()) {
            return null;
        } else {
            return user;
        }
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getCurrentUser', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const getUserId = () => {
    try {
        const user = JSON.parse(localStorage.getItem(constantes.LS_profile)!);
        const token = user?.token;
        if (token) {
            const decodedToken: any = decode(token);
            if (decodedToken.internalID === 0) {
                return decodedToken.id;
            } else {
                return decodedToken.userId;
            }
        }
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getUserId', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const getUserRole = () => {
    try {
        const user = JSON.parse(localStorage.getItem(constantes.LS_profile)!);
        const token = user?.token;
        if (token) {
            const decodedToken: any = decode(token);
            return decodedToken.role;
        }
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getUserRole', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const getUserInternalId = () => {
    try {
        const user = JSON.parse(localStorage.getItem(constantes.LS_profile)!);
        const token = user?.token;
        if (token) {
            const decodedToken: any = decode(token);
            return decodedToken.internalID;
        }
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getUserInternalId', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const getEmpleados = () => {
    try {
        const config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        if (!config) return [];
        return config.users;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getCurrentEmpleados', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return [];
    }
};

const getSanitarios = () => {
    try {
        const config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        if (!config) return [];
        return config.users.filter((u: any) => u.role !== 2);

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getSanitarios', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return [];
    }
};

const getOutOffices = () => {
    try {
        const config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        if (!config) return [];
        return config.outoffices;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getOutOffices', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return [];
    }
};

const getClinicName = () => {
    try {
        const config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        if (!config) return '';
        return config.clinicName;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getClinicName', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return '';
    }
};

const getClinicCity = () => {
    try {
        const config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        if (!config) return '';
        return config.clinicCity;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getClinicCity', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return '';
    }
}

const getClinicDomicilio = () => {
    try {
        const config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        if (!config) return '';
        return config.clinicDomicilio == undefined ? '' : config.clinicDomicilio;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getClinicDomicilio', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return '';
    }
}

const getClinicEmail = () => {
    try {
        const config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        if (!config) return '';
        return config.clinicEmail == undefined ? '' : config.clinicEmail;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getClinicEmail', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return '';
    }
}

const getClinicTelefono = () => {
    try {
        const config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        if (!config) return '';
        return config.clinicTelefono == undefined ? '' : config.clinicTelefono;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getClinicTelefono', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return '';
    }
}

const getProvincia = () => {
    try {
        const config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        if (!config) return '';
        return config.provincia;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getProvincia', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return '';
    }
};

const getActividades = () => {
    try {
        const config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        if (!config) return [];
        return config.actividades;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getActividades', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return [];
    }
};

const getSalas = () => {
    try {
        const config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        if (!config) return [];
        return config.salas;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getSalas', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return [];
    }
};

const updateSalas = (salas: ISala[], idActividad: string | undefined | null) => {
    try {
        if (Utilities.isNullEmptyUndefined(idActividad)) return [];

        salas.map((sala: ISala) => {
            sala.actividades = sala.actividades.filter(a => a !== idActividad);
            return sala;
        });

        return salas;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.updateSalas', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return [];
    }
};

const getActividadesArray = (actividades: IActividad[]) => {
    try {
        const actividadesArr: string[] = [];
        actividades.map((actividad) => {
            actividadesArr.push(actividad.title.toLowerCase());
            return actividad;
        });

        return actividadesArr;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getActividadesArray', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return [];
    }
};

const getSalaByActividad = (salas: ISala[], actividad: IActividad) => {
    try {
        let result: string = '';
        salas.map((s) => {
            if (!actividad._id) return s;
            if (s.actividades.includes(actividad._id)) {
                if (s.internalID === null || s.internalID === undefined) return s;
                result = s.internalID;
            }
            return s;
        });
        return result;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getSalaByActividad', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const getHolidays = () => {
    try {
        const config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        if (!config) return [];
        return config.holidays;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getHolidays', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return [];
    }
};

const getMail = () => {
    try {
        const config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        if (!config) return undefined;
        return config.mail;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getMail', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return undefined;
    }
};

const getAsistenciaDoc = () => {
    try {
        const config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        if (!config) return undefined;
        return config.asistenciaDoc;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getAsistenciaDoc', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return undefined;
    }
};

const getConsentientoDoc = () => {
    try {
        const config: IConfig = JSON.parse(localStorage.getItem(constantes.LS_config)!);
        if (!config) return null;
        return config.consentimientoDoc;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.getConsentientoDoc', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return null;
    }
};

const formatHolidays = (holidays: HolidaysTypes.Holiday[]) => {
    try {
        const festivos: IHoliday[] = [];
        holidays.map((h) => {
            festivos.push({ start: new Date(h.start), end: new Date(h.end), name: h.name, userId: getUserId() });
        });
        return festivos;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.formatHolidays', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return [];
    }
};

const formatEvents = (holidays: IHoliday[]): IEvent[] => {
    try {
        const festivos: IEvent[] = [];
        holidays.map((h, index) => {
            festivos.push({ id: h._id ? h._id : index, title: h.name, allDay: true, start: new Date(h.start), end: new Date(h.end), resource: null });
        });
        return festivos;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Config.toEvents', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return [];
    }
};

const ConfigUtils = {
    formatOutOfficeDate,
    formatOutOffice,
    addConfig,
    editConfig,
    editFestivos,
    deleteConfig,
    getEmpleados,
    getSanitarios,
    getOutOffices,
    getCurrentUser,
    getUserId,
    getUserRole,
    getUserInternalId,
    getClinicName,
    getClinicCity,
    getClinicDomicilio,
    getClinicEmail,
    getClinicTelefono,
    getProvincia,
    getActividades,
    getSalas,
    updateSalas,
    getActividadesArray,
    getSalaByActividad,
    getHolidays,
    formatHolidays,
    formatEvents,
    getMail,
    getAsistenciaDoc,
    getConsentientoDoc
};

export default ConfigUtils;