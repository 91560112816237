import API from '../api/index';
import IDocumento from '../types/Documento';
import IHistoriaClinica from '../types/HistoriaClinica';
import IMinPaciente from '../types/MinPaciente';
import IPaciente from '../types/Paciente';

const getAll = () => API.get('/pacientes');
const create = (data: IPaciente | IMinPaciente) => API.post('/pacientes', data);
const get = (_id: string) => API.get(`/paciente${_id}`);
const update = (_id: string, data: IPaciente) => API.patch(`/paciente${_id}`, data);
const deleteP = (_id: string) => API.delete(`/paciente${_id}`);

const getDocs = (_id: string) => API.get(`/documento${_id}`);
const getDocCI = (_id: string) => API.get(`/documento/CI${_id}`);
const createDoc = (data: FormData) => API.post('/documento', data);
const deleteDoc = (_id: string) => API.delete(`/documento${_id}`);

const createHClinica = (data: IHistoriaClinica) => API.post('/historiaclinica', data);
const getHClinica = (_id: string) => API.get(`/historiaclinica${_id}`);
const updateHClinica = (_id: string, data: IHistoriaClinica) => API.patch(`/historiaclinica${_id}`, data);

const sendConsentimiento = (data: any) => API.post('/sendConsentimiento', data);

const getClases = (_id: string) => API.get(`/clases${_id}`);

const PacienteService = {
    getAll,
    create,
    get,
    update,
    createDoc,
    deleteDoc,
    deleteP,
    getDocs,
    getDocCI,
    createHClinica,
    getHClinica,
    updateHClinica,
    sendConsentimiento,
    getClases
};

export default PacienteService;