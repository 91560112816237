import { Alert, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import { useNavigate } from "react-router-dom";
import Utilities from "../../common/Utilities";
import ConfigService from "../../services/ConfigService";
import LogService from "../../services/LogService";
import IAlert from "../../types/Alert";
import { CONFIGTYPES } from "../../types/Config";
import IAsistenciaDoc from "../../types/AsistenciaDoc";
import ILog from "../../types/Log";
import ConfigUtils from "../../utils/Config";
import * as constantes from "../../common/Constantes";

const JA = () => {
    const theme = createTheme();
    const history = useNavigate();

    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState(`Sirva este documento para acreditar la presencia en la clínica ${ConfigUtils.getClinicName()} al paciente antes mencionado y como justificante de falta de asistencia.`);

    const [imgSrc, setImgSrc] = useState(null);
    const [showImgSrc, setShowImgSrc] = useState(true);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);

    const [asistenciaDoc, setAsistenciaDoc] = useState<IAsistenciaDoc | undefined>(ConfigUtils.getAsistenciaDoc());
    const [modalDeleteDoc, setModalDeleteDoc] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);


    const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, []);

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const clear = () => {
        setText(`Sirva este documento para acreditar la presencia en la clínica ${ConfigUtils.getClinicName()} al paciente antes mencionado y como justificante de falta de asistencia.`);
        setImgSrc(null);
        setCroppedImage(null);
        setShowImgSrc(true);
    };

    const saveJustificante = () => {
        if (Utilities.isNullEmptyUndefined(text)) {
            setAlert({ open: true, severity: 'warning', msg: 'El texto en el justificante de asistencia es obligatorio' });
            return;
        }
        if (!croppedImage) {
            if (imgSrc) {
                setAlert({ open: true, severity: 'warning', msg: 'Debe aceptar la imagen para poder guardar' });
                return;
            }
            setAlert({ open: true, severity: 'warning', msg: 'El sello en el justificante de asistencia es obligatorio' });
            return;
        }

        setLoading(true);
        const justificante: IAsistenciaDoc = {
            texto: text,
            sello: croppedImage,
            userId: ConfigUtils.getUserId()
        };

        ConfigService.createAsistenciaDoc(justificante)
            .then((response: any) => {
                ConfigUtils.addConfig(CONFIGTYPES.asistenciaDoc.toString(), response.data);
                setAlert({ open: true, severity: 'success', msg: 'Justificante de asistencia guardado correctamente' });
                setAsistenciaDoc(response.data);
                setCroppedImage(null);
                setImgSrc(null);
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Docs.Component.createAsistenciaDoc', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const deleteDoc = () => {
        setLoadingDelete(true);
        ConfigService.deleteAsistenciaDoc(ConfigUtils.getUserId())
        .then((response: any) => {
            setAsistenciaDoc(undefined);
            ConfigUtils.deleteConfig(CONFIGTYPES.asistenciaDoc.toString(), '');
            setAlert({ open: true, severity: 'success', msg: response.data.message });
        })
        .catch((e: any) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'Docs.Component.deleteDoc', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        })
        .finally(() => {
            setModalDeleteDoc(false);
            setLoadingDelete(false);
        });
    };

    function readFile(file: any) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    const createImage = (url: any) =>
        new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', (error) => reject(error))
            image.src = url
        })

    async function getCroppedImg(imageSrc: any, pixelCrop: any, rotation = 0, flip = { horizontal: false, vertical: false }) {
        const image: any = await createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) return null;

        const rotRad = getRadianAngle(rotation);

        // calculate bounding box of the rotated image
        const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

        // set canvas size to match the bounding box
        canvas.width = bBoxWidth
        canvas.height = bBoxHeight

        // translate canvas context to a central location to allow rotating and flipping around the center
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
        ctx.rotate(rotRad);
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
        ctx.translate(-image.width / 2, -image.height / 2);

        // draw rotated image
        ctx.drawImage(image, 0, 0);

        const croppedCanvas = document.createElement('canvas');
        const croppedCtx = croppedCanvas.getContext('2d');

        if (!croppedCtx) return null;

        // Set the size of the cropped canvas
        croppedCanvas.width = pixelCrop.width;
        croppedCanvas.height = pixelCrop.height;

        // Draw the cropped image onto the new canvas
        croppedCtx.drawImage(
            canvas,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
          );

        // As Base64 string
        return canvas.toDataURL('image/jpeg');

        // As a blob
        /*return new Promise((resolve, reject) => {
            canvas.toBlob((file: any) => {
                resolve(URL.createObjectURL(file))
            }, 'image/jpeg')
        })*/
    }

    const getRadianAngle = (degreeValue: number) => {
        return (degreeValue * Math.PI) / 180;
    };

    const rotateSize = (width: any, height: any, rotation: number) => {
        const rotRad = getRadianAngle(rotation);

        return {
          width:
            Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
          height:
            Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        };
    };

    const onFileChange = async (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl: any = await readFile(file);
            setImgSrc(imageDataUrl);
        }
    }

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage: any = await getCroppedImg(imgSrc, croppedAreaPixels);
            setShowImgSrc(false);
            setCroppedImage(croppedImage);
        } catch (e: any) {
            const error: ILog = { method: 'Docs.Component.showCroppedImage', level: 'error', message: e.message, meta: e.response };
            LogService.logError(error);
            setAlert({ open: true, severity: 'error', msg: e.response.data.message });
        }
    }, [imgSrc, croppedAreaPixels])

    try {
        return (
            <Grid>
                <Grid container>
                    <Grid item md={12}>
                        <Container>
                            <Grid container>
                                <Grid item md={10}>
                                    <Typography variant="h6" sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1), fontWeight: 'bold' }}>JUSTIFICANTE DE ASISTENCIA</Typography>
                                </Grid>
                                <Grid item md={2}>
                                    {asistenciaDoc && <Button onClick={() => setModalDeleteDoc(true)} variant="contained" color="error">ELIMINAR</Button>}
                                </Grid>
                            </Grid>
                            <Grid item md={12}>
                                <TextField multiline variant="filled" fullWidth value={`El paciente (Nombre y Apellidos) con DNI: (DNI o DNI del tutor legal)
Acudió a esta clínica de fisioterapia el (Fecha - ej. Lunes, 2 de enero de 2023 de 15:00 a 16:00 horas.)`} inputProps={{ readOnly: true }} />
                            </Grid>
                            <Grid item md={12} sx={{ mt: theme.spacing(1) }}>
                                <TextField multiline rows={4} disabled={!Utilities.isNullEmptyUndefined(asistenciaDoc)} variant="outlined" fullWidth autoFocus value={text} onChange={(event) => setText(event.target.value)} />
                            </Grid>
                            {!asistenciaDoc && <Grid item md={12} sx={{ mt: theme.spacing(1) }}>
                                {!imgSrc && <Button variant="contained" component="label" onClick={() => { }}>
                                    Subir sello
                                    <input hidden accept="image/*" type="file" onChange={onFileChange} />
                                </Button>}
                                {imgSrc && showImgSrc && <div style={{ position: 'relative', width: '100%', height: 400, background: '#333', [theme.breakpoints.up('sm')]: { height: 400, } }}>
                                    <Cropper
                                        image={imgSrc}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={231 / 148}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                    />
                                    <Button sx={{ margin: theme.spacing(2), backgroundColor: '#63ad3c', "&:hover": { backgroundColor: '#7dc557' } }} variant="contained" color="primary" onClick={showCroppedImage}>Aceptar imagen</Button>
                                </div>}
                                {croppedImage && <img src={croppedImage} />}
                            </Grid>}
                            {asistenciaDoc && <img style={{ marginTop: '10px' }} src={asistenciaDoc.sello} />}
                        </Container>

                        {!asistenciaDoc && <Grid sx={{ ml: theme.spacing(3) }}>
                            {loading ? <CircularProgress /> :
                                <>
                                    <Button sx={{ margin: theme.spacing(2, 3, 1, 0) }} variant="contained" color="primary" onClick={saveJustificante}>Guardar</Button>
                                    <Button sx={{ margin: theme.spacing(2, 3, 1, 0) }} variant="contained" color="secondary" onClick={clear}>Cancelar</Button>
                                </>
                            }
                        </Grid>}
                    </Grid>
                </Grid>

                {/* ELIMINAR DOC */}
                <Dialog open={modalDeleteDoc} maxWidth="lg" onClose={() => setModalDeleteDoc(false)}>
                    <DialogTitle>ELIMINAR JUSITIFICANTE DE ASISTENCIA</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{`¿Está seguro de que desea eliminar el justificante de asitencia?`}</DialogContentText>
                    </DialogContent>
                    {loadingDelete ? <CircularProgress /> : <DialogActions>
                        <Button onClick={deleteDoc} color="primary">SI</Button>
                        <Button onClick={() => setModalDeleteDoc(false)} color="primary" autoFocus>NO</Button>
                    </DialogActions>}
                </Dialog>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </Grid>
        );
    } catch (e: any) {
        const error: ILog = { method: 'JA.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }
};

export default JA;