import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import IPaciente from "../../../types/Paciente";
import { createTheme } from '@mui/material/styles';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PacienteService from "../../../services/PacienteService";
import { Typography, TextField, Button, Avatar, Paper, Container, Grid, MenuItem, CircularProgress, Alert, InputAdornment, IconButton, Switch, FormControlLabel, Snackbar } from "@mui/material";
import PersonAdd from '@mui/icons-material/PersonAdd';
import AddIcon from '@mui/icons-material/Add';
import Utilities from "../../../common/Utilities";
import ILog from "../../../types/Log";
import LogService from "../../../services/LogService";
import * as constantes from "../../../common/Constantes";
import { geocodeByAddress } from "react-places-autocomplete";
import IAlert from "../../../types/Alert";
import ConfigUtils from "../../../utils/Config";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const locale = constantes.locale;

const schema = yup.object().shape({
    nombre: yup.string().required('El nombre es obligatorio'),
    apellidos: yup.string().required('Los apellidos son obligatorios'),    
    fechaNacimiento: yup.date().typeError('Debe ser una fecha válida').required('La fecha de nacimiento es obligatoria'),
    telefono: yup.string().required('El teléfono es obligatorio').min(9, 'El teléfono no tiene un formato válido').max(9, 'El teléfono no tiene un formato válido').matches(/^[0-9]*$/, 'El teléfono no tiene un formato válido'),
    email: yup.string().email()
});

const PacienteForm = () => {
    const theme = createTheme();
    const history = useNavigate();

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const {register, handleSubmit, reset, setValue, formState: { errors }} = useForm<IPaciente>({ resolver: yupResolver(schema) });

    let location = useLocation();
    const [paciente] = useState<IPaciente>(location.state);
    const [address, setAddress] = useState<string>(location.state ? location.state.domicilio : '');
    const [zipCode, setZipCode] = useState<string>(location.state ? location.state.CP : '');
    const [fechaNacimiento, setFechaNacimiento] = useState<Date | null>(location.state ? new Date(location.state.fechaNacimiento) : null);
    const [errorAddress, setErrorAddress] = useState(false);

    const [multiNumber, setMultiNumber] = useState(false);
    const [passport, setPassport] = useState(false);
    const [errorDNI, setErrorDNI] = useState('');

    const onSubmit = (data: IPaciente) => {
        if (!Utilities.isNullEmptyUndefined(data.DNI)) {
            if (passport) {
                //Validamos el pasaporte
                if (!Utilities.validatePassport(data.DNI)) {
                    setErrorDNI('El pasaporte no tiene un formato válido');
                    return;
                }
            } else {
                //Validamos el DNI o NIE
                if (Utilities.getSpanichIDType(data.DNI) === "CIF") {
                    setErrorDNI('El DNI o NIE no tiene un formato válido');
                    return;
                }
                if (!Utilities.validateSpanishID(data.DNI)) {
                    setErrorDNI('El DNI o NIE no tiene un formato válido');
                    return;
                }
            }
        }
        setLoading(true);
        paciente ? updatePaciente(data) : savePaciente(data);
    };

    const clear = () => {
        reset();
        history(-1);
    };

    const savePaciente = (data: IPaciente) => {
        data.userId = ConfigUtils.getUserId();
        PacienteService.create(data)
        .then((response: any) => {
            setLoading(false);
            history(`${constantes.R_PACIENTE}?${constantes.QP_ID}=${response.data._id}`, { state: { open: true, severity: 'success', msg: 'Paciente guardado correctamente' } });
        })
        .catch ((e: any) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'PacienteForm.Component.savePaciente', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                setLoading(false);
            }
        });
    };

    const updatePaciente = (data: IPaciente) => {
        PacienteService.update(paciente._id, data)
        .then((response: any) => {
            setLoading(false);
            history(`${constantes.R_PACIENTE}?${constantes.QP_ID}=${paciente._id}`, { state: { open: true, severity: 'success', msg: 'Paciente actualizado correctamente' } });
        })
        .catch ((e: any) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'PacienteForm.Component.updatePaciente', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                setLoading(false);
            }
        });
    };

    const handleChange = (e: any) => {
        if (e.target.name === 'actividad') {
            setValue(e.target.name, e.target.checked);
        } else if (e.target.name === 'domicilio') {
            setValue(e.target.name, e.target.value);
            setAddress(e.target.value);
        } else {
            setValue(e.target.name, e.target.value);
        }
    };

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const getZipCode = () => {
        if (address === '') return;
        geocodeByAddress(address).then((result) => {
            if (result.length === 0) {
                //No se encuentra la dirección
                setErrorAddress(true);
                return;
            }
            else if (result.length > 1) {
                //Se ha encontrado más de una dirección
                setErrorAddress(true);
                return;
            }
            const data = result[0].address_components[result[0].address_components.length - 1];
            if (data.types[0] !== constantes.googleAPI_postalCode) {
                //No se encuentra la dirección
                setErrorAddress(true);
                return;
            }
            setErrorAddress(false);
            const cp = data.long_name;
            setValue('domicilio', result[0].formatted_address);
            setAddress(result[0].formatted_address);
            setValue('CP', cp);
            setZipCode(cp);
        })
        .catch((e: any) => {
            //No se encuentra la dirección
            setErrorAddress(true);
        });
    }

    useEffect(() => {
        reset(paciente);
        setPassport(location.state && location.state.DNI ? Utilities.getSpanichIDType(location.state.DNI) === "Pasaporte" ? true : false : false);
    }, [paciente, reset]);

    try {
        return (
            <Container maxWidth="lg">
                <Paper elevation={3} sx={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(), padding: theme.spacing(3), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div>
                        <Typography variant="h4" sx={{ display: 'inline-flex', margin: theme.spacing(1, 0, 1) }}>
                            <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}> <PersonAdd /></Avatar><div style={{ margin: '10px auto' }}>{paciente ? 'Editar paciente' : 'Nuevo paciente'}</div>
                        </Typography>
                        <FormControlLabel control={<Switch defaultChecked={paciente?.actividad} {...register("actividad")} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />} label={`Clases en ${ConfigUtils.getClinicName()}?`} labelPlacement="start" />
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Grid container>
                        <Grid item md={5} style={{ margin: 'auto' }}>
                            <TextField defaultValue={paciente?.nombre} {...register("nombre")} onChange={handleChange} variant="outlined" label="Nombre" margin="dense" fullWidth required autoFocus helperText={errors.nombre?.message} error={!!errors.nombre?.message} />
                            <TextField defaultValue={paciente?.apellidos} {...register("apellidos")} onChange={handleChange} variant="outlined" label="Apellidos" margin="dense" fullWidth required helperText={errors.apellidos?.message} error={!!errors.apellidos?.message} />
                            <TextField defaultValue={paciente?.DNI} {...register("DNI")} onChange={() => {handleChange; setErrorDNI('')}} variant="outlined" label={passport ? "Pasaporte" : "DNI o NIE"} margin="dense" fullWidth helperText={errorDNI} error={!!errorDNI} InputProps={{ endAdornment: (<InputAdornment position="end"><Switch checked={passport} onChange={() => {setPassport(!passport); setErrorDNI('')}} inputProps={{ 'aria-label': 'controlled' }} /></InputAdornment>) }} />
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                <DatePicker 
                                renderInput={(props: any) => 
                                    <TextField {...register("fechaNacimiento")} variant="outlined" margin="dense" fullWidth required helperText={errors.fechaNacimiento?.message} error={!!errors.fechaNacimiento?.message} {...props} />}
                                label="Fecha de nacimiento"
                                toolbarFormat="dd-mm-yyyy"
                                value={fechaNacimiento}
                                onChange={(date: any) => { date && setFechaNacimiento(date); setValue('fechaNacimiento', date.toString()) }} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item md={5} style={{ margin: 'auto' }}>
                            <TextField defaultValue={paciente?.telefono} {...register("telefono")} onChange={handleChange} variant="outlined" label="Teléfono" margin="dense" fullWidth required helperText={errors.telefono?.message} error={!!errors.telefono?.message} InputProps={{ endAdornment: (<InputAdornment position="end"><IconButton onClick={() => { setMultiNumber(true) }}><AddIcon titleAccess="Añadir otro teléfono" /></IconButton></InputAdornment>) }} />
                            { (multiNumber || paciente?.telefonoAdicional) &&  <TextField defaultValue={paciente?.telefonoAdicional} {...register("telefonoAdicional")} onChange={handleChange} variant="outlined" label="Otro teléfono" margin="dense" fullWidth />}
                            <TextField defaultValue={paciente?.email} {...register("email")} onChange={handleChange} variant="outlined" label="Email" margin="dense" fullWidth helperText={errors.email?.message} error={!!errors.email?.message} />
                            <TextField value={address} {...register("domicilio")} onChange={handleChange} variant="outlined" label="Domicilio" margin="dense" fullWidth />
                            <TextField value={zipCode} {...register("CP")} onFocus={getZipCode} variant="outlined" label="Código postal" margin="dense" fullWidth InputProps={{ readOnly: true }} helperText={errorAddress ? 'No se encuentra la dirección' : ''} error={errorAddress} />
                        </Grid>
                        
                        <Grid item md={3} xs={12} style={{ margin: 'auto' }}>
                            <TextField select defaultValue={paciente?.sexo ? paciente.sexo : ''} {...register("sexo")} variant="outlined" label="Sexo" margin="dense" fullWidth >
                                {constantes.sexos.map((option, index) => (
                                    <MenuItem key={index} value={index}>{option}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item md={3} xs={12} style={{ margin: 'auto' }}>
                            <TextField select defaultValue={paciente?.conocido ? paciente.conocido : ''} {...register("conocido")} variant="outlined" label="¿Cómo nos has conocido?" margin="dense" fullWidth >
                                {constantes.formaConocer.map((option, index) => (
                                    <MenuItem key={index} value={index}>{option}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item md={3} xs={12} style={{ margin: 'auto' }}>
                            <TextField defaultValue={paciente?.masConocido} {...register("masConocido")} variant="outlined" label="Más..." margin="dense" fullWidth />
                        </Grid>
                        <Grid item md={12} xs={12} style={{ margin: 'auto' }}>
                            <TextField multiline rows={3} defaultValue={paciente?.actividadFisica} {...register("actividadFisica")} onChange={handleChange} variant="outlined" label="Deportes, aficiciones, actividad física habital" margin="dense" fullWidth />
                            <TextField multiline rows={3} defaultValue={paciente?.ocupacion} {...register("ocupacion")} onChange={handleChange} variant="outlined" label="Ocupación laboral y condiciones del lugar laboral" margin="dense" fullWidth />
                            <TextField multiline rows={3} defaultValue={paciente?.especialistaSalud} {...register("especialistaSalud")} onChange={handleChange} variant="outlined" label="¿Has ido a un fisioterapeuta antes u otro especialista de la salud? ¿Por qué? ¿Cuándo?" margin="dense" fullWidth />
                        </Grid>

                        <Grid style={{ margin: 'auto' }}>
                            { loading ? <CircularProgress /> : 
                            <>
                                <Button sx={{ margin: theme.spacing(2, 3, 1, 0) }} variant="contained" color="primary" type="submit" >Guardar</Button>
                                <Button sx={{ margin: theme.spacing(2, 3, 1, 0) }} variant="contained" color="secondary" onClick={clear}>Cancelar</Button>
                            </>
                            }
                        </Grid>

                    </Grid>
                    </form>
                    
                </Paper>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </Container>
        );
    } catch (e: any) {
        const error: ILog = { method: 'PacienteForm.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }
    
};

export default PacienteForm;