import { Alert, Avatar, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Snackbar, SnackbarContent, TextField, Tooltip, Typography } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { useEffect, useState } from "react";
import LogService from "../../services/LogService";
import IHoliday from "../../types/Holiday";
import ILog from "../../types/Log";
import ConfigUtils from "../../utils/Config";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Holiday from 'date-holidays';
import * as constantes from "../../common/Constantes";
import IAlert from "../../types/Alert";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Utilities from "../../common/Utilities";
import ConfigService from "../../services/ConfigService";
import { useNavigate } from "react-router-dom";

const locale = constantes.locale;

const Holidays = () => {
    const theme = createTheme();
    const history = useNavigate();

    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const [loading, setLoading] = useState(false);
    const [modalNewFestivo, setModalNewFestivo] = useState(false);
    const [defaultHolidays, setDefaultHolidays] = useState<Array<IHoliday>>(ConfigUtils.getHolidays());
    const [holidays, setHolidays] = useState<Array<IHoliday>>(ConfigUtils.getHolidays());
    const [provincia] = useState<string>(ConfigUtils.getProvincia());

    const [selectedFestivo, setSelectedFestivo] = useState<IHoliday>();
    const [modalDelete, setModalDelete] = useState(false);

    const [start, setStart] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [name, setName] = useState<string>('');
    const [errorName, setErrorName] = useState(false);

    useEffect(() => {
        return () => {
            // Executed only when component unmounts,
            let e = new Event("componentUnmount");
            document.dispatchEvent(e);
        }
    }, []);

    useEffect(() => {
        function doOnUnmount() {
            if (holidays.toString() !== defaultHolidays.toString()) {
                // Perform an Action only if something changed
                if (window.confirm('Hay cambios sin guardar, ¿Quiere guardar los cambios de los festivos antes de continuar?')) {
                    updateFestivos();
                }
            }
        }

        document.addEventListener("componentUnmount", doOnUnmount);
        return () => {
            // This is done whenever value of somethingChanged changes
            document.removeEventListener("componentUnmount", doOnUnmount);
        }

    }, [holidays, defaultHolidays]);

    useEffect(() => {
        if (holidays.length === 0) {
            const h = new Holiday(constantes.holidaysCountry, provincia).getHolidays().filter(x => x.type === 'public');
            setHolidays(ConfigUtils.formatHolidays(h));
            setDefaultHolidays(ConfigUtils.formatHolidays(h));
        }
    }, [holidays.length, setHolidays]);

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const closeModalFestivo = () => {
        setStart(new Date(new Date().setHours(0, 0, 0, 0)));
        setName('');
        setErrorName(false);
        setModalNewFestivo(false);
    };

    const deleteFestivo = () => {
        setHolidays(holidays.filter(h => h.name !== selectedFestivo?.name));
        setSelectedFestivo(undefined);
        setModalDelete(false);
    };

    const addFestivo = () => {
        if (Utilities.isNullEmptyUndefined(name)) {
            setErrorName(true);
            return;
        }
        setErrorName(false);

        const exists = holidays.filter(h => new Date(h.start).getTime() === start.getTime());
        if (exists.length !== 0) {
            setAlert({ open: true, severity: 'warning', msg: 'Ya existe un festivo ese día' });
            return;
        }

        const endFestivo = new Date(start);
        endFestivo.setDate(endFestivo.getDate() + 1);

        const updatedHolidays = [...holidays, { start, end: endFestivo, name, userId: ConfigUtils.getUserId() }];
        updatedHolidays.sort((a: IHoliday, b: IHoliday) => {
            return new Date(a.start).getTime() - new Date(b.start).getTime();
        });
        setHolidays(updatedHolidays);
        closeModalFestivo();
    };

    const updateFestivos = () => {
        setLoading(true);

        ConfigService.updateFestivos(ConfigUtils.getUserId(), holidays)
            .then((response: any) => {
                ConfigUtils.editFestivos(holidays);
                setDefaultHolidays(holidays);
                setAlert({ open: true, severity: 'success', msg: response.data.message });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Holidays.Component.updateFestivos', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    try {
        return (
            <Grid>
                <Grid container sx={{ minWidth: '680px' }}>

                    {holidays.toString() !== defaultHolidays.toString() &&
                        <Alert severity="warning" sx={{ mb: theme.spacing(1) }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ mr: theme.spacing(1) }}>Hay cambios sin guardar</Typography>
                                <Button onClick={updateFestivos} sx={{ backgroundColor: '#63ad3c', "&:hover": { backgroundColor: '#7dc557' } }} variant="contained" >
                                    <Typography variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', color: 'white' }} >Guardar cambios</Typography>
                                </Button>
                            </div>
                        </Alert>
                    }

                    {loading ?
                        <Grid container>
                            <CircularProgress style={{ margin: '20px auto' }} />
                        </Grid> :

                        <Grid item md={12}>
                            <Container>
                                <Grid>
                                    <Grid container spacing={2}>
                                        <Grid item md={4}>
                                            <Button onClick={() => setModalNewFestivo(true)} sx={{ backgroundColor: '#63ad3c', "&:hover": { backgroundColor: '#7dc557' } }} variant="contained" >
                                                <Typography variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', color: 'white' }}>Añadir festivo</Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <List dense>
                                        {holidays.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()).map((holiday: IHoliday, index: number) => {
                                            return (
                                                <ListItem divider key={index}>
                                                    <ListItemText primary={`${holiday.name} - ${new Date(holiday.start).getDate()} de ${constantes.monthNames[new Date(holiday.start).getMonth()]}`} />
                                                    <ListItemSecondaryAction>
                                                        <Tooltip title="ELIMINAR FESTIVO" ><IconButton edge="end" onClick={() => { setModalDelete(true); setSelectedFestivo(holiday) }}><DeleteIcon /></IconButton></Tooltip>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </Grid>
                            </Container>
                        </Grid>}
                </Grid>

                {/* CONFIRMACIÓN BORRAR */}
                <Dialog open={modalDelete} maxWidth="lg" onClose={() => setModalDelete(false)}>
                    <DialogTitle>ELIMINAR FESTIVO</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{`¿Está seguro de que desea eliminar el festivo ${selectedFestivo?.name}?`}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={deleteFestivo} color="primary">SI</Button>
                        <Button onClick={() => setModalDelete(false)} color="primary" autoFocus>NO</Button>
                    </DialogActions>

                </Dialog>

                {/* AÑADIR FESTIVO */}
                <Dialog open={modalNewFestivo} maxWidth="lg" fullWidth onClose={closeModalFestivo}>
                    <DialogContent>
                        <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                            <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><AddIcon /></Avatar><div style={{ margin: '10px auto' }}>Nuevo festivo</div>
                        </Typography>

                        <Grid container spacing={1}>
                            <Grid item md={4} xs={12}>
                                <TextField value={name} onChange={(e) => setName(e.target.value)} variant="outlined" label="Nombre del festivo" margin="normal" autoFocus required fullWidth error={errorName} helperText={errorName ? 'El nombre es obligatorio' : ''} />
                            </Grid>
                            <Grid item md={4} xs={12} sx={{ mt: theme.spacing(2) }}>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                    <DatePicker
                                        renderInput={(props: any) => <TextField required fullWidth {...props} />}
                                        label="Fecha"
                                        toolbarFormat="dd-mm-yyyy"
                                        value={start}
                                        onChange={(date: any) => { date && setStart(new Date(new Date(date).setHours(0, 0, 0, 0))) }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={addFestivo}>Guardar</Button>
                        <Button onClick={closeModalFestivo}>Cancelar</Button>
                    </DialogActions>
                </Dialog>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </Grid>
        );
    } catch (e: any) {
        const error: ILog = { method: 'Holidays.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }
};

export default Holidays;