export const resources = {
    CI : {
        title: 'INFORMACIÓN Y CONSENTIMIENTO DE TRATAMIENTO DE DATOS PERSONALES Y CONSENTIMIENTO DE TRATAMIENTO',
        responsable: 'RESPONSABLE',
        responsableTexto: 'Identidad: {0} con {7}: {1} y dirección postal en {2}. Teléfono {3} y email {4}',
        finalidad: 'FINALIDAD',
        finalidadTexto: 'Tratamiento de los datos para la creación de la ficha de paciente, para la prestación de servicios contratados, para la facturación de servicios contratados, para comunicaciones relacionadas con los servicios contratados, posibilidad de envío de email o whatsApp para recordatorio de citas, envío de facturas o justificantes de citas.',
        datos: 'DATOS ALMACENADOS Y TRATADOS',
        datosTexto: 'Identificación: nombre y apellidos, DNI, dirección, fecha de nacimiento, email y teléfono. Deportes, aficiones, actividad desarrollada, ocupación laboral y condiciones laborales y datos médicos.',
        acceso: 'ACCESO RESTRINGIDO',
        accesoTexto: 'Los empleados de {5}, tendrán acceso restringido a la información almacenada en la aplicación, obrando permisos generales a la agenda y a los datos de "nombre, edad, teléfono y DNI" a fin de dar operativa a las actividades cotidianas de la actividad principal del responsable.',
        legitimacion: 'LEGITIMACIÓN',
        legitimacionTexto: 'Consentimiento expreso del usuario, cumplimiento contractual y legal.',
        destinatarios: 'DESTINATARIOS',
        destinatariosTexto: 'Administraciones públicas, entidades bancarias, así como entidades que gestionan la actividad de la entidad, como el servicio informático que da soporte técnico a la aplicación.',
        plazo: 'PLAZO DE CONSERVACIÓN',
        plazoTexto: 'Mientras se mantenga la relación contractual o no conste revocación expresa del consentimiento del usuario. El plazo legalmente establecido para dar respuesta a una obligación legal.',
        derechos: 'DERECHOS',
        derechosTexto: 'Acceso, rectificación, supresión, oposición, limitación del tratamiento, portabilidad y no ejercicio de acciones individualizadas. Estos derechos podrán ejercitarse en la dirección postal o en el e-mail indicado en el apartado “Responsable”.',
        seguridad: 'MEDIDAS DE SEGURIDAD',
        seguridadTexto: 'Anonimización y codificación de datos. Medidas técnicas y organizativas (confidencialidad, control de usuarios y accesos, gestión de contraseñas y barreras ante vulnerabilidades y riesgos ante los datos).',
        autorizacion: 'AUTORIZACIONES',
        authIncorporacion: 'Autoriza incorporar sus datos a nuestra base de datos para la gestión de la relación',
        authConfirmacion: 'Confirma haber sido informado de la política de privacidad de nuestra base de datos',
        authEmail: 'Autoriza al envío de email para comunicaciones sobre el servicio contratado',
        authWhatsApp: 'Autoriza a las comunicaciones vía WhatsApp y SMS para comunicaciones sobre el servicio contratado',
        tratamiento: 'TRATAMIENTOS',
        tratamientoTexto: 'Manifiesto haber sido informado sobre los tratamientos a realizar ({6}) y posibles efectos secundarios. Estoy satisfecho con la información recibida, he podido formular toda clase de preguntas que he creído convenientes y me han aclarado todas las dudas planteadas.',
        tratamientoConfirmacion: 'Confirma haber sido informado sobre los tratamientos a realizar'
    }
}