import { useState } from "react";
import { useForm } from "react-hook-form";
import { Typography, TextField, Button, CircularProgress, Alert, Snackbar, MenuItem } from "@mui/material";
import IUser from "../../types/User";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AuthService from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import * as constantes from '../../common/Constantes';
import ILog from "../../types/Log";
import LogService from "../../services/LogService";
import { createTheme } from '@mui/material/styles';
import IAlert from "../../types/Alert";

const schema = yup.object().shape({
    email: yup.string().required('El correo electrónico es obligatorio').email('Deber ser un correo electrónico válido'),
    firstName: yup.string().required('El nombre es obligatorio'),
    lastName: yup.string().required('Los apellidos son obligatorios'),
    clinicName: yup.string().required('El nombre de la clínica es obligatorio'),
    password: yup.string().required('La contraseña es obligatoria').min(6, 'La contraseña debe tener al menos 8 caracteres').matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, 'Debe contener una mayúscula, una minúscula, un número y un carácter especial'),
    passwordConfirm: yup.string().required('La confirmación de contraseña es obligatoria').oneOf([yup.ref('password'), null], 'Las contraseñas deben coincidir'),
    code: yup.string().required('El código es obligatorio')
});

const SignUp = () => {
    const theme = createTheme();
    const history = useNavigate();

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const {register, setValue, handleSubmit, formState: { errors }} = useForm<IUser>({ resolver: yupResolver(schema) });
    const [provincia, setProvincia] = useState(constantes.defaultProvincia);

    const onSubmit = (data: IUser) => {
        setLoading(true);
        saveUser(data);
    };

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const saveUser = (data: IUser) => {
        data.role = constantes.ROLES.ADMINISTRADOR;
        AuthService.signUp(data)
        .then((response: any) => {
            authUser(data);
        })
        .catch ((e: any) => {
            const error: ILog = { method: 'SignUp.Component.saveUser', level: 'error', message: e.message, meta: e.response };
            LogService.logError(error);
            setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            setLoading(false);
        });
        
    };

    const authUser = (data: IUser) => {
        AuthService.signIn(data)
        .then((response: any) => {
            localStorage.setItem(constantes.LS_profile, JSON.stringify({ ...response.data[0] }));
            localStorage.setItem(constantes.LS_config, JSON.stringify(response.data[1]));
            setLoading(false);
            history(constantes.R_HOME);
            window.dispatchEvent(new Event('popstate'));
        })
        .catch ((e: any) => {
            const error: ILog = { method: 'SignUp.Component.authUser', level: 'error', message: e.message, meta: e.response };
            LogService.logError(error);
            setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            setLoading(false);
        });
    };

    try {
        return (
            <>
            <Typography variant="h4" sx={{ textAlign: 'center', margin: theme.spacing(1, 0, 1) }}>Registrarse</Typography>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <TextField {...register("email")} variant="outlined" label="Email" margin="normal" fullWidth required autoFocus helperText={errors.email?.message} error={!!errors.email?.message} />
                <TextField {...register("firstName")} variant="outlined" label="Nombre" margin="normal" fullWidth required helperText={errors.firstName?.message} error={!!errors.firstName?.message} />
                <TextField {...register("lastName")} variant="outlined" label="Apellidos" margin="normal" fullWidth required helperText={errors.lastName?.message} error={!!errors.lastName?.message} />
                <TextField {...register("clinicName")} variant="outlined" label="Nombre de la clínica" margin="normal" fullWidth required helperText={errors.clinicName?.message} error={!!errors.clinicName?.message} />
                <TextField {...register("clinicCity")} variant="outlined" label="Ciudad de la clínica" margin="normal" fullWidth required helperText={errors.clinicCity?.message} error={!!errors.clinicCity?.message} />                
                <TextField value={provincia} select variant="outlined" label="Provincia" margin="normal" fullWidth required onChange={(e) => {setProvincia(e.target.value), setValue("provincia", e.target.value)}} >
                    {
                        constantes.provincias.map((provincia: any, index: number) => {
                            return (
                                <MenuItem key={index} value={provincia.id}>{provincia.name}</MenuItem>
                            );
                        })
                    }
                </TextField>
                <TextField {...register("password")} variant="outlined" label="Contraseña" margin="normal" fullWidth required type="password" helperText={errors.password?.message} error={!!errors.password?.message} />
                <TextField {...register("passwordConfirm")} variant="outlined" label="Confirmar contraseña" margin="normal" fullWidth required type="password" helperText={errors.passwordConfirm?.message} error={!!errors.passwordConfirm?.message} />
                <TextField {...register("code")} variant="outlined" label="Código" margin="normal" fullWidth required type="password" helperText={errors.code?.message} error={!!errors.code?.message} />
                <Button sx={{ margin: theme.spacing(3, 0, 2) }} variant="contained" color="primary" fullWidth type="submit" >{loading ? <CircularProgress size={25} color="inherit" /> : `Registrarse`}</Button>
            </form>

            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
            </Snackbar>
            </>
        );
    } catch (e: any) {
        const error: ILog = { method: 'SignUp.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }
    
};

export default SignUp;