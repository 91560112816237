import { AppBar, Toolbar, Typography, Menu, MenuItem, Alert, Box, IconButton, Avatar, ListItemIcon, ListItemText } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import ClassIcon from '@mui/icons-material/DownhillSkiing';
import CalendarIcon from '@mui/icons-material/DateRange';
import SettingsIcon from '@mui/icons-material/Settings';
import FolderIcon from '@mui/icons-material/Folder';
import LogoutIcon from '@mui/icons-material/Logout';
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { createTheme } from '@mui/material/styles';
import ILog from "../../types/Log";
import LogService from "../../services/LogService";
import { isMobile } from "react-device-detect";
import Utilities from "../../common/Utilities";
import ConfigUtils from "../../utils/Config";
import IActividad from "../../types/Actividad";
import * as constantes from "../../common/Constantes";
import { ISimpleUser } from "../config/Empleados";


const Navbar = () => {
    const theme = createTheme();
    const history = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState(ConfigUtils.getCurrentUser());
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [actividades, setActividades] = useState<Array<IActividad>>(ConfigUtils.getActividades());
    const [empleados, setEmpleados] = useState<Array<ISimpleUser>>(ConfigUtils.getEmpleados());

    const userRole: number = ConfigUtils.getUserRole();
    const userInternalID: number = ConfigUtils.getUserInternalId();
    const empleado: ISimpleUser | undefined = empleados.find(e => e.internalID === userInternalID);

    const exit = () => {
        setUser(null);
        setAnchorEl(null);
        Utilities.logOut();
        history(constantes.R_HOME);
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (location.pathname === constantes.R_RESETPASS || location.pathname === constantes.R_ADDTOCALENDAR || location.pathname === constantes.R_CREATEPASS) return;
        const onLocationChange = () => {
            setUser(ConfigUtils.getCurrentUser());
            setActividades(ConfigUtils.getActividades());
            setEmpleados(ConfigUtils.getEmpleados());
        };

        window.addEventListener('popstate', onLocationChange);
        
        if (!user) {
            Utilities.logOut();
            history(constantes.R_HOME);
        }

    }, [history, user, location.pathname]);

    try {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" sx={{ backgroundColor: '#323232' }}>
                    <Toolbar>
                        <IconButton size={isMobile ? "medium" : "large"} edge="start" aria-label="Home" sx={{ ml: isMobile ? theme.spacing(0) : theme.spacing(4), mr: isMobile ? theme.spacing(1) : theme.spacing(9) }}>
                            <Link to={constantes.R_HOME} style={{ textDecoration: 'none' }}>
                                <HomeIcon sx={{ color: 'white' }} fontSize={isMobile ? "medium" : "large"} />
                            </Link>
                        </IconButton>

                        {user && <>
                        <IconButton size={isMobile ? "medium" : "large"} edge="start" aria-label="Agenda" sx={{ mr: isMobile ? theme.spacing(1) : theme.spacing(3) }} >
                            <Link to={constantes.R_AGENDA} style={{ display: 'flex', flexDirection: 'column', textDecoration: 'none', alignItems: 'center' }}>
                                <CalendarIcon sx={{ color: 'white' }} fontSize={isMobile ? "medium" : "large"} />
                                <Typography sx={{ color: 'white', fontSize: '12px' }}>AGENDA</Typography>
                            </Link>
                        </IconButton>
                        <IconButton size={isMobile ? "medium" : "large"} edge="start" aria-label="Pacientes" sx={{ mr: isMobile ? theme.spacing(1) : theme.spacing(3) }} >
                            <Link to={constantes.R_PACIENTES} style={{ display: 'flex', flexDirection: 'column', textDecoration: 'none', alignItems: 'center' }}>
                                <PeopleIcon sx={{ color: 'white' }} fontSize={isMobile ? "medium" : "large"} />
                                <Typography sx={{ color: 'white', fontSize: '12px' }}>PACIENTES</Typography>
                            </Link>
                        </IconButton>
                        {
                            actividades.length !== 0 &&
                            <IconButton size={isMobile ? "medium" : "large"} edge="start" aria-label="Clases" sx={{ mr: isMobile ? theme.spacing(1) : theme.spacing(3) }} >
                            <Link to={constantes.R_ACTIVIDADES} style={{ display: 'flex', flexDirection: 'column', textDecoration: 'none', alignItems: 'center' }}>
                                <ClassIcon sx={{ color: 'white' }} fontSize={isMobile ? "medium" : "large"} />
                                <Typography sx={{ color: 'white', fontSize: '12px' }}>CLASES</Typography>
                            </Link>
                            </IconButton>
                        }
                        <Box sx={{ flexGrow: 1 }} />
                        <Box>
                            <IconButton size={isMobile ? "medium" : "large"} aria-label="Configuración" color="inherit" onClick={handleMenu}>
                                <Avatar sx={{ bgcolor: empleado?.color, width: 34, height: 34, fontSize: "14px" }}>{empleado && empleado.firstName[0] + empleado.lastName[0]}</Avatar>        
                            </IconButton>
                        </Box>
                        <Menu
                            id="menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {userRole == constantes.ROLES.ADMINISTRADOR && <Link to={constantes.R_CONFIG} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>
                                <MenuItem divider onClick={handleClose}>
                                    <ListItemIcon>
                                        <SettingsIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Configuración</ListItemText>
                                </MenuItem>
                            </Link>}
                            <MenuItem divider onClick={() => window.open("https://drive.google.com/drive/folders/1zreAjmF7qnqQMYc3XHCypxieQ09tpvrp")}>
                                <ListItemIcon>
                                    <FolderIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Manuales</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={exit}>
                                <ListItemIcon>
                                    <LogoutIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Salir</ListItemText>
                            </MenuItem>
                        </Menu>
                        </> }
                    </Toolbar>
                </AppBar>
            </Box>
        );
    } catch (e: any) {
        const error: ILog = { method: 'Navbar.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }
    
};

export default Navbar;