import LogService from '../services/LogService';
import ILog from '../types/Log';
import ConfigUtils from '../utils/Config';
import * as constantes from './Constantes';

const logOut = () => {
    localStorage.removeItem(constantes.LS_profile);
    localStorage.removeItem(constantes.LS_config);
    window.dispatchEvent(new Event('popstate'));
};

const formatDate = (date: string) => {
    try {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    } catch (e: any) {
        const error: ILog = { method: 'Utilities.formatDate', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const formatTime = (date: string) => {
    try {
        var d = new Date(date),
        hours = '' + (d.getHours()),
        minutes = '' + d.getMinutes();

        if (hours.length < 2) 
            hours = '0' + hours;

        if (minutes.length < 2) 
            minutes = '0' + minutes;
    
        return `${hours}:${minutes}`;
    } catch (e: any) {
        const error: ILog = { method: 'Utilities.formatTime', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const isNullEmptyUndefined = (value: any) => {
    try {
        if (value === null) return true;
        if (value === undefined) return true;
        if (value === '') return true;
    
        return false;
    } catch (e: any) {
        const error: ILog = { method: 'Utilities.isNullEmptyUndefined', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const isMoheUser = () => {
    try {
        if (ConfigUtils.getUserId() == process.env.REACT_APP_MOHE_USER) return true;
        return false;
    } catch (e: any) {
        const error: ILog = { method: 'Utilities.isMoheUser', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return false;
    }
};

const validateSpanishID = (id: string) => {
    try {
        if (constantes.DNI_REGEX.test(id))
            return true;
        if (constantes.NIE_REGEX.test(id))
            return true;
        if (constantes.CIF_REGEX.test(id))
            return true;

        return false;
    } catch (e: any) {
        const error: ILog = { method: 'Utilities.validateSpanishID', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return false;
    }
};

const validatePassport = (id: string) => {
    try {
        if (constantes.Pasaporte_REGEX.test(id))
            return true;
        
            return false;
    } catch (e: any) {
        const error: ILog = { method: 'Utilities.validatePassport', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return false;
    }
};

const getSpanichIDType = (id: string) => {
    try {
        if (constantes.DNI_REGEX.test(id))
            return "DNI";
        if (constantes.NIE_REGEX.test(id))
            return "NIE";
        if (constantes.CIF_REGEX.test(id))
            return "CIF";
        if (constantes.Pasaporte_REGEX.test(id))
            return "Pasaporte";

        return "";
    } catch (e: any) {
        const error: ILog = { method: 'Utilities.getSpanichIDType', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return "";
    }
};

const compareDates = (date1: Date, date2: Date): boolean => {
    try {
    const [year1, month1, day1] = [ date1.getFullYear(), date1.getMonth(), date1.getDate() ];
    const [year2, month2, day2] = [ date2.getFullYear(), date2.getMonth(), date2.getDate() ];

    if (year1 === year2 && month1 === month2 && day1 === day2) return true;
    else return false;

    } catch (e: any) {
        const error: ILog = { method: 'Utilities.compareDates', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return false;
    }
};

const Utilities = {
    logOut,
    formatDate,
    formatTime,
    isNullEmptyUndefined,
    isMoheUser,
    validateSpanishID,
    getSpanichIDType,
    validatePassport,
    compareDates
};

export default Utilities;