import IPaciente from "../types/Paciente";
import moment from 'moment';
import 'moment/locale/es';
import ILog from "../types/Log";
import LogService from "../services/LogService";
import * as constantes from "../common/Constantes";

moment.locale(constantes.momentLocale);

const sortPacientesAlphabetically = (data: any) => {
    try {
        const result = data.sort((a: any,b: any) => {
            return (a.nombre < b.nombre) ? -1 : 0;
        });
        return result;
    } catch (e: any) {
        const error: ILog = { method: 'PacienteService.sortPacientesAlphabetically', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
    
};

const sortPacientesByInternalID = (pacientes: any): any => {
    try {
        const nID = pacientes.filter((p: any) => !p.internalID);
        let yID = pacientes.filter((p: any) => p.internalID);
        yID = yID.sort((a: any,b: any) => {
            return a.internalID - b.internalID;
        });
        const result = [...yID, ...nID];
        return result;
    } catch (e: any) {
        const error: ILog = { method: 'PacienteService.sortPacientesByInternalID', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const formatPacientesGrid = (pacientes: IPaciente[]) => {
    try {
        const pacientesGrid: any = [];

        pacientes.map(paciente => {
            let pacienteGrid = {
                id: paciente._id,
                internalID: paciente.internalID,
                nombre: `${paciente.nombre} ${paciente.apellidos}`,
                edad: paciente.fechaNacimiento && calcularEdad(paciente.fechaNacimiento),
                email: paciente.email,
                telefono: paciente.telefono,
                updatedAt: moment(paciente.updatedAt).fromNow(),
                actividad: paciente.actividad
            };
            pacientesGrid.push(pacienteGrid);
            return pacienteGrid;    
        });  
        return pacientesGrid;
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Pacientes.formatPacientesGrid', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
    
};

const calcularEdad = (fechaNacimiento: string) => {
    try {
        let diff_ms = Date.now() - new Date(fechaNacimiento).getTime();
        let edad_dt = new Date(diff_ms);
        return Math.abs(edad_dt.getFullYear() - 1970);
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Pacientes.calcularEdad', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
    
};

const getSexo = (index: string) => {
    return constantes.sexos[parseInt(index)];
};

const getConocido = (index: string) => {
    return constantes.formaConocer[parseInt(index)];
};

const PacientesUtils = {
    sortPacientesAlphabetically,
    sortPacientesByInternalID,
    formatPacientesGrid,
    calcularEdad,
    getSexo,
    getConocido
};

export default PacientesUtils;