import LogService from "../services/LogService";
import IClass from "../types/Class";
import IEvent from "../types/Event";
import ILog from "../types/Log";
import IMasterClass from "../types/MasterClass";
import * as constantes from "../common/Constantes";
import ConfigUtils from "./Config";
import IActividad from "../types/Actividad";

const formatClassEvent = (event: IEvent): IClass | undefined => {
    try {
        const formattedClass: IClass = {

            _id: event.id?.toString(),
            title: event.title,
            pacientesIds: event.resource.pacientesIds,
            especialistaId: event.resource.especialista,
            salaId: event.resource.sala,
            start: event.start,
            end: event.end,
            repId: event.resource.repId,
            userId: ConfigUtils.getUserId(),
            comentarios: event.resource.comentarios

        };
        return formattedClass;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Clase.formatClassEvent', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const formatClaseDate = (event: IEvent | undefined) => {
    try {
        if (event === undefined) return;

        var d = new Date(event.start),
        dayWeek = '' + d.getDay(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    
        return `${constantes.weekNames[parseInt(dayWeek) - 1]}, ${day} de ${constantes.monthNames[parseInt(month) - 1]} de ${year}`;
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Clase.formatClaseDate', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const formatClaseTime = (event: IEvent | undefined) => {
    try {
        if (event === undefined) return;

        var d = new Date(event.start),
        hoursStart = '' + (d.getHours()),
        minutesStart = '' + d.getMinutes();
    
        var d2 = new Date(new Date(event.end)),
        hoursEnd = '' + (d2.getHours()),
        minutesEnd = '' + d2.getMinutes();
    
        if (minutesStart.length < 2)
            minutesStart = '0' + minutesStart;
    
        if (minutesEnd.length < 2)
            minutesEnd = '0' + minutesEnd;
    
        return `de ${hoursStart}:${minutesStart} a ${hoursEnd}:${minutesEnd}`;
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Clase.formatClaseTime', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const formatClassTitle = (clase: IMasterClass) => {
    try {
        var d = new Date(clase.start),
        hoursStart = '' + (d.getHours()),
        minutesStart = '' + d.getMinutes();
        var d2 = new Date(new Date(clase.start).setMinutes(new Date(clase.start).getMinutes() + clase.duracion)),
        hoursEnd = '' + (d2.getHours()),
        minutesEnd = '' + d2.getMinutes();
    
    
        if (minutesStart.length < 2)
            minutesStart = '0' + minutesStart;
        if (minutesEnd.length < 2)
            minutesEnd = '0' + minutesEnd;
    
        return `${constantes.weekNames[clase.weekDay]}, de ${hoursStart}:${minutesStart} a ${hoursEnd}:${minutesEnd}`;
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Clase.formatClassTitle', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const formatClasses = (data: IClass[], actividades: IActividad[]): IEvent[] => {
    try {
        const formattedClasses: IEvent[] = [];

        data.map(clase => {
            const actividad = actividades.filter(a =>a.internalID?.toString() === clase.title.toString())[0];

            let formattedClass: IEvent = {
                id: clase._id,
                title: actividad.title,
                start: new Date(clase.start),
                end: clase.end ? new Date (clase.end) : new Date(),
                resource: { sala: clase.salaId, especialista: clase.especialistaId, pacientesIds: clase.pacientesIds, start: clase.start, end: clase.end, repId: clase.repId, comentarios: clase.comentarios, capacity: actividad.capacity },
            };
            formattedClasses.push(formattedClass);
            return formattedClass;
        });
        return formattedClasses;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Clase.formatClasses', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return [];
    }
};

const formatClassesActividades = (data: IClass[], masters: IMasterClass[], actividades: IActividad[]): IEvent[] => {
    try {
        const formattedClasses: IEvent[] = [];

        data.map(clase => {

            const master = masters.filter(m => clase.repId === m._id)[0];
            let actividad;
            if (master) actividad = actividades.filter(a =>a.internalID?.toString() === master.title.toString())[0];

            const pacientesLength = master && master.pacientes !== undefined ? master.pacientes.filter(p => new Date(p.end).getTime() > new Date(clase.end).getTime() && new Date(p.start).getTime() < new Date(clase.start).getTime()).length : 0;

            let formattedClass: IEvent = {
                id: clase._id,
                title: master ? `${actividad?.title} [${pacientesLength}/${actividad?.capacity}]` : 'CLASE ELIMINADA',
                start: new Date(clase.start),
                end: clase.end ? new Date (clase.end) : new Date(),
                resource: { sala: clase.salaId, especialista: clase.especialistaId, start: clase.start, end: clase.end, repId: clase.repId },
            };
            formattedClasses.push(formattedClass);
            return formattedClass;
        });
        return formattedClasses;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Clase.formatClassesActividades', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return [];
    }
};

const formatDate = (date: string) => {
    try {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [day, month, year].join('/');
    } catch (e: any) {
        const error: ILog = { method: 'ClaseUtils.formatDate', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const ClaseUtils = {
    formatClassTitle,
    formatClassEvent,
    formatClaseDate,
    formatClaseTime,
    formatClasses,
    formatClassesActividades,
    formatDate
};

export default ClaseUtils;