import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import PacienteService from "../../services/PacienteService";
import IPaciente from "../../types/Paciente";
import { Alert, Typography, Paper, Container, TextField, InputAdornment, IconButton, Grid, Button, Avatar, CircularProgress, FormGroup, FormControlLabel, Checkbox, Pagination, Snackbar } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { createTheme } from '@mui/material/styles';
import PacientesUtils from "../../utils/Pacientes";
import ILog from "../../types/Log";
import LogService from "../../services/LogService";
import { isMobile } from "react-device-detect";
import AgendaService from "../../services/AgendaService";
import IAlert from "../../types/Alert";
import IActividad from "../../types/Actividad";
import ConfigUtils from "../../utils/Config";
import * as constantes from "../../common/Constantes";

const Pacientes = () => {
    const theme = createTheme();
    const history = useNavigate();
    let location = useLocation();

    const totalPacientes = 4;

    const [loading, setLoading] = useState(true);
    const [pacientes, setPacientes] = useState<Array<IPaciente>>([]);
    const [initialGrid, setInitialGrid] = useState(pacientes);
    const [pacientesGrid, setPacientesGrid] = useState(pacientes);
    const [filteredGrid, setFilteredGrid] = useState(pacientes);
    const [searchString, setSearchString] = useState('');
    const [alert, setAlert] = useState<IAlert>({ open: location.state ? location.state.open : false, severity: location.state ? location.state.severity :  'info', msg: location.state ? location.state.msg :  '' });

    const [currentPage, setCurrentPage] = useState(1);
    const [showOnlyActividades, setShowOnlyActividades] = useState(false);
    const [pacientesActividades, setPacientesActividades] = useState<Array<IPaciente>>([]);
    const [pacientesActivos, setPacientesActivos] = useState<Array<string>>([]);

    const [actividades] = useState<IActividad[]>(ConfigUtils.getActividades());

    useEffect(() => {

        if (pacientes.length === 0) {
            const promisePacientes = PacienteService.getAll();
            const promisePacientesActivos = AgendaService.getPacientesActivos();

            Promise.all([promisePacientes, promisePacientesActivos])
                .then((response: any) => {
                    const res = PacientesUtils.sortPacientesAlphabetically(response[0].data);
                    setPacientes(res);
                    setPacientesGrid(PacientesUtils.formatPacientesGrid(res).splice((currentPage - 1) * totalPacientes, totalPacientes));
                    setInitialGrid(PacientesUtils.formatPacientesGrid(res));
                    setFilteredGrid(PacientesUtils.formatPacientesGrid(res));
                    setPacientesActividades(PacientesUtils.formatPacientesGrid(res.filter((paciente: any) => paciente.actividad)));
                    setPacientesActivos(response[1].data);
                })
                .catch((e: any) => {
                    if (e.response.status === 401) {
                        localStorage.clear();
                        history(constantes.R_HOME);
                    } else {
                        const error: ILog = { method: 'Pacientes.Component.useEffect', level: 'error', message: e.message, meta: e.response };
                        LogService.logError(error);
                        setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setPacientesGrid(filteredGrid.slice((currentPage - 1) * totalPacientes, ((currentPage - 1) * totalPacientes) + totalPacientes));
        }
    }, [setPacientes, setPacientesGrid, setInitialGrid, setFilteredGrid, setPacientesActividades, setPacientesActivos, setAlert, setLoading, history, currentPage, filteredGrid, pacientes.length, totalPacientes]);

    const search = (e: any) => {
        try {
            setSearchString(e.target.value);
            const value = e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            let results: IPaciente[] = [];
            if (showOnlyActividades) {
                results = pacientesActividades
            } else {
                results = initialGrid;
            }
            results = results.filter((paciente) => paciente.nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").search(value) !== -1);
            setCurrentPage(1);
            setFilteredGrid(results);
            setPacientesGrid(results.slice((currentPage - 1) * totalPacientes, ((currentPage - 1) * totalPacientes) + totalPacientes));
        } catch (e: any) {
            const error: ILog = { method: 'Pacientes.Component.search', level: 'error', message: e.message, meta: e.response };
            LogService.logError(error);
        }

    };

    const filterPacientesActividades = () => {
        setCurrentPage(1);
        if (!showOnlyActividades) {
            let results = pacientesActividades;
            if (searchString !== '') {
                results = results.filter((paciente) => paciente.nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").search(searchString.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) !== -1);
            }
            setPacientesGrid(results.slice(0, totalPacientes));
            setFilteredGrid(results);
        } else {
            let results = initialGrid;
            if (searchString !== '') {
                results = initialGrid.filter((paciente) => paciente.nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").search(searchString.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) !== -1);
            }
            setPacientesGrid(results.slice(0, totalPacientes));
            setFilteredGrid(results);
        }
        setShowOnlyActividades(!showOnlyActividades);
    };

    const clearFilter = () => {

        if (showOnlyActividades) {
            setPacientesGrid(pacientesActividades.slice(0, totalPacientes));
            setFilteredGrid(pacientesActividades);

        } else {
            setPacientesGrid(initialGrid.slice(0, totalPacientes));
            setFilteredGrid(initialGrid);
        }

        setSearchString('');
        setCurrentPage(1);
    };

    const sortById = () => {
        try {
            const results = PacientesUtils.sortPacientesByInternalID(filteredGrid);
            setPacientesGrid(results.slice(0, totalPacientes));
            setFilteredGrid(results);
        } catch (e: any) {
            const error: ILog = { method: 'Pacientes.Component.sortById', level: 'error', message: e.message, meta: e.response };
            LogService.logError(error);
        }
    };

    const getFromShowingItems = () => {
        let currentItem = 0;

        //Calculamos el inicio de página de los elementos
        if (currentPage > 0) currentItem = (currentPage - 1) * totalPacientes + 1;

        return currentItem;
    };

    const getLastShowingItems = () => {
        let maxItems = currentPage * totalPacientes;

        //Calculamos el final de página de los elementos
        //Si excede el total, lo igualamos
        if (maxItems < totalPacientes) maxItems = filteredGrid.length;
        if (currentPage === getTotalPages(initialGrid.length)) maxItems = initialGrid.length;

        return maxItems;
    };

    const getTotalPages = (length: number) => {
        if (length === 0) return 1;
        return Math.ceil(length / totalPacientes);
    };

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    try {
        return (
            <>
                <Grid>
                <Grid container>
                    <Grid item md={12}>
                        <Container>
                            <Paper elevation={3} sx={{ marginTop: theme.spacing(3), padding: theme.spacing(3), marginBottom: theme.spacing(3) }}>
                                <Grid container spacing={3}>
                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                        <Typography variant="h4" sx={{ marginTop: theme.spacing(1), fontWeight: 'bold' }}>PACIENTES</Typography>
                                    </Grid>
                                    <Grid item lg={5} md={5} sm={7} xs={12}>
                                        <TextField variant="outlined" placeholder="Buscar paciente..." fullWidth autoFocus onChange={(e) => search(e)} value={searchString} InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>), endAdornment: (<InputAdornment position="end"><IconButton onClick={clearFilter}><ClearIcon /></IconButton></InputAdornment>) }} />
                                        {actividades.length !== 0 && <FormGroup aria-label="position" row>
                                            <FormControlLabel control={<Checkbox checked={showOnlyActividades} onChange={filterPacientesActividades} inputProps={{ 'aria-label': 'controlled' }} />} label="Mostrar solo pacientes de clases" />
                                        </FormGroup>}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={5} xs={12}>
                                        <Link to={constantes.R_NUEVOPACIENTE} style={{ textDecoration: 'none' }}>
                                            <Button sx={{ padding: theme.spacing(1), backgroundColor: '#63ad3c', "&:hover": { backgroundColor: '#7dc557' } }} variant="contained" fullWidth>
                                                <PersonAddIcon sx={{ color: 'white' }} />
                                                <Typography variant="body1" sx={{ margin: theme.spacing(1), fontSize: '15px', fontWeight: 'bold', color: 'white' }}>Nuevo paciente</Typography>
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>

                                {loading ?
                                    <Grid container>
                                        <CircularProgress style={{ margin: '20px auto' }} />
                                    </Grid>
                                    :

                                    pacientesGrid.length === 0 ? <div>No se han encontrado pacientes</div> : 
                                    
                                    <>
                                    <div>{` Mostrando ${getFromShowingItems()}-${getLastShowingItems()} de ${filteredGrid.length} pacientes`}</div>
                                    { pacientesGrid.map((paciente: any) => {
                                        let color = '#d32f2f';
                                        if (paciente.actividad) color = '#ed6c02';
                                        if (pacientesActivos.includes(paciente.id)) color = '#2e7d32';
                                        return (
                                            <Paper elevation={2} sx={{ marginTop: theme.spacing(2), padding: theme.spacing(3), borderLeft: `5px solid ${color}`}} key={paciente.id}>
                                                <Grid container>
                                                    <Grid item md={1} sm={1} xs={2}>
                                                        <Avatar><IconButton onClick={() => history(`${constantes.R_PACIENTE}?${constantes.QP_ID}=${paciente.id}`)}><PersonIcon titleAccess="Ver paciente" /></IconButton></Avatar>
                                                    </Grid>
                                                    <Grid item md={4} sm={7} xs={10} sx={{ paddingLeft: theme.spacing(1) }}>
                                                        <Typography variant="h6" >{paciente.nombre}</Typography>
                                                        <Typography variant="body2" >{paciente.edad && `${paciente.edad} años`}</Typography>
                                                        <div style={{ cursor: 'pointer', fontSize: '0.8rem', color: 'rgba(0, 0, 0, 0.6)' }} onClick={sortById}>{`ID: ${paciente.internalID ? paciente.internalID : 0}`}</div>
                                                    </Grid>
                                                    <Grid item md={3} sm={4} xs={6} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                                        <a href={`tel:0034${paciente.telefono}`}>
                                                        <div style={{ display: 'flex', marginBottom: '5px' }}>
                                                            <PhoneIcon sx={{ color: '#696969', marginRight: theme.spacing(1) }} titleAccess="Teléfono" fontSize="small" />
                                                            <Typography variant="body2" >{paciente.telefono}</Typography>
                                                        </div>
                                                        </a>
                                                        {paciente.email &&
                                                        <a href={`mailto:${paciente.email}`}>
                                                            <div style={{ display: 'flex' }}>
                                                                <EmailIcon sx={{ color: '#696969', marginRight: theme.spacing(1) }} titleAccess="Email" fontSize="small" />
                                                                <Typography variant="body2" >{isMobile ? paciente.email.length > 12 ? `${paciente.email.substring(0, 12)}...` : paciente.email : paciente.email}</Typography>
                                                            </div>
                                                        </a>
                                                        }
                                                    </Grid>
                                                    <Grid item md={3} sm={4} xs={6}>
                                                        <Typography variant="body2" style={{ marginTop: '1.2em', textAlign: 'right' }} >{`Modificado ${paciente.updatedAt}`}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        );
                                    })}
                                    </>
                                }

                                <Grid container>
                                    <Grid item xs={12} style={{ marginTop: theme.spacing(2), justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                        <Pagination size="small" page={currentPage} count={filteredGrid.length === 0 ? 1 : Math.ceil(filteredGrid.length / totalPacientes)} onChange={(event, value) => { setCurrentPage(value) }} />
                                    </Grid>
                                </Grid>

                            </Paper>
                        </Container>
                    </Grid>

                </Grid>
                </Grid>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </>
        );
    } catch (e: any) {
        const error: ILog = { method: 'Pacientes.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }

};

export default Pacientes;