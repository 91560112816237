import { createTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import 'react-big-calendar/lib/css/react-big-calendar.css'
import React, { useEffect, useState } from "react";
import moment from "moment";
import ILog from '../../types/Log';
import IEvent from "../../types/Event";
import LogService from '../../services/LogService';
import { Alert, Avatar, Button, Checkbox, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Snackbar, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import * as constantes from "../../common/Constantes";
import AgendaService from '../../services/AgendaService';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterOn from '@mui/icons-material/FilterList';
import FilterOff from '@mui/icons-material/FilterListOff';
import IPaciente from '../../types/Paciente';
import IMasterClass from '../../types/MasterClass';
import PacienteService from '../../services/PacienteService';
import PacientesUtils from '../../utils/Pacientes';
import EditActivities from './EditActivities';
import ClaseUtils from '../../utils/Clase';
import { isMobile } from "react-device-detect";
import ConfigUtils from '../../utils/Config';
import IAlert from '../../types/Alert';
import ISala from '../../types/Sala';
import IActividad from '../../types/Actividad';
import IHoliday from '../../types/Holiday';
import Holiday from 'date-holidays';
import PacienteActivities from './PacienteActivities';

import WavesIcon from '@mui/icons-material/Waves';
import LandscapeIcon from '@mui/icons-material/Landscape';
import DiamondIcon from '@mui/icons-material/Diamond';
import GrassIcon from '@mui/icons-material/Grass';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ListaEspera from './ListaEspera';

const locale = constantes.locale;

const AgendaAct = () => {
    const theme = createTheme();
    const history = useNavigate();
    const localizer = momentLocalizer(moment);

    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const [events, setEvents] = useState<Array<IEvent>>([]);
    const [shownEvents, setShownEvents] = useState<Array<IEvent>>([]);

    const [salas] = useState<Array<ISala>>(ConfigUtils.getSalas());
    const [actividades] = useState<Array<IActividad>>(ConfigUtils.getActividades());
    const [holidays, setHolidays] = useState<Array<IHoliday>>(ConfigUtils.getHolidays());

    const [sanitarios] = useState(ConfigUtils.getSanitarios());
    const [checkedSanitarios, setCheckedSanitarios] = useState(new Array(ConfigUtils.getSanitarios().length).fill(true));
    const [checkedActividades, setCheckedActividades] = useState(new Array(ConfigUtils.getActividades().length).fill(true));
    const [showFilters, setShowFilters] = useState(false);

    const [pacientes, setPacientes] = useState<Array<IPaciente>>([]);

    const [modalClass, setModalClass] = useState(false);
    const [loadingClass, setLoadingClass] = useState(false);
    const [actividadId, setActividadId] = useState<number>(0);
    const [especialistaId, setEspecialistaId] = useState<number>(0);
    const [startClass, setStartClass] = useState<Date>(new Date());
    const [duracion, setDuracion] = useState<number>(actividades[0].duracion);
    const [endClass, setEndClass] = useState<Date>(new Date());
    const [startClassTime, setStartClassTime] = useState<Date>(new Date(new Date().setMinutes(0)));
    const [weekDays, setWeekDays] = useState<Array<number>>([]);
    const [errorDiasSemana, setErrorDiasSemana] = useState<string>('');
    const [errorSala, setErrorSala] = useState(false);

    const salaDefault = ConfigUtils.getSalaByActividad(salas, actividades[0]);
    const [salaId, setSalaId] = useState<number>(salaDefault ? parseInt(salaDefault) : 0);

    const [masterClasses, setMasterClasses] = useState<Array<IMasterClass>>([]);
    const [masterClass, setMasterClass] = useState<IMasterClass>();
    const [selectedEvent, setSelectedEvent] = useState<IEvent>();

    const [modalDelete, setModalDelete] = useState(false);
    const [deleteChecked, setDeleteChecked] = useState<Array<string>>([]);
    const [modalConfirm, setModalConfirm] = useState(false);

    const [modalClassEvent, setModalClassEvent] = useState(false);

    const [modalEdit, setModalEdit] = useState(false);
    const [modalEditPacientes, setModalEditPacientes] = useState(false);

    const [modalListaEspera, setModalListaEspera] = useState(false);

    const userRole: number = ConfigUtils.getUserRole();

    const minTime = new Date();
    minTime.setHours(8, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(22, 0, 0);

    useEffect(() => {

        if (holidays.length === 0) {
            const h = new Holiday(constantes.holidaysCountry, ConfigUtils.getProvincia()).getHolidays().filter(x => x.type === 'public');
            setHolidays(ConfigUtils.formatHolidays(h));
        }

        const promisePacientes = PacienteService.getAll();
        const promiseClasses = AgendaService.getAllClasses();
        const promiseMasterClasses = AgendaService.getMasterClasses();
        Promise.all([promisePacientes, promiseClasses, promiseMasterClasses])

            .then((response) => {
                const classes = ClaseUtils.formatClassesActividades(response[1].data, response[2].data, actividades);
                setEvents(classes);
                setShownEvents(classes);
                const res = PacientesUtils.sortPacientesAlphabetically(response[0].data);
                setPacientes(res);
                const orderedMasterClasses = response[2].data
                                                .sort((a: IMasterClass, b: IMasterClass) => new Date(a.start).getHours() < new Date(b.start).getHours() ? -1 : 1)
                                                .sort((a: IMasterClass, b: IMasterClass) => a.weekDay - b.weekDay)
                                                .sort((a: IMasterClass, b: IMasterClass) => parseInt(a.title) - parseInt(b.title));    
                setMasterClasses(orderedMasterClasses);
            })
            .catch((e) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'AgendaAct.Component.useEffect', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [setAlert, history, actividades, holidays]);

    const closeModalClass = () => {
        setActividadId(0);
        setEspecialistaId(0);
        setStartClass(new Date());
        setStartClassTime(new Date(new Date().setMinutes(0)));
        setDuracion(actividades[0].duracion);
        setEndClass(new Date());
        setWeekDays([]);
        setErrorDiasSemana('');
        setErrorSala(false);
        setModalClass(false);
        const result = ConfigUtils.getSalaByActividad(salas, actividades[0]);
        if (result === undefined) return;
        setSalaId(parseInt(result));
    };

    const closeModalDelete = () => {
        setModalDelete(false);
        setDeleteChecked([]);
        setModalConfirm(false);
    };

    const saveClass = () => {
        setLoadingClass(true);
        if (weekDays.length === 0) {
            setErrorDiasSemana('Selecciona al menos un día de la semana *');
            setLoadingClass(false);
            return;
        }
        if (isNaN(salaId)) {
            setErrorSala(true);
            setLoadingClass(false);
            return;
        }
        setErrorDiasSemana('');
        setErrorSala(false);

        const start = new Date(startClass);
        start.setHours(new Date(startClassTime).getHours());
        start.setMinutes(new Date(startClassTime).getMinutes());

        const newMasterClasses: IMasterClass[] = [];
        weekDays.map((weekDay) => {
            const newMasterClass: IMasterClass = {
                title: actividadId.toString(),
                especialistaId,
                salaId,
                start,
                duracion,
                terminarRepetir: endClass,
                weekDay: weekDay,
                userId: ConfigUtils.getUserId(),
                pacientes: []
            };
            newMasterClasses.push(newMasterClass);
            return newMasterClass;
        });

        AgendaService.createClasses(newMasterClasses)
            .then((response: any) => {
                setModalClass(false);
                const createdClasses = ClaseUtils.formatClassesActividades(response.data[0], response.data[1], actividades);
                setEvents([...events, ...createdClasses]);
                setShownEvents([...events, ...createdClasses]);
                const orderedMasterClasses = [...masterClasses, ...response.data[1]].sort((a: IMasterClass, b: IMasterClass) => new Date(a.start).getDate() < new Date(b.start).getDate() ? 1 : -1).sort((a: IMasterClass, b: IMasterClass) => a.weekDay - b.weekDay)
                setMasterClasses(orderedMasterClasses);
                setCheckedActividades(new Array(actividades.length).fill(true));
                setCheckedSanitarios(new Array(sanitarios.length).fill(true));
                setAlert({ open: true, severity: 'success', msg: 'Clases creadas correctamente' });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'AgendaAct.Component.saveClass', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoadingClass(false);
                closeModalClass();
            });
    };

    const filter = (filterType: string, filterName: string, value: boolean) => {
        let filteredEvents: IEvent[] = [];

        switch (filterType) {
            case 'sanitarios':
                sanitarios.filter(e => e.activo == true).map((sanitario) => {
                    if (sanitario.firstName === filterName) {
                        if (value) {
                            //Poner
                            checkedActividades.map((a: any, index: number) => {
                                if (a) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && x.resource.especialista === sanitario.internalID && x.title.indexOf(actividades[index].title) !== -1)];
                                checkedSanitarios.map((s: any, i: number) => {
                                    if (s && a) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && x.resource.especialista === sanitarios[i].internalID && x.title.indexOf(actividades[index].title) !== -1)];
                                    return filteredEvents;
                                });
                                return filteredEvents;
                            });
                        } else {
                            //Quitar
                            filteredEvents = shownEvents.filter(x => x.resource && x.resource.especialista !== sanitario.internalID);
                        }
                    }
                    return filteredEvents;
                });
            break;
            case 'actividades':
                actividades.map((actividad) => {
                    if (actividad.title === filterName) {
                        if (value) {
                            //Poner
                            checkedSanitarios.map((s: any, index: number) => {
                                if (s) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && x.resource.especialista === sanitarios[index].internalID && x.title.indexOf(actividad.title) !== -1)];
                                checkedActividades.map((a: any, i: number) => {
                                    if (a && s) filteredEvents = [...filteredEvents, ...events.filter(x => x.resource && x.resource.especialista === sanitarios[index].internalID && x.title.indexOf(actividades[i].title) !== -1)];
                                    return filteredEvents;
                                });
                                return filteredEvents;
                            });
                        } else {
                            //Quitar
                            filteredEvents = shownEvents.filter(x => x.title.indexOf(actividad.title) === -1);
                        }
                    }
                    return filteredEvents;
                });
            break;
        }

        setShownEvents(filteredEvents);
    };

    const selectClase = (event: IEvent) => {
        const selectedMasterClass: IMasterClass = masterClasses.filter(m => m._id === event.resource.repId)[0];
        if (selectedMasterClass === undefined) return;

        setMasterClass(selectedMasterClass);
        setSelectedEvent(event);
        setModalClassEvent(true);
    };

    const handleToggle = (item: IMasterClass) => {
        if (item._id === undefined || item._id === null) return;

        const currentValue: boolean = deleteChecked.includes(item._id);
        const newChecked = [...deleteChecked];

        if (!currentValue) {
            newChecked.push(item._id);
        } else {
            const index = deleteChecked.indexOf(item._id);
            newChecked.splice(index, 1);
        }

        setDeleteChecked(newChecked);
    };

    const deleteClasses = () => {
        setModalConfirm(false);
        if (deleteChecked.length === 0) {
            setAlert({ open: true, severity: 'warning', msg: 'No se han seleccionado clases a eliminar' });
            return;
        }
        setLoadingClass(true);

        AgendaService.deleteClasses(deleteChecked)
            .then((response: any) => {
                let updatedEvents = events;
                for (let e of deleteChecked) {
                    updatedEvents = updatedEvents.filter(x => x.resource.repId !== e);
                }
                setEvents(updatedEvents);
                setShownEvents(updatedEvents);
                let updatedMasterClasses = masterClasses;
                for (let e of deleteChecked) {
                    updatedMasterClasses = updatedMasterClasses.filter(x => x._id !== e);
                }
                setMasterClasses(updatedMasterClasses);
                setCheckedActividades(new Array(actividades.length).fill(true));
                setCheckedSanitarios(new Array(sanitarios.length).fill(true));
                setAlert({ open: true, severity: 'success', msg: 'Clases eliminadas correctamente' });
                history(0);
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'AgendaAct.Component.deleteClasses', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoadingClass(false);
                closeModalDelete();
            })
    };

    const handleChangeFilterSanitarios = (empleado: any, checked: boolean, index: number) => {
        const z = [...checkedSanitarios];
        z[index] = checked;
        setCheckedSanitarios(z);
        filter('sanitarios', empleado.firstName, checked);
    };

    const handleChangeFilterActividades = (actividad: IActividad, checked: boolean, index: number) => {
        const z = [...checkedActividades];
        z[index] = checked;
        setCheckedActividades(z);
        filter('actividades', actividad.title, checked);
    };

    const handleFilters = () => {
        setCheckedSanitarios(new Array(sanitarios.length).fill(true));
        setCheckedActividades(new Array(actividades.length).fill(true));
        setShownEvents([...events]);
        setShowFilters(!showFilters);
    };

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const handleChangeActividad = (event: any) => {
        const actividad = actividades.filter(a => a.internalID == event.target.value)[0];
        setActividadId(parseInt(event.target.value));
        setDuracion(actividad.duracion);
        const result = ConfigUtils.getSalaByActividad(salas, actividad);
        if (result === undefined) return;
        setSalaId(parseInt(result));
    };

    const Event: React.FC<{ event: IEvent }> = ({ event }) => {
        const salaId = event?.resource?.sala;
        if (salaId == null) return <div><div>{event.title}</div></div>;
        const sala = salas.find(s => s.internalID == salaId);
        if (sala == null) return <div><div>{event.title}</div></div>;
        let icon;
        switch (sala?.icon) {
            case "0":
                icon = <WavesIcon sx={{ fontSize: '15px' }} />;
                break;
            case "1":
                icon = <DiamondIcon sx={{ fontSize: '15px' }} />;
                break;
            case "2":
                icon = <LandscapeIcon sx={{ fontSize: '15px' }} />;
                break;
            case "3":
                icon = <GrassIcon sx={{ fontSize: '15px' }} />;
                break;
            case "4":
                icon = <AcUnitIcon sx={{ fontSize: '15px' }} />;
                break;
            default:
                break;
        };
        
        return (
            <div>
            {/* Primera línea: hora e ícono */}
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                <span style={{ fontSize: '80%', marginRight: '5px' }}>
                    {moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}
                </span>
                {icon && <span>{icon}</span>}
            </div>
            {/* Segunda línea: título del evento */}
            <div>{event.title}</div>
        </div>
         );
    };    

    try {
        return (
            <>
                <Grid>
                    <Grid container>
                        <Container>
                            <Paper elevation={3} sx={{ marginTop: theme.spacing(2), padding: '20px', marginBottom: theme.spacing(2) }}>
                                <Grid item md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant="h4" sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1), fontWeight: 'bold' }}>CLASES</Typography>
                                </Grid>
                                <Grid item>
                                    {loading ? (
                                        <Grid container>
                                            <CircularProgress style={{ margin: '20px auto' }} />
                                        </Grid>
                                    ) : (
                                        <>
                                            <Grid container spacing={isMobile ? 1 : 3}>
                                                <Grid item md={2}>
                                                    <Button fullWidth variant="contained" color="primary" onClick={() => setModalClass(true)}>NUEVA CLASE</Button>
                                                </Grid>
                                                {masterClasses.length !== 0 && 
                                                    <>
                                                        <Grid item md={3}>
                                                            <Button fullWidth variant="contained" color="primary" onClick={() => { setModalEditPacientes(true) }}>MODIFICAR PACIENTES</Button>
                                                        </Grid>
                                                            {userRole == constantes.ROLES.ADMINISTRADOR && masterClasses.length !== 0 ? (
                                                                <>
                                                                    <Grid item md={2}>
                                                                        <Button fullWidth variant="contained" color="primary" onClick={() => { setModalEdit(true) }}>EDITAR CLASES</Button>
                                                                    </Grid>
                                                                    <Grid item md={2}>
                                                                        <Button fullWidth variant="contained" color="error" onClick={() => setModalDelete(true)}>ELIMINAR CLASES</Button>
                                                                    </Grid>
                                                                </>
                                                            ) : (
                                                                <Grid item md={2}>
                                                                    <Button fullWidth variant="contained" color="primary" onClick={() => { setModalEdit(true) }}>EDITAR CLASES</Button>
                                                                </Grid>
                                                            )}
                                                        <Grid item md={3}>
                                                            <Button fullWidth variant="contained" color="secondary" onClick={() => { setModalListaEspera(true) }}>LISTA DE ESPERA</Button>
                                                        </Grid>
                                                    </>}
                                            </Grid>
                                            {showFilters ? (
                                                <>
                                                    <Button sx={{ marginTop: theme.spacing(1) }} color="info" size="small" variant="contained" onClick={handleFilters} startIcon={<FilterOff />}>Quitar filtros</Button>
                                                    <FormGroup aria-label="position" row>
                                                        {actividades.map((actividad: IActividad, index: number) => (
                                                            <FormControlLabel key={actividad.internalID} control={<Checkbox size={isMobile ? "small" : "medium"} checked={checkedActividades[index]} inputProps={{ 'aria-label': 'controlled' }} />} label={actividad.title} onChange={(e, checked) => handleChangeFilterActividades(actividad, checked, index)} />
                                                        ))}
                                                        {sanitarios.filter(e => e.activo == true).map((empleado: any, index: number) => (
                                                            <FormControlLabel key={empleado.internalID} control={<Checkbox size={isMobile ? "small" : "medium"} style={{ color: empleado.color }} checked={checkedSanitarios[index]} inputProps={{ 'aria-label': 'controlled' }} />} label={empleado.firstName} onChange={(e, checked) => handleChangeFilterSanitarios(empleado, checked, index)} />
                                                        ))}
                                                    </FormGroup>
                                                </>
                                            ) : (
                                                <Button sx={{ marginBottom: theme.spacing(1), marginTop: theme.spacing(1) }} color="info" size="small" variant="contained" onClick={() => setShowFilters(!showFilters)} startIcon={<FilterOn />}>Mostrar filtros</Button>
                                            )}
                                            <style>
                                                {`
                                                    /* Oculta la hora del evento */
                                                    .rbc-event-label {
                                                        display: none;
                                                    }
                                                `}
                                            </style>
                                            <Calendar
                                                popup
                                                localizer={localizer}
                                                defaultView="work_week"
                                                views={['work_week']}
                                                events={shownEvents}
                                                min={minTime}
                                                max={maxTime}
                                                style={{ height: '95vh', fontSize: '12px', wordBreak: 'break-word' }}
                                                onSelectEvent={selectClase}
                                                components={{ event: Event }}
                                                messages={{
                                                    next: "Siguiente",
                                                    previous: "Anterior",
                                                    today: "Esta semana"
                                                }}
                                                eventPropGetter={event => ({
                                                    style: {
                                                        backgroundColor: event.resource != null ? event.resource.especialista != null ? sanitarios.filter((s: any) => s.internalID === event.resource.especialista)[0]?.color : constantes.colorFestivos : '',
                                                        border: 'none',
                                                        textDecoration: event.title === 'CLASE ELIMINADA' ? 'line-through' : 'none'
                                                    }
                                                })}
                                                dayPropGetter={day => ({
                                                    style: {
                                                        backgroundColor: ConfigUtils.formatEvents(holidays).filter(festivo => festivo.start.getTime() === day.getTime()).length > 0 ? constantes.colorBackgroundFestivos : 'transparent'
                                                    }
                                                })}
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Paper>
                        </Container>
                    </Grid>

                    {/* SELECCIONAR CLASE */}
                    <Dialog open={modalClassEvent} maxWidth="lg" onClose={() => {setModalClassEvent(false); setMasterClass(undefined); setSelectedEvent(undefined);} }>
                        <DialogTitle>{`${masterClass && actividades.filter(a => a.internalID?.toString() === masterClass.title)[0].title} - ${masterClass && sanitarios.filter((s: any) => s.internalID === selectedEvent?.resource.especialista)[0]?.firstName != undefined ? sanitarios.filter((s: any) => s.internalID === selectedEvent?.resource.especialista)[0]?.firstName : ''}`}</DialogTitle>
                        <DialogContent>
                            <div>{ClaseUtils.formatClaseDate(selectedEvent)}</div>
                            <div>{ClaseUtils.formatClaseTime(selectedEvent)}</div>
                            <div>
                                <List dense={true}>
                                    <ul>
                                        {
                                            selectedEvent && 
                                                masterClass?.pacientes && masterClass?.pacientes.filter(p => new Date(p.end).getTime() > new Date(selectedEvent.start).getTime() && new Date(p.start).getTime() < new Date(selectedEvent.start).getTime()).map((p, i) => {
                                                    const paciente = pacientes.filter(x => x._id === p.id)[0];
                                                    return (
                                                        <div key={i}>
                                                            <li>{`${paciente.nombre} ${paciente.apellidos}`}</li>
                                                        </div>
                                                    )
                                                })
                                        }
                                    </ul>
                                </List>
                            </div>
                        </DialogContent>
                    </Dialog>
                    {/* AÑADIR CLASE */}
                    <Dialog open={modalClass} maxWidth="sm" fullWidth onClose={closeModalClass}>

                        <DialogContent>

                            <Grid container spacing={1}>
                            <Grid container item md={12} spacing={1}>
                                <Grid item md={7} xs={12}>
                                    <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                                        <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><AddIcon /></Avatar><div style={{ margin: '10px auto' }}>Nueva clase de </div>
                                    </Typography>
                                </Grid>
                                <Grid item md={5} xs={12}>
                                    <TextField select value={actividadId} variant="outlined" label="Actividad" margin="dense" fullWidth required onChange={handleChangeActividad} >
                                    {
                                        actividades.map((actividad: any) => {
                                            return (
                                                <MenuItem key={actividad.internalID} value={actividad.internalID}>{actividad.title}</MenuItem>
                                            );
                                        })
                                    }
                                    </TextField>
                                </Grid>
                            </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid container item md={12} spacing={1}>
                                    <Grid item md={6} xs={6}>
                                        <TextField select value={especialistaId} disabled={masterClass !== undefined} variant="outlined" label="Especialista" margin="dense" fullWidth required onChange={(event) => setEspecialistaId(parseInt(event.target.value))} >
                                            {
                                                sanitarios.filter(e => e.activo == true).map((empleado: any) => {
                                                    return (
                                                        <MenuItem key={empleado.internalID} value={empleado.internalID}>{empleado.firstName}</MenuItem>
                                                    );
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item md={6} xs={6}>
                                        <TextField select value={salaId} disabled={masterClass !== undefined} variant="outlined" label="Sala" margin="dense" fullWidth required onChange={(event) => setSalaId(parseInt(event.target.value))} error={errorSala} helperText={errorSala ? "Selecciona la sala" : ""}>
                                            {
                                                salas.map((sala: any) => {
                                                    return (
                                                        <MenuItem key={sala.internalID} value={sala.internalID}>{sala.title}</MenuItem>
                                                    );
                                                })
                                            }
                                        </TextField>
                                    </Grid>

                                    <Grid item md={6} sx={{ pt: 1, pl: 2 }}>
                                        <FormControl error={errorDiasSemana !== ''} disabled={masterClass !== undefined}>
                                            <FormHelperText>{"Selecciona los días de la semana *"}</FormHelperText>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={weekDays.includes(0) ? true : false} onChange={() => { weekDays.includes(0) ? setWeekDays(weekDays.filter(x => x !== 0)) : setWeekDays([...weekDays, 0]) }} inputProps={{ 'aria-label': 'controlled' }} name="Lunes" />
                                                    }
                                                    label="Lunes"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={weekDays.includes(1) ? true : false} onChange={() => { weekDays.includes(1) ? setWeekDays(weekDays.filter(x => x !== 1)) : setWeekDays([...weekDays, 1]) }} inputProps={{ 'aria-label': 'controlled' }} name="Martes" />
                                                    }
                                                    label="Martes"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={weekDays.includes(2) ? true : false} onChange={() => { weekDays.includes(2) ? setWeekDays(weekDays.filter(x => x !== 2)) : setWeekDays([...weekDays, 2]) }} inputProps={{ 'aria-label': 'controlled' }} name="Miércoles" />
                                                    }
                                                    label="Miércoles"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={weekDays.includes(3) ? true : false} onChange={() => { weekDays.includes(3) ? setWeekDays(weekDays.filter(x => x !== 3)) : setWeekDays([...weekDays, 3]) }} inputProps={{ 'aria-label': 'controlled' }} name="Jueves" />
                                                    }
                                                    label="Jueves"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={weekDays.includes(4) ? true : false} onChange={() => { weekDays.includes(4) ? setWeekDays(weekDays.filter(x => x !== 4)) : setWeekDays([...weekDays, 4]) }} inputProps={{ 'aria-label': 'controlled' }} name="Viernes" />
                                                    }
                                                    label="Viernes"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid container item md={6}>
                                        <Grid item md={12} sx={{ marginTop: 1 }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                                <DatePicker disabled={masterClass !== undefined}
                                                    renderInput={(props: any) => <TextField required fullWidth {...props} />}
                                                    label="Fecha de inicio"
                                                    toolbarFormat="dd-mm-yyyy"
                                                    value={startClass}
                                                    onChange={(date: any) => { date && setStartClass(date); }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item md={12} sx={{ marginTop: 1 }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                                <DatePicker disabled={masterClass !== undefined}
                                                    renderInput={(props: any) => <TextField required fullWidth {...props} />}
                                                    label="Fecha final"
                                                    toolbarFormat="dd-mm-yyyy"
                                                    value={endClass}
                                                    onChange={(date: any) => { date && setEndClass(date); }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item md={12} sx={{ marginTop: 1 }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                                <TimePicker disabled={masterClass !== undefined}
                                                    renderInput={(props: any) => <TextField required fullWidth {...props} />}
                                                    label="Hora de inicio"
                                                    value={startClassTime}
                                                    ampm={false}
                                                    minutesStep={5}
                                                    onChange={(date: any) => { date && setStartClassTime(date); }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item md={12} xs={6}>
                                            <TextField select value={duracion} disabled={masterClass !== undefined} variant="outlined" label="Duración" margin="dense" fullWidth required onChange={(event) => setDuracion(parseInt(event.target.value))} >
                                                {
                                                    constantes.duracion.map((item, index) => {
                                                        return (
                                                            <MenuItem key={index} value={item}>{`${item.toString()} minutos`}</MenuItem>
                                                        );
                                                    })
                                                }
                                            </TextField>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </DialogContent>
                        {loadingClass ?
                            <Grid container>
                                <CircularProgress style={{ margin: '20px auto' }} />
                            </Grid> :
                            <DialogActions>
                                <Button onClick={saveClass}>Guardar</Button>
                                <Button onClick={closeModalClass}>Cancelar</Button>
                            </DialogActions>
                        }

                    </Dialog>
                    {/* QUÉ CLASE ELIMINAR */}
                    <Dialog open={modalDelete} maxWidth="lg" fullWidth onClose={closeModalDelete}>
                        <DialogContent>
                            <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                                <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><DeleteIcon /></Avatar><div style={{ margin: '10px auto' }}>Selecciona las clases a eliminar</div>
                            </Typography>
                            <List>
                                {
                                    masterClasses.map((item) => {

                                        return (
                                            <ListItem key={item._id}>
                                                <ListItemButton onClick={() => handleToggle(item)} dense>
                                                    <ListItemIcon>
                                                        <Checkbox edge="start" tabIndex={-1} disableRipple checked={item._id !== undefined && item._id !== null && deleteChecked.includes(item._id)} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={`${actividades.filter(a => a.internalID?.toString() === item.title)[0].title} - ${ClaseUtils.formatClassTitle(item)}`} />
                                                </ListItemButton>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </DialogContent>
                        {loadingClass ?
                            <Grid container>
                                <CircularProgress style={{ margin: '20px auto' }} />
                            </Grid> :
                            <DialogActions>
                                <Button onClick={() => setModalConfirm(true)} color="error">Eliminar</Button>
                                <Button onClick={closeModalDelete}>Cancelar</Button>
                            </DialogActions>
                        }
                    </Dialog>
                    {/* CONFIRMACION */}
                    <Dialog open={modalConfirm} maxWidth="lg" onClose={closeModalDelete}>
                        <DialogTitle>ELIMINAR CLASES</DialogTitle>
                        <>
                            <DialogContent>
                                <DialogContentText>¿Está seguro de que desea eliminar las clases?</DialogContentText>
                                {
                                    deleteChecked.map((item) => {
                                        const clase = masterClasses.find(x => x._id === item);
                                        return (clase === undefined ? null : <p key={item}>{`${actividades.filter(a => a.internalID?.toString() === clase.title)[0].title} - ${ClaseUtils.formatClassTitle(clase)}`}</p>);
                                    })
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={deleteClasses} color="primary">SI</Button>
                                <Button onClick={closeModalDelete} color="primary" autoFocus>NO</Button>
                            </DialogActions>
                        </>
                    </Dialog>
                    {/* EDICIÓN DE CLASES */}
                    {modalEdit && <EditActivities masterClasses={masterClasses} onClose={() => setModalEdit(false)} callBack={() => { setAlert({ open: true, severity: 'success', msg: 'Clases actualizadas correctamente' }) }} />}
                    {/* EDICIÓN DE PACIENTES EN LAS CLASES */}
                    {modalEditPacientes && <PacienteActivities masterClasses={masterClasses} pacientes={pacientes} onClose={() => setModalEditPacientes(false)} callBack={() => { setAlert({ open: true, severity: 'success', msg: 'Clases actualizadas correctamente' }) }} /> }
                    {/* LISTA DE ESPERA */}
                    {modalListaEspera && <ListaEspera  masterClasses={masterClasses} pacientes={pacientes} onClose={() => setModalListaEspera(false)} callBack={() => { setAlert({ open: true, severity: 'success', msg: 'Paciente añadido correctamente' }) }} />}
                </Grid>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </>
        );
    } catch (e: any) {
        const error: ILog = { method: 'AgendaAct.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }

};

export default AgendaAct;