import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Paper, Container, Grid, Alert, Button, Dialog, DialogContent, DialogActions, CircularProgress, Avatar, TextField, MenuItem, IconButton, Tooltip, List, ListItem, ListItemText, ListItemSecondaryAction, DialogTitle, DialogContentText, Snackbar, Switch, FormControlLabel } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import ILog from "../../types/Log";
import LogService from "../../services/LogService";
import ConfigService from "../../services/ConfigService";
import * as Constantes from '../../common/Constantes';
import { SketchPicker } from 'react-color';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import * as constantes from "../../common/Constantes";
import IEmpleado from "../../types/Empleado";
import AuthService from "../../services/AuthService";
import IOutOffice from "../../types/OutOffice";
import ConfigUtils from "../../utils/Config";
import { CONFIGTYPES } from "../../types/Config";
import IAlert from "../../types/Alert";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

export interface ISimpleUser {
    _id: string;
    internalID: number;
    email: string;
    firstName: string;
    lastName: string;
    role: number;
    color: string,
    accesoApp: boolean;
    activo: boolean;
}

const locale = constantes.locale;

const Empleados = () => {
    const theme = createTheme();
    const history = useNavigate();

    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const [empleados, setEmpleados] = useState<Array<ISimpleUser>>(ConfigUtils.getEmpleados());
    const [outOffices, setOutOffices] = useState<Array<IOutOffice>>(ConfigUtils.getOutOffices());
    const [verMas, setVerMas] = useState(false);

    const [modalNewEmpleado, setModalNewEmpleado] = useState(false);
    const [loadingNewEmpleado, setLoadingNewEmpleado] = useState(false);
    const [emailEmpleado, setEmailEmpleado] = useState<string>('');
    const [errorEmail, setErrorEmail] = useState(false);
    const [nombreEmpleado, setNombreEmpleado] = useState<string>('');
    const [errorNombre, setErrorNombre] = useState(false);
    const [apellidosEmpleado, setApellidosEmpleado] = useState<string>('');
    const [errorApellidos, setErrorApellidos] = useState(false);
    const [roleEmpleado, setRoleEmpleado] = useState<number>(1);
    const [colorEmpleado, setColorEmpleado] = useState<string>(constantes.defaultEmpleadoColor);
    const [showColorPicker, setShowColorPicker] = useState(false);

    const [accesoAppEmpleado, setAccesoAppEmpleado] = useState(false);
    const [openDialogAcceso, setOpenDialogAcceso] = useState(false);
    const [openDialogQuitarAcceso, setOpenDialogQuitarAcceso] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [idEmpleado, setIdEmpleado] = useState<string>('');
    const [selectedEmpleado, setSelectedEmpleado] = useState<ISimpleUser>();
    const [modalDeleteEmpleado, setModalDeleteEmpleado] = useState(false);

    const [modalNewOutOffice, setModalNewOutOffice] = useState(false);
    const [loadingNewOutOffice, setLoadingNewOutOffice] = useState(false);
    const [especialistaId, setEspecialistaId] = useState<number>(0);
    const [start, setStart] = useState<Date>(new Date(new Date().setMinutes(0)));
    const [end, setEnd] = useState<Date>(new Date(new Date().setMinutes(0)));

    const [isEditOutOffice, setIsEditOutOffice] = useState(false);
    const [selectedOutOffice, setSelectedOutOffice] = useState<IOutOffice>();
    const [modalDelete, setModalDelete] = useState(false);
    
    const roles = Object.keys(constantes.ROLES).filter((v) => isNaN(Number(v)))
    .map((rol) => {
        return {
          rol,
          id: constantes.ROLES[rol as keyof typeof constantes.ROLES],
        };
    });

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const closeModalNewEmpleado = () => {
        setEmailEmpleado('');
        setErrorEmail(false);
        setNombreEmpleado('');
        setErrorNombre(false);
        setApellidosEmpleado('');
        setErrorApellidos(false);
        setRoleEmpleado(1);
        setColorEmpleado(constantes.defaultEmpleadoColor);
        setShowColorPicker(false);
        setAccesoAppEmpleado(false);
        setIsEdit(false);
        setIdEmpleado('');
        setLoadingNewEmpleado(false);
        setModalNewEmpleado(false);
    };

    const saveEmpleado = () => {
        if (emailEmpleado === '') setErrorEmail(true);
        if (nombreEmpleado === '') setErrorNombre(true);
        if (apellidosEmpleado === '') setErrorApellidos(true);
        if (emailEmpleado === '' || nombreEmpleado === '' || apellidosEmpleado === '') return;

        if (!constantes.Email_REGEX.test(emailEmpleado)) {
            setAlert({ open: true, severity: 'error', msg: 'El email no tiene un formato válido' });
            return;
        };

        if (emailEmpleado === empleados.find(e => e.internalID === 0)?.email) {
            setAlert({ open: true, severity: 'error', msg: 'El correo electrónico del usuario ya existe' });
            return;
        };

        if (accesoAppEmpleado) {
            setOpenDialogAcceso(true);
            return;
        }

        setLoadingNewEmpleado(true);
        const empleado: IEmpleado = {
            userId: ConfigUtils.getUserId(),
            email: emailEmpleado,
            firstName: nombreEmpleado,
            lastName: apellidosEmpleado,
            role: roleEmpleado,
            color: colorEmpleado,
            accesoApp: accesoAppEmpleado
        };

        ConfigService.createEmpleado(empleado)
            .then((response: any) => {
                const newEmpleado: ISimpleUser = response.data;
                ConfigUtils.addConfig(CONFIGTYPES.users.toString(), newEmpleado);
                const newEmpleados: ISimpleUser[] = [...empleados, newEmpleado]
                setEmpleados(newEmpleados);
                setAlert({ open: true, severity: 'success', msg: 'Empleado guardado correctamente' });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Empleados.Component.saveEmpleado', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoadingNewEmpleado(false);
                closeModalNewEmpleado();
            });
    };

    const setIsUpdate = (empleado: ISimpleUser) => {
        setIsEdit(true);
        setIdEmpleado(empleado._id);
        setEmailEmpleado(empleado.email);
        setNombreEmpleado(empleado.firstName);
        setApellidosEmpleado(empleado.lastName);
        setRoleEmpleado(empleado.role);
        setColorEmpleado(empleado.color);
        setAccesoAppEmpleado(empleado.accesoApp ?? false);
        setModalNewEmpleado(true);
    };

    const updateEmpleado = () => {
        if (nombreEmpleado === '') setErrorNombre(true);
        if (apellidosEmpleado === '') setErrorApellidos(true);
        if (nombreEmpleado === '' || apellidosEmpleado === '') return;

        //Verificamos si hay cambio de acceso a la aplicación
        if (!empleados.find(e => e._id === idEmpleado)?.accesoApp === accesoAppEmpleado) {
            if (accesoAppEmpleado) {
                setOpenDialogAcceso(true);
                return;
            } else {
                setOpenDialogQuitarAcceso(true);
                return;
            }
        }

        setLoadingNewEmpleado(true);

        if (idEmpleado === ConfigUtils.getUserId()) {
            const user = {
                firstName: nombreEmpleado,
                lastName: apellidosEmpleado,
                color: colorEmpleado
            };
            AuthService.updateUser(idEmpleado, user)
            .then((response: any) => {
                const newEmpleados: ISimpleUser[] = [response.data, ...empleados.filter((e) => e._id !== response.data._id)].sort((a: any,b: any) => {
                    return a.internalID - b.internalID;
                });;
                setEmpleados(newEmpleados);
                ConfigUtils.editConfig(CONFIGTYPES.users.toString(), response.data);
                setAlert({ open: true, severity: 'success', msg: 'Usuario actualizado correctamente' });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Empleados.Component.updateUser', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoadingNewEmpleado(false);
                closeModalNewEmpleado();
            });
        } else {
            const empleado: IEmpleado = {
                userId: ConfigUtils.getUserId(),
                email: emailEmpleado,
                firstName: nombreEmpleado,
                lastName: apellidosEmpleado,
                role: roleEmpleado,
                color: colorEmpleado,
                accesoApp: accesoAppEmpleado
            };

            ConfigService.updateEmpleado(idEmpleado, empleado)
            .then((response: any) => {
                const newEmpleados: ISimpleUser[] = [...empleados.filter((e) => e._id !== response.data._id), response.data].sort((a: any,b: any) => {
                    return a.internalID - b.internalID;
                });;
                setEmpleados(newEmpleados);
                ConfigUtils.editConfig(CONFIGTYPES.users.toString(), response.data);
                setAlert({ open: true, severity: 'success', msg: 'Empleado actualizado correctamente' });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Empleados.Component.updateEmpleado', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoadingNewEmpleado(false);
                closeModalNewEmpleado();
            });
        }
    };

    const sendAcceso = (dar: boolean) => {
        setLoadingNewEmpleado(true);

        if (dar) {
            setOpenDialogAcceso(false);
            if (isEdit) {
                //Enviar email de acceso
                AuthService.createPass(emailEmpleado)
                .then((response: any) => {
                    setAlert({ open: true, severity: 'success', msg: response.data.message });
                    actualizarEmpleado(idEmpleado);
                })
                .catch ((e: any) => {
                    const error: ILog = { method: 'Empleados.Component.createPass', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                    setLoadingNewEmpleado(false);
                });
            } else {
                //Creamos el empleado primero
                const empleado: IEmpleado = {
                    userId: ConfigUtils.getUserId(),
                    email: emailEmpleado,
                    firstName: nombreEmpleado,
                    lastName: apellidosEmpleado,
                    role: roleEmpleado,
                    color: colorEmpleado
                };

                ConfigService.createEmpleado(empleado)
                    .then((response: any) => {
                        const newEmpleado: ISimpleUser = response.data;
                        ConfigUtils.addConfig(CONFIGTYPES.users.toString(), newEmpleado);
                        const newEmpleados: ISimpleUser[] = [...empleados, newEmpleado]
                        setEmpleados(newEmpleados);
                        setAlert({ open: true, severity: 'success', msg: 'Empleado guardado correctamente' });
                        //Enviar email de acceso
                        AuthService.createPass(emailEmpleado)
                        .then((response: any) => {
                            setAlert({ open: true, severity: 'success', msg: response.data.message });
                            actualizarEmpleado(newEmpleado._id);
                        })
                        .catch ((e: any) => {
                            const error: ILog = { method: 'Empleados.Component.createPass', level: 'error', message: e.message, meta: e.response };
                            LogService.logError(error);
                            setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                            setLoadingNewEmpleado(false);
                        });
                    })
                    .catch((e: any) => {
                        if (e.response.status === 401) {
                            localStorage.clear();
                            history(constantes.R_HOME);
                        } else {
                            const error: ILog = { method: 'Empleados.Component.createEmpleado', level: 'error', message: e.message, meta: e.response };
                            LogService.logError(error);
                            setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                        }
                    })
                    .finally(() => {
                        setLoadingNewEmpleado(false);
                    });
            }
        } else {
            setOpenDialogQuitarAcceso(false);
            actualizarEmpleado(idEmpleado);
        }
    };

    const actualizarEmpleado = (id: string) => {
        const empleado: IEmpleado = {
            userId: ConfigUtils.getUserId(),
            email: emailEmpleado,
            firstName: nombreEmpleado,
            lastName: apellidosEmpleado,
            role: roleEmpleado,
            color: colorEmpleado,
            accesoApp: accesoAppEmpleado
        };
    
        ConfigService.updateEmpleado(id, empleado)
        .then((response: any) => {
            const newEmpleados: ISimpleUser[] = [...empleados.filter((e) => e._id !== response.data._id), response.data].sort((a: any,b: any) => {
                return a.internalID - b.internalID;
            });;
            setEmpleados(newEmpleados);
            ConfigUtils.editConfig(CONFIGTYPES.users.toString(), response.data);
            setAlert({ open: true, severity: 'success', msg: 'Empleado actualizado correctamente' });
        })
        .catch((e: any) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'Empleados.Component.updateEmpleado', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        })
        .finally(() => {
            setLoadingNewEmpleado(false);
            closeModalNewEmpleado();
        });
    };

    const closeModalNewOutOffice = () => {
        setStart(new Date(new Date().setMinutes(0)));
        setEnd(new Date(new Date().setMinutes(0)));
        setEspecialistaId(0);
        setLoadingNewOutOffice(false);
        setModalNewOutOffice(false);

        setSelectedOutOffice(undefined);
        setIsEditOutOffice(false);
    };

    const editOutOffice = (outOffice: IOutOffice) => {
        setSelectedOutOffice(outOffice);
        setIsEditOutOffice(true);
        setStart(outOffice.start);
        setEnd(outOffice.end);
        setEspecialistaId(outOffice.especialistaId);
        setModalNewOutOffice(true);
    };

    const saveOutOffice = () => {
        setLoadingNewOutOffice(true);

        const oof: IOutOffice = {
            userId: ConfigUtils.getUserId(),
            especialistaId,
            start,
            end
        };

        ConfigService.createOutOffice(oof)
            .then((response: any) => {
                const newOutOffice: IOutOffice = response.data;
                ConfigUtils.addConfig(CONFIGTYPES.outoffices.toString(), newOutOffice);
                const newOutOffices: IOutOffice[] = [...outOffices, newOutOffice];
                setOutOffices(newOutOffices);
                setAlert({ open: true, severity: 'success', msg: 'No disponible guardado correctamente' });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Empleados.Component.saveOutOffice', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoadingNewOutOffice(false);
                closeModalNewOutOffice();
            });
    };

    const updateOutOffice = () => {
        if (!selectedOutOffice) return;
        if (!selectedOutOffice._id) return;
        setLoadingNewOutOffice(true);

        const oof: IOutOffice = {
            userId: selectedOutOffice.userId,
            especialistaId: selectedOutOffice.especialistaId,
            start,
            end
        };

        ConfigService.updateOutOffice(selectedOutOffice._id, oof)
            .then((response: any) => {
                const newOutOffices: IOutOffice[] = [response.data, ...outOffices.filter((e) => e._id !== response.data._id)];
                setOutOffices(newOutOffices);
                ConfigUtils.editConfig(CONFIGTYPES.outoffices.toString(), response.data);
                setAlert({ open: true, severity: 'success', msg: 'No disponible actualizado correctamente' });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Empleados.Component.updateOutOffice', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoadingNewOutOffice(false);
                closeModalNewOutOffice();
            });
    };

    const deleteOutOffice = () => {
        if (!selectedOutOffice) return;
        if (!selectedOutOffice._id) return;
        setLoadingNewOutOffice(true);
        
        ConfigService.deleteOutOffice(selectedOutOffice._id)
            .then((response: any) => {
                const newOutOffices: IOutOffice[] = [...outOffices.filter((e) => e._id !== selectedOutOffice._id)];
                setOutOffices(newOutOffices);
                ConfigUtils.deleteConfig(CONFIGTYPES.outoffices.toString(), selectedOutOffice._id);
                setAlert({ open: true, severity: 'success', msg: 'No disponible eliminado correctamente' });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Empleados.Component.deleteOutOffice', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoadingNewOutOffice(false);
                setModalDelete(false);
                closeModalNewOutOffice();
            });
    };

    const deleteEmpleado = () => {
        if (!selectedEmpleado) return;
        if (!selectedEmpleado._id) return;
        
        ConfigService.deleteEmpleado(selectedEmpleado._id)
            .then((response: any) => {
                const newEmpleados: ISimpleUser[] = [response.data, ...empleados.filter((e) => e._id !== response.data._id)].sort((a: any,b: any) => {
                    return a.internalID - b.internalID;
                });;
                setEmpleados(newEmpleados);
                ConfigUtils.editConfig(CONFIGTYPES.users.toString(), response.data);
                setAlert({ open: true, severity: 'success', msg: "Empleado eliminado correctamente" });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Empleados.Component.deleteEmpleado', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setModalDeleteEmpleado(false);
                setSelectedEmpleado(undefined);
            });
    };

    try {
        return (
            <Grid>
                <Grid container>
                    <Grid item md={12}>
                        <Container>
                            <Grid>
                                
                                <Grid container spacing={2}>
                                    <Grid item md={2} xs={12}>
                                        <Button onClick={() => setModalNewEmpleado(true)} sx={{ backgroundColor: '#63ad3c', "&:hover": { backgroundColor: '#7dc557' } }} variant="contained" >
                                            <Typography variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', color: 'white' }}>Añadir usuario</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item md={5} xs={12}>
                                        <Button onClick={() => setModalNewOutOffice(true)} sx={{ backgroundColor: '#63ad3c', "&:hover": { backgroundColor: '#7dc557' } }} variant="contained" >
                                            <Typography variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', color: 'white' }}>Añadir periodo no disponible</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>

                                {empleados.filter(e => e.activo == true).map((empleado: ISimpleUser) => {
                                    return (
                                        <Paper elevation={2} sx={{ marginTop: theme.spacing(2), padding: theme.spacing(3) }} key={empleado._id}>
                                            
                                            <Grid container>
                                                <Grid item md={11} xs={11}>
                                                    <Typography variant="h6" >{`${empleado.firstName} ${empleado.lastName}`}</Typography>
                                                </Grid>
                                                <Grid item md={1} xs={1}>
                                                    <Tooltip title="EDITAR EMPLEADO" >
                                                        <IconButton onClick={() => setIsUpdate(empleado)}>
                                                            <EditIcon style={{ float: 'right', cursor: 'pointer', color: 'grey' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {empleado.internalID != 0 && <Tooltip title="ELIMINAR EMPLEADO" >
                                                        <IconButton onClick={() => {setModalDeleteEmpleado(true); setSelectedEmpleado(empleado)}}>
                                                            <DeleteIcon style={{ float: 'right', cursor: 'pointer', color: 'grey' }} />
                                                        </IconButton>
                                                    </Tooltip>}
                                                </Grid>
                                                <Grid item md={4} xs={12} sx={{ paddingLeft: theme.spacing(1) }}>
                                                    <Typography variant="body2" >{empleado.email}</Typography>
                                                    <Typography variant="body2" >{Constantes.ROLES[empleado.role]}</Typography>
                                                    <div style={{ marginTop: '5px', padding: '5px', background: '#fff', borderRadius: '1px', boxShadow: '0 0 0 1px rgba(0,0,0,.1)', display: 'inline-block' }}>
                                                        <div style={{ width: '36px', height: '14px', borderRadius: '2px', background: `${empleado.color}` }} />
                                                    </div>
                                                </Grid>
                                            </Grid>

                                            {outOffices.filter(e => e.especialistaId === empleado.internalID).length > 0 && 
                                            <Grid container sx={{ mt: theme.spacing(2) }}>
                                                <Grid item md={10} xs={12}>
                                                    <Typography sx={{ fontWeight: 'bold' }} variant="caption" >NO DISPONIBLE</Typography>
                                                    {verMas ?
                                                        <Tooltip title="VER MENOS" >
                                                            <IconButton onClick={() => setVerMas(false)}>
                                                                <RemoveIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    :
                                                        <Tooltip title="VER TODOS" >
                                                            <IconButton onClick={() => setVerMas(true)}>
                                                                <AddIcon />
                                                            </IconButton>
                                                        </Tooltip>}
                                                </Grid>
                                                <List dense>
                                                {!verMas && outOffices.filter(e => e.especialistaId === empleado.internalID && new Date(e.end).getTime() > new Date().getTime()).map((outOffice: IOutOffice) => {
                                                    return (
                                                        <ListItem divider key={outOffice._id}>
                                                            <ListItemText sx={{ mr: theme.spacing(6) }} primary={`Desde el ${ConfigUtils.formatOutOfficeDate(outOffice.start)} hasta el ${ConfigUtils.formatOutOfficeDate(outOffice.end)}`} />
                                                            {new Date(outOffice.start).getTime() > new Date().getDate() && 
                                                            <ListItemSecondaryAction>
                                                                <Tooltip title="EDITAR PERIODO" ><IconButton edge="end" onClick={() => editOutOffice(outOffice)}><EditIcon/></IconButton></Tooltip>
                                                                <Tooltip title="ELIMINAR PERIODO" ><IconButton edge="end" onClick={() => {setModalDelete(true); setSelectedOutOffice(outOffice)}}><DeleteIcon/></IconButton></Tooltip>
                                                            </ListItemSecondaryAction>}
                                                        </ListItem>
                                                        );
                                                })}
                                                </List>
                                                <List dense>
                                                {verMas && outOffices.filter(e => e.especialistaId === empleado.internalID).map((outOffice: IOutOffice) => {
                                                    return (
                                                        <ListItem divider key={outOffice._id}>
                                                            <ListItemText sx={{ mr: theme.spacing(6) }} primary={`Desde el ${ConfigUtils.formatOutOfficeDate(outOffice.start)} hasta el ${ConfigUtils.formatOutOfficeDate(outOffice.end)}`} />
                                                            {new Date(outOffice.start).getTime() > new Date().getDate() && 
                                                            <ListItemSecondaryAction>
                                                                <Tooltip title="EDITAR PERIODO" ><IconButton edge="end" onClick={() => editOutOffice(outOffice)}><EditIcon/></IconButton></Tooltip>
                                                                <Tooltip title="ELIMINAR PERIODO" ><IconButton edge="end" onClick={() => {setModalDelete(true); setSelectedOutOffice(outOffice)}}><DeleteIcon/></IconButton></Tooltip>
                                                            </ListItemSecondaryAction>}
                                                        </ListItem>
                                                        );
                                                })}
                                                </List>
                                            </Grid>}

                                        </Paper>
                                    );
                                })}
                            </Grid>
                        </Container>
                    </Grid>
                </Grid>

                {/* AÑADIR EMPLEADO */}
                <Dialog open={modalNewEmpleado} maxWidth="lg" fullWidth onClose={closeModalNewEmpleado}>
                    <DialogContent>
                        <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                            <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><AddIcon /></Avatar><div style={{ margin: '10px auto' }}>{isEdit ? 'Editar usuario' : 'Nuevo usuario'}</div>
                        </Typography>

                        <Grid container spacing={1}>
                            <Grid item md={4} xs={12} style={{ margin: 'auto' }}>
                                <TextField value={emailEmpleado} onChange={(event) => setEmailEmpleado(event.target.value)} disabled={isEdit} variant="outlined" label="Email" margin="dense" fullWidth required autoFocus error={errorEmail} helperText={errorEmail ? "El email es obligatorio" : ""} />
                            </Grid>
                            <Grid item md={4} xs={12} style={{ margin: 'auto' }}>
                                <TextField value={nombreEmpleado} onChange={(event) => setNombreEmpleado(event.target.value)} variant="outlined" label="Nombre" margin="dense" fullWidth required error={errorNombre} helperText={errorNombre ? "El nombre es obligatorio" : ""} />
                            </Grid>
                            <Grid item md={4} xs={12} style={{ margin: 'auto' }}>
                                <TextField value={apellidosEmpleado} onChange={(event) => setApellidosEmpleado(event.target.value)} variant="outlined" label="Apellidos" margin="dense" fullWidth required error={errorApellidos} helperText={errorApellidos ? "Los apellidos son obligatorios" : ""} />
                            </Grid>

                            <Grid item md={4} xs={12}>
                                <TextField select value={roleEmpleado} disabled={isEdit && idEmpleado === ConfigUtils.getUserId()} variant="outlined" label="Rol" margin="dense" fullWidth required onChange={(event) => setRoleEmpleado(parseInt(event.target.value))} >
                                {
                                    roles.map((rol) => {
                                        return (
                                            <MenuItem key={rol.id} value={rol.id}>{rol.rol}</MenuItem>
                                        );
                                    })
                                }
                                </TextField>
                            </Grid>
                            <Grid item md={1} xs={12}>
                                <div style={{ marginTop: '9px', padding: '5px', background: '#fff', borderRadius: '1px', boxShadow: '0 0 0 1px rgba(0,0,0,.1)', cursor: 'pointer' }} onClick={() => setShowColorPicker(true)}>
                                    <div style={{ height: '43px', borderRadius: '2px', background: `${colorEmpleado}` }} />
                                </div>
                                {showColorPicker && 
                                <div style={{ position: 'relative', zIndex: 2, bottom: '50px', left: '100px'}}>
                                    <div style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }} onClick={() => setShowColorPicker(false)} />
                                    <SketchPicker color={colorEmpleado} onChange={(color) => setColorEmpleado(color.hex)} />
                                </div>}
                            </Grid>
                            {idEmpleado != ConfigUtils.getUserId() && <Grid item md={6} xs={12} style={{ margin: 'auto', marginLeft: '15px' }}>
                                <FormControlLabel label="¿Quieres dar acceso al empleado a la aplicación?" control={<Switch checked={accesoAppEmpleado} onChange={(event) => setAccesoAppEmpleado(event.target.checked)} inputProps={{ 'aria-label': 'controlled' }} />} />
                            </Grid>}
                        </Grid>
                    </DialogContent>
                    {loadingNewEmpleado ? 
                        <Grid container>
                            <CircularProgress style={{ margin: '20px auto' }} />
                        </Grid> :
                        <DialogActions>
                            <Button onClick={isEdit ? updateEmpleado : saveEmpleado}>Guardar</Button>
                            <Button onClick={closeModalNewEmpleado}>Cancelar</Button>
                        </DialogActions>}
                </Dialog>

                {/* CONFIRMAR ACCESO A LA APP DEL EMPLEADO */}
                <Dialog open={openDialogAcceso} onClose={() => setOpenDialogAcceso(false)}>
                    <DialogTitle>ENVIAR ACCESO A LA APLICACIÓN POR EMAIL</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{`¿Está seguro de que desea enviar el email de acceso a la aplicación a ${nombreEmpleado}: ${emailEmpleado}?`}</DialogContentText>
                    </DialogContent>
                    
                    <DialogActions>
                        <Button onClick={() => sendAcceso(true)} color="primary">SI</Button>
                        <Button onClick={() => setOpenDialogAcceso(false)} color="primary" autoFocus>NO</Button>
                    </DialogActions>
                </Dialog>

                {/* QUITAR ACCESO A LA APP DEL EMPLEADO */}
                <Dialog open={openDialogQuitarAcceso} onClose={() => setOpenDialogQuitarAcceso(false)}>
                    <DialogTitle>QUITAR ACCESO A LA APLICACIÓN</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{`¿Está seguro de que quiere quitar el acceso a la aplicación a ${nombreEmpleado}: ${emailEmpleado}?`}</DialogContentText>
                    </DialogContent>
                    
                    <DialogActions>
                        <Button onClick={() => sendAcceso(false)} color="primary">SI</Button>
                        <Button onClick={() => setOpenDialogQuitarAcceso(false)} color="primary" autoFocus>NO</Button>
                    </DialogActions>
                </Dialog>

                {/* AÑADIR PERIODO NO DISPONIBLE */}
                <Dialog open={modalNewOutOffice} maxWidth="lg" fullWidth onClose={closeModalNewOutOffice}>
                    <DialogContent>
                        <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                            <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><AddIcon /></Avatar><div style={{ margin: '10px auto' }}>{isEditOutOffice ? 'Editar periodo no disponible' : 'Nuevo periodo no disponible'}</div>
                        </Typography>

                        <Grid container spacing={1}>
                            <Grid item md={4} xs={12}>
                                <TextField select value={especialistaId} disabled={isEditOutOffice} variant="outlined" label="Usuario" margin="dense" fullWidth required onChange={(event) => setEspecialistaId(parseInt(event.target.value))} >
                                    {
                                        empleados.filter(e => e.activo == true).map((empleado: any) => {
                                            return (
                                                <MenuItem key={empleado.internalID} value={empleado.internalID}>{empleado.firstName}</MenuItem>
                                            );
                                        })
                                    }
                                </TextField>
                            </Grid>
                            <Grid item md={4} xs={12} sx={{ marginTop: 1 }}>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                    <DateTimePicker
                                        renderInput={(props: any) => <TextField required fullWidth {...props} />}
                                        label="Fecha y hora de inicio"
                                        toolbarFormat="dd-mm-yyyy"
                                        minutesStep={5}
                                        value={start}
                                        ampm={false}
                                        onChange={(date: any) => { date && setStart(date); date && setEnd(date) }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item md={4} xs={12} sx={{ marginTop: 1 }}>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                    <DateTimePicker
                                        renderInput={(props: any) => <TextField required fullWidth {...props} />}
                                        label="Fecha y hora final"
                                        toolbarFormat="dd-mm-yyyy"
                                        minutesStep={5}
                                        value={end}
                                        ampm={false}
                                        onChange={(date: any) => { date && setEnd(date) }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    {loadingNewOutOffice ? 
                        <Grid container>
                            <CircularProgress style={{ margin: '20px auto' }} />
                        </Grid> :
                        <DialogActions>
                            <Button onClick={isEditOutOffice ? updateOutOffice : saveOutOffice}>Guardar</Button>
                            <Button onClick={closeModalNewOutOffice}>Cancelar</Button>
                        </DialogActions>}
                </Dialog>

                {/* ELIMINAR PERIODO NO DISPONIBLE */}
                <Dialog open={modalDelete} maxWidth="lg" onClose={() => setModalDelete(false)}>
                    <DialogTitle>ELIMINAR PERIODO</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{`¿Está seguro de que desea eliminar el periodo desde el ${selectedOutOffice && ConfigUtils.formatOutOfficeDate(selectedOutOffice.start)} hasta el ${selectedOutOffice && ConfigUtils.formatOutOfficeDate(selectedOutOffice.end)}?`}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={deleteOutOffice} color="primary">SI</Button>
                        <Button onClick={() => setModalDelete(false)} color="primary" autoFocus>NO</Button>
                    </DialogActions>

                </Dialog>

                {/* ELIMINAR EMPLEADO */}
                <Dialog open={modalDeleteEmpleado} maxWidth="lg" onClose={() => setModalDeleteEmpleado(false)}>
                    <DialogTitle>ELIMINAR EMPLEADO</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{`¿Está seguro de que desea eliminar al empleado ${selectedEmpleado?.firstName} ${selectedEmpleado?.lastName} ?`}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={deleteEmpleado} color="primary">SI</Button>
                        <Button onClick={() => setModalDeleteEmpleado(false)} color="primary" autoFocus>NO</Button>
                    </DialogActions>

                </Dialog>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </Grid>
        );
    } catch (e: any) {
        const error: ILog = { method: 'Empleados.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }

};

export default Empleados;