import { useState } from "react";
import { useForm } from "react-hook-form";
import { Typography, TextField, Button, CircularProgress, Container, Paper, Avatar, Alert, Snackbar } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AuthService from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import LogService from "../../services/LogService";
import ILog from "../../types/Log";
import { createTheme } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import IResetPass from "../../types/ResetPass";
import IAlert from "../../types/Alert";
import * as constantes from '../../common/Constantes';

const schema = yup.object().shape({
    password: yup.string().required('La contraseña es obligatoria').min(6, 'La contraseña debe tener al menos 8 caracteres').matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, 'Debe contener una mayúscula, una minúscula, un número y un carácter especial'),
    passwordConfirm: yup.string().required('La confirmación de contraseña es obligatoria').oneOf([yup.ref('password'), null], 'Las contraseñas deben coincidir')
});

const ResetPass = () => {
    const theme = createTheme();
    const history = useNavigate();

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const {register, handleSubmit, formState: { errors }} = useForm<IResetPass>({ resolver: yupResolver(schema) });

    const onSubmit = (data: IResetPass) => {
        setLoading(true);
        resetPass(data);
    };

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const resetPass = (data: IResetPass) => {

        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get(constantes.QP_ID);
        const token = urlParams.get(constantes.QP_TOKEN);

        if (!id || !token) {
            setAlert({ open: true, severity: 'error', msg: 'El token no es válido o ha expirado' });
            setLoading(false);
            return; 
        } 

        data._id = id;
        data.token = token;

        AuthService.changePass(data._id, data)
        .then((response: any) => {
            setLoading(false);
            setAlert({ open: true, severity: 'success', msg: response.data.message });
            setTimeout(() => {
                history(constantes.R_HOME);
            }, constantes.timeRedirect)
        })
        .catch ((e: any) => {
            const error: ILog = { method: 'ResetPass.Component.resetPass', level: 'error', message: e.message, meta: e.response };
            LogService.logError(error);
            setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            setLoading(false);
        });
    };

    try {
        return (
            <>
            <Container maxWidth="sm">
                <Paper elevation={3} sx={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3), padding: theme.spacing(3), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar sx={{ margin: theme.spacing(1), backgroundColor: theme.palette.secondary.main }}>
                        <LockIcon />
                    </Avatar>
    
                    <>
                    <Typography variant="h4" sx={{ textAlign: 'center', margin: theme.spacing(1, 0, 1) }}>Cambiar contraseña</Typography>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <TextField {...register("password")} variant="outlined" label="Contraseña" margin="normal" fullWidth required type="password" helperText={errors.password?.message} error={!!errors.password?.message} />
                        <TextField {...register("passwordConfirm")} variant="outlined" label="Confirmar contraseña" margin="normal" fullWidth required type="password" helperText={errors.passwordConfirm?.message} error={!!errors.passwordConfirm?.message} /><Button sx={{ margin: theme.spacing(3, 0, 2) }} variant="contained" color="primary" fullWidth type="submit" >{loading ? <CircularProgress size={25} color="inherit" /> : `Enviar`}</Button>
                    </form>
                    </>

                </Paper>
            </Container>

            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
            </Snackbar>
            </> 
        );
    } catch (e: any) {
        const error: ILog = { method: 'ResetPass.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }

};

export default ResetPass;