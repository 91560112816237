import { useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import IPaciente from "../../types/Paciente";
import ILog from "../../types/Log";
import IDocumento from "../../types/Documento";
import DocUtils from "../../utils/Doc";
import PacienteService from "../../services/PacienteService";
import LogService from "../../services/LogService";
import * as constantes from "../../common/Constantes";
import { Button, CircularProgress, Dialog, DialogActions, Grid, Paper, Typography, Alert, Container, Snackbar, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import SignaturePad from 'react-signature-canvas';
import IAlert from "../../types/Alert";
import { resources } from "../../common/Resources";
import { isMobile } from "react-device-detect";
import ConfigUtils from "../../utils/Config";
import IConsentimientoDoc from "../../types/CI";
import Utilities from "../../common/Utilities";

const Consentimiento = () => {
    const theme = createTheme();
    const history = useNavigate();

    const [loading, setLoading] = useState(false);
    let location = useLocation();
    const [paciente] = useState<IPaciente>(location.state.paciente);
    const [esMenor] = useState<boolean>(location.state.esMenor);
    const [signature, setSignature] = useState(undefined);
    const [openDialog, setOpenDialog] = useState(false);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const ref = useRef() as React.MutableRefObject<any>;

    const [auth, setAuth] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [tratamiento, setTratamiento] = useState<boolean>(false);
    const [email, setEmail] = useState<boolean>(false);
    const [whatsApp, setWhatsApp] = useState<boolean>(false);

    const [CI] = useState<IConsentimientoDoc | null>(ConfigUtils.getConsentientoDoc());
    const [clinicDomicilio] = useState<string>(ConfigUtils.getClinicDomicilio());
    const [clinicTelefono] = useState<string>(ConfigUtils.getClinicTelefono());
    const [clinicEmail] = useState<string>(ConfigUtils.getClinicEmail());
    const [clinicName] = useState<string>(ConfigUtils.getClinicName());
    const [clinicCity] = useState<string>(ConfigUtils.getClinicCity());

    if (!paciente) history(constantes.R_PACIENTES);

    const saveSignedDoc = () => {
        setLoading(true);
        const consentimiento: ArrayBuffer = DocUtils.formatCI(paciente, signature, esMenor, email, whatsApp, CI, clinicDomicilio, clinicTelefono, clinicEmail, clinicName, clinicCity);
        const documento: IDocumento = {
            nombre: 'Consentimiento Informado.pdf',
            tipo: 'pdf',
            documento: consentimiento,
            pacienteId: paciente._id
        };

        if (!documento.documento) {
            setAlert({ open: true, severity: 'warning', msg: 'Error en el archivo' });
            return; 
        }

        const blob = new Blob([documento.documento], { type: 'application/pdf' });
        const formData = new FormData();
        formData.append('file', blob, documento.nombre);
        formData.append('nombre', documento.nombre);
        formData.append('tipo', documento.tipo);
        formData.append('pacienteId', documento.pacienteId);

        const promiseCI = PacienteService.createDoc(formData);
        const promisePaciente = PacienteService.update(paciente._id, { ...paciente, authEmail: email, authWhatsApp: whatsApp });

        Promise.all([promiseCI, promisePaciente])
            .then((response: any) => {
                setLoading(false);
                history(`${constantes.R_PACIENTE}?${constantes.QP_ID}=${paciente._id}`, { state: { open: true, severity: 'success', msg: 'Consentimiento firmado correctamente' } });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Consentimiento.Component.saveSignedDoc', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                    setLoading(false);
                }
            });
    };

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    try {
        return (
            <Container maxWidth="lg">
                <Paper id="CI" sx={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3), padding: theme.spacing(3), display: 'flex', flexDirection: 'column' }}>

                    <Typography variant="h6" gutterBottom align="center" >{resources.CI.title}</Typography>
                    
                    <Typography variant="caption" sx={{ fontWeight: 800 }} >{resources.CI.responsable}</Typography>
                    <Typography paragraph align="justify" sx={{ fontSize: '0.9rem' }} >{resources.CI.responsableTexto
                        .replace("{0}", CI?.responsable != undefined ? CI.responsable : '')
                        .replace("{1}", CI?.responsableNIF != undefined ? CI.responsableNIF : '')
                        .replace("{2}", clinicDomicilio)
                        .replace("{3}", clinicTelefono)
                        .replace("{4}", clinicEmail)
                        .replace("{7}", CI?.responsableNIF != undefined ? Utilities.getSpanichIDType(CI.responsableNIF) : '')
                    }</Typography>
                    <Typography variant="caption" sx={{ fontWeight: 800 }} >{resources.CI.finalidad}</Typography>
                    <Typography paragraph align="justify" sx={{ fontSize: '0.9rem' }} >{resources.CI.finalidadTexto}</Typography>
                    <Typography variant="caption" sx={{ fontWeight: 800 }} >{resources.CI.datos}</Typography>
                    <Typography paragraph align="justify" sx={{ fontSize: '0.9rem' }} >{resources.CI.datosTexto}</Typography>
                    <Typography variant="caption" sx={{ fontWeight: 800 }} >{resources.CI.acceso}</Typography>
                    <Typography paragraph align="justify" sx={{ fontSize: '0.9rem' }} >{resources.CI.accesoTexto.replace("{5}", clinicName)}</Typography>
                    <Typography variant="caption" sx={{ fontWeight: 800 }} >{resources.CI.legitimacion}</Typography>
                    <Typography paragraph align="justify" sx={{ fontSize: '0.9rem' }} >{resources.CI.legitimacionTexto}</Typography>
                    <Typography variant="caption" sx={{ fontWeight: 800 }} >{resources.CI.destinatarios}</Typography>
                    <Typography paragraph align="justify" sx={{ fontSize: '0.9rem' }} >{resources.CI.destinatariosTexto}</Typography>
                    <Typography variant="caption" sx={{ fontWeight: 800 }} >{resources.CI.plazo}</Typography>
                    <Typography paragraph align="justify" sx={{ fontSize: '0.9rem' }} >{resources.CI.plazoTexto}</Typography>
                    <Typography variant="caption" sx={{ fontWeight: 800 }} >{resources.CI.derechos}</Typography>
                    <Typography paragraph align="justify" sx={{ fontSize: '0.9rem' }} >{resources.CI.derechosTexto}</Typography>
                    <Typography variant="caption" sx={{ fontWeight: 800 }} >{resources.CI.seguridad}</Typography>
                    <Typography paragraph align="justify" sx={{ fontSize: '0.9rem' }} >{resources.CI.seguridadTexto}</Typography>
                    <Typography variant="caption" sx={{ fontWeight: 800 }} >{resources.CI.autorizacion}</Typography>

                    <FormGroup sx={{ mb: theme.spacing(3), fontSize: '0.9rem' }}>
                        <FormControlLabel control={<Checkbox checked={auth} onChange={() => setAuth(!auth)} />} label={resources.CI.authIncorporacion} />
                        <FormControlLabel control={<Checkbox checked={confirm} onChange={() => setConfirm(!confirm)} />} label={resources.CI.authConfirmacion} />
                        <FormControlLabel control={<Checkbox checked={email} onChange={() => setEmail(!email)} />} label={resources.CI.authEmail} />
                        <FormControlLabel control={<Checkbox checked={whatsApp} onChange={() => setWhatsApp(!whatsApp)} />} label={resources.CI.authWhatsApp} />
                    </FormGroup>

                    <Typography variant="caption" sx={{ fontWeight: 800 }} >{resources.CI.tratamiento}</Typography>
                    <Typography paragraph align="justify" sx={{ fontSize: '0.9rem' }} >{resources.CI.tratamientoTexto.replace("{6}", CI?.texto != undefined ? CI.texto : '')}</Typography>

                    <FormGroup sx={{ mb: theme.spacing(3), fontSize: '0.9rem' }}>
                        <FormControlLabel control={<Checkbox checked={tratamiento} onChange={() => setTratamiento(!tratamiento)} />} label={resources.CI.tratamientoConfirmacion} />
                    </FormGroup>

                    <Typography variant="body2" gutterBottom align="center" >{`${clinicCity.toLocaleUpperCase()}, a ${new Date().getDate()} de ${constantes.monthNames[new Date().getMonth()]} de ${new Date().getFullYear()}`}</Typography>
                    {signature && <div style={{ textAlign: 'center' }}><img src={signature != null ? signature : ''} width="300px" height="200px" alt="Signature" /></div>}

                    <Grid style={{ margin: '10px auto' }}>
                        {loading ? <CircularProgress /> :
                            <>
                                {signature && <Button id="Guardar" style={{ marginBottom: '15px', marginTop: '15px' }} variant="contained" color="primary" size="small" onClick={saveSignedDoc} fullWidth>GUARDAR</Button>}
                                <Button disabled={!auth || !confirm || !tratamiento} id="Firmar" style={{ marginBottom: '15px' }} variant="contained" color="primary" size="small" fullWidth onClick={() => setOpenDialog(true)}>FIRMAR</Button>
                                <Button variant="contained" color="secondary" size="small" fullWidth onClick={() => history(-1)}>Cancelar</Button>
                            </>
                        }
                    </Grid>

                    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                        <SignaturePad ref={ref} canvasProps={{ width: '600px', height: isMobile ? '250px' : '400px ' }} />
                        <DialogActions>
                            <Button color="primary" onClick={() => setOpenDialog(false)}>CERRAR</Button>
                            <Button color="primary" onClick={() => ref.current.clear()}>LIMPIAR</Button>
                            <Button onClick={() => { setSignature(ref.current.getTrimmedCanvas().toDataURL('image/png')); setOpenDialog(false) }}>GUARDAR</Button>
                        </DialogActions>
                    </Dialog>
                </Paper>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </Container>
        );
    } catch (e: any) {
        const error: ILog = { method: 'Consentimiento.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }

};

export default Consentimiento;