import { Alert, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Snackbar, Tooltip, Typography } from "@mui/material";
import FileIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Download from '@mui/icons-material/GetApp';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import IDocumento from "../../../types/Documento";
import PacienteService from "../../../services/PacienteService";
import IPaciente from "../../../types/Paciente";
import { createTheme } from '@mui/material/styles';
import ILog from "../../../types/Log";
import LogService from "../../../services/LogService";
import DocUtils from "../../../utils/Doc";
import IAlert from "../../../types/Alert";
import * as constantes from "../../../common/Constantes";
import ConfigUtils from "../../../utils/Config";
import Utilities from "../../../common/Utilities";

type DocumentosProps = {
    paciente: IPaciente;
    callBack: (id: string) => void
}

const Documentos = (props: DocumentosProps) => {

    const theme = createTheme();
    const history = useNavigate();

    const [loading, setLoading] = useState(true);
    const [loadingDoc, setLoadingDoc] = useState(false);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const [docs, setDocs] = useState<Array<any>>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogEmail, setOpenDialogEmail] = useState(false);
    const [doc, setDoc] = useState<IDocumento>();
    const [correo] = useState(ConfigUtils.getMail());
    
    const userRole: number = ConfigUtils.getUserRole();

    useEffect(() => {

            PacienteService.getDocs(props.paciente._id)
            .then((response: any) => {
                setDocs(response.data);
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else if (e.response.status === 404) {

                } else {
                    const error: ILog = { method: 'Documentos.Component.useEffect', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoading(false);
            });

    }, [setDocs, setLoading, setAlert, history, props.paciente, alert]);

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const saveDoc = async (files: File[]) => {     
        if (!files || files.length === 0) {
            setAlert({ open: true, severity: 'warning', msg: 'No se ha seleccionado ningún archivo' });
            return;
        }
        
        const file = files[0];

        if (!file || !file.name) {
            setAlert({ open: true, severity: 'warning', msg: 'El archivo no tiene un nombre válido' });
            return;
        }

        const maxSize = 5 * 1024 * 1024; // 5 MB
        const allowedExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx'];
        const parts = file.name.split('.');
        const fileExtension = parts.length > 1 ? parts.pop()?.toLowerCase() || '' : '';

        if (!allowedExtensions.includes(fileExtension)) {
            setAlert({ open: true, severity: 'warning', msg: 'Solo se permiten archivos con las siguientes extensiones: ' + allowedExtensions.join(', ') });
            return;
        }

        if (file.size > maxSize) {
            setAlert({ open: true, severity: 'warning', msg: 'El archivo no debe superar los 5 MB' });
            return;
        }
        
        const documento = await DocUtils.formatDoc(file, props.paciente._id);
        if (!documento) {
            setAlert({ open: true, severity: 'warning', msg: 'Error en el formato del archivo' });
            return; 
        }

        if (!documento.documento) {
            setAlert({ open: true, severity: 'warning', msg: 'Error en el archivo' });
            return; 
        }

        const blob = new Blob([documento.documento], { type: file.type });
        const formData = new FormData();
        formData.append('file', blob, documento.nombre);
        formData.append('nombre', documento.nombre);
        formData.append('tipo', documento.tipo);
        formData.append('pacienteId', documento.pacienteId);

        PacienteService.createDoc(formData)
        .then((response: any) => {
            setAlert({ open: true, severity: 'success', msg: 'Documento guardado correctamente' });
        })
        .catch ((e: any) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'Documentos.Component.saveDoc', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        });
    };

    const deleteDoc = () => {
        if (!doc?._id) return;

        setLoadingDoc(true);

        PacienteService.deleteDoc(doc?._id)
        .then((response: any) => {
            setDocs(docs.filter(x => x._id !== doc._id));
            setDoc({ _id: '', nombre: '', tipo: '', documento: null, pacienteId: '' });
            setAlert({ open: true, severity: 'success', msg: response.data.message });
            //Re-render parent
            if (!doc?._id) return;
            props.callBack(doc?._id);
        })
        .catch ((e: any) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'Documentos.Component.deleteDoc', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        })
        .finally(() => {
            setOpenDialog(false);
            setLoadingDoc(false);
        });
    };

    const sendConsentimiento = () => {
        if (Utilities.isNullEmptyUndefined(props.paciente.email)) {
            setOpenDialog(false);
            setOpenDialogEmail(false);
            setAlert({ open: true, severity: 'warning', msg: 'El paciente no tiene un correo electrónico donde enviar el consentimiento' });
            return;
        }

        setLoadingDoc(true);

        PacienteService.sendConsentimiento({ email: props.paciente.email, doc: doc?.documento })
        .then(response => {
            setAlert({ open: true, severity: 'success', msg: response.data.message });
            setDoc({ _id: '', nombre: '', tipo: '', documento: null, pacienteId: '' });
        })
        .catch (e => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'CitaActual.Component.sendConsentimiento', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        })
        .finally(() => {
            setOpenDialogEmail(false);
            setLoadingDoc(false);
        });
    };

    const base64ToBlob = (base64: string, type: string) => {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type });
    }

    try {

        return (
            <>
                <Grid item md={12} xs={12}>
                    <Container maxWidth="md">
                        <Paper elevation={3} sx={{ marginTop: theme.spacing(3), padding: theme.spacing(3), display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', marginLeft: '10px' }} >DOCUMENTOS ASOCIADOS</Typography>

                            {loading ? 
                            <Grid container>
                                <CircularProgress style={{ margin: '10px auto' }} />
                            </Grid>
                             : 
                                docs && docs.length === 0 ? <div>No se han encontrado documentos</div> : 
                                <>
                                    {docs.map(doc => {
                                        let blob;
                                        let url;
                                        if (doc.documento) {
                                            blob = base64ToBlob(doc.documento as string, doc.tipo as string);
                                            url = URL.createObjectURL(blob);
                                        }
                                        const nombrePartes = doc.nombre.split('.');
                                        const extension = nombrePartes.pop();
                                        const nombreArchivo = nombrePartes.join('.');

                                        return userRole == constantes.ROLES.SECRETARIO && doc.nombre != 'Consentimiento Informado.pdf' ? <div key={doc._id}></div> : (
                                            <Grid container key={doc._id}>
                                                <Grid item md={1} xs={1}>
                                                    <FileIcon fontSize="small" />
                                                </Grid>
                                                <Grid item md={7} xs={7}>
                                                    <Typography>{doc.nombre}</Typography>
                                                </Grid>
                                                <Grid item md={4} xs={4}>
                                                    {doc.nombre === 'Consentimiento Informado.pdf' && props.paciente.authEmail && correo && <Tooltip title="Enviar consentimiento" >
                                                        <IconButton sx={{ bottom: '11px '}} onClick={() => {setOpenDialogEmail(true); setDoc(doc)}}>
                                                            <EmailIcon style={{ cursor: 'pointer', float: 'right' }} />
                                                        </IconButton>
                                                    </Tooltip>}
                                                    <Tooltip title="Descargar" >
                                                        <IconButton sx={{ bottom: '7px '}}><a href={url} download={`${nombreArchivo}_${props.paciente.nombre} ${props.paciente.apellidos}.${extension}`}><Download style={{ color: 'grey', cursor: 'pointer' }} /></a></IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Borrar" >
                                                        <IconButton sx={{ bottom: '11px '}} onClick={() => {setOpenDialog(true); setDoc(doc)}}>
                                                            <DeleteIcon style={{ cursor: 'pointer', float: 'right' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </>
                            }
                            
                            <Button variant="contained" component="label" >SUBIR DOCUMENTO<input onChange={(e: any) => saveDoc(e.target.files)} id="file" type="file" hidden /></Button>
                        </Paper>
                    </Container>
                </Grid>
        
                {/* BORRAR DOCUMENTO */}
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                    <DialogTitle>BORRAR DOCUMENTO {doc?.nombre}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>¿Está seguro de que desea borrar el documento?</DialogContentText>
                    </DialogContent>
                    
                    {loadingDoc ? 
                    <Grid container>
                        <CircularProgress style={{ margin: '20px auto' }} />
                    </Grid> :   
                    <DialogActions>
                        <Button onClick={() => deleteDoc()} color="primary">SI</Button>
                        <Button onClick={() => setOpenDialog(false)} color="primary" autoFocus>NO</Button>
                    </DialogActions>  
                    }
                </Dialog>

                {/* ENVIAR CONSENTIMIENTO */}
                <Dialog open={openDialogEmail} onClose={() => setOpenDialogEmail(false)}>
                    <DialogTitle>ENVIAR CONSENTIMIENTO POR EMAIL</DialogTitle>
                    <DialogContent>
                        <DialogContentText>¿Está seguro de que desea enviar el consentimiento informado?</DialogContentText>
                    </DialogContent>
                    
                    {loadingDoc ? 
                    <Grid container>
                        <CircularProgress style={{ margin: '20px auto' }} />
                    </Grid> :  
                    <DialogActions>
                        <Button onClick={() => sendConsentimiento()} color="primary">SI</Button>
                        <Button onClick={() => setOpenDialogEmail(false)} color="primary" autoFocus>NO</Button>
                    </DialogActions>
                    }
                </Dialog>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </>
            );
    } catch (e: any) {
        const error: ILog = { method: 'Documentos.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }
};

export default Documentos;