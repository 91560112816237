import { ISimpleUser } from "../components/config/Empleados";
import IActividad from "./Actividad";
import IHoliday from "./Holiday";
import IAsistenciaDoc from "./AsistenciaDoc";
import IMail from "./Mail";
import IOutOffice from "./OutOffice";
import IConsentimientoDoc from "./CI";

export default interface IConfig {
    clinicName: string,
    provincia: string,
    clinicCity: string,
    clinicDomicilio: string,
    clinicEmail: string,
    clinicTelefono: string,
    users: ISimpleUser[],
    outoffices: IOutOffice[],
    actividades: IActividad[],
    salas: any,
    holidays: IHoliday[],
    mail: IMail,
    asistenciaDoc: IAsistenciaDoc,
    consentimientoDoc: IConsentimientoDoc
}

export enum CONFIGTYPES {
    clinicName = 'clinicName',
    provincia = 'provincia',
    clinicCity = 'clinicCity',
    users = 'users',
    outoffices = 'outoffices',
    actividades = 'actividades',
    salas = 'salas',
    holidays = 'holidays',
    mail = 'mail',
    asistenciaDoc = 'asistenciaDoc',
    clinicDomicilio = 'clinicDomicilio',
    clinicEmail = 'clinicEmail',
    clinicTelefono = 'clinicTelefono',
    consentimientoDoc = 'consentimientoDoc'
};