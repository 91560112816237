import { Alert, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Snackbar, TextField, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LogService from "../../../../services/LogService";
import ILog from "../../../../types/Log";
import IPaciente from "../../../../types/Paciente";
import { createTheme } from '@mui/material/styles';
import ICita from "../../../../types/Cita";
import AgendaService from "../../../../services/AgendaService";
import AgendaUtils from "../../../../utils/Agenda";
import Utilities from "../../../../common/Utilities";
import EmailIcon from '@mui/icons-material/Email';
import DocUtils from "../../../../utils/Doc";
import IAlert from "../../../../types/Alert";
import ConfigUtils from "../../../../utils/Config";
import * as constantes from "../../../../common/Constantes";

type CitaActualProps = {
    paciente: IPaciente,
    cita: ICita,
    esMenor: boolean
}

const CitaActual = (props: CitaActualProps) => {
    const theme = createTheme();
    const history = useNavigate();

    const [loading, setLoading] = useState(false);
    const [loadingSendMail, setLoadingSendMail] = useState(false);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const [motivo, setMotivo] = useState<string>(props.cita.motivo ? props.cita.motivo : '');
    const [valoracion, setValoracion] = useState<string>(props.cita.valoracion ? props.cita.valoracion : '');
    const [razonamiento, setRazonamiento] = useState<string>(props.cita.razonamiento ? props.cita.razonamiento : '');
    const [tratamiento, setTratamiento] = useState<string>(props.cita.tratamiento ? props.cita.tratamiento : '');

    const [correo] = useState(ConfigUtils.getMail());
    const [asistenciaDoc] = useState(ConfigUtils.getAsistenciaDoc());
    const [justificanteSent, setJustificanteSent] = useState(props.cita.justificanteSent);

    const clear = () => {
        setMotivo(props.cita.motivo ? props.cita.motivo : '');
        setValoracion(props.cita.valoracion ? props.cita.valoracion : '');
        setRazonamiento(props.cita.razonamiento ? props.cita.razonamiento : '');
        setTratamiento(props.cita.tratamiento ? props.cita.tratamiento : '');
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const updateCita = () => {
        if (!props.cita._id) return;
        setLoading(true);

        if (Utilities.isNullEmptyUndefined(motivo) && Utilities.isNullEmptyUndefined(valoracion) && Utilities.isNullEmptyUndefined(razonamiento) && Utilities.isNullEmptyUndefined(tratamiento)) {
            setAlert({ open: true, severity: 'warning', msg: 'No hay datos que guardar' });
            setLoading(false);
            return;
        }

        if (motivo === props.cita.motivo && valoracion === props.cita.valoracion && razonamiento === props.cita.razonamiento && tratamiento === props.cita.tratamiento) {
            setAlert({ open: true, severity: 'warning', msg: 'No hay cambios que guardar' });
            setLoading(false);
            return;
        }

        let updatedCita: ICita = props.cita;
        updatedCita.motivo = motivo;
        updatedCita.valoracion = valoracion;
        updatedCita.razonamiento = razonamiento;
        updatedCita.tratamiento = tratamiento;

        AgendaService.update(props.cita._id, updatedCita, false)
        .then(response => {
            setLoading(false);
            setAlert({ open: true, severity: 'success', msg: 'Datos guardados correctamente' });
        })
        .catch (e => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'CitaActual.Component.updateCita', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                setLoading(false);
            }
        });
    };

    const sendJustificante = () => {

        if (Utilities.isNullEmptyUndefined(props.paciente.email)) {
            setOpenDialog(false);
            setAlert({ open: true, severity: 'warning', msg: 'El paciente no tiene un correo electrónico donde enviar el justificante' });
            setLoading(false);
            return;
        }
        if (props.esMenor) {
            if (Utilities.isNullEmptyUndefined(props.paciente.DNItutor)) {
                setOpenDialog(false);
                setAlert({ open: true, severity: 'warning', msg: 'El tutor del paciente no tiene DNI guardado' });
                setLoading(false);
                return;
            }
        } else {
            if (Utilities.isNullEmptyUndefined(props.paciente.DNI)) {
                setOpenDialog(false);
                setAlert({ open: true, severity: 'warning', msg: 'El paciente no tiene DNI guardado' });
                setLoading(false);
                return;
            }
        }

        setLoadingSendMail(true);
        const justificante = DocUtils.formatJustificanteCita(props.paciente, props.cita, props.esMenor);

        AgendaService.sendJustificante({ email: props.paciente.email, doc: justificante, citaId: props.cita._id })
        .then(response => {
            setAlert({ open: true, severity: 'success', msg: response.data.message });
            setJustificanteSent(true);
        })
        .catch (e => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'CitaActual.Component.sendJustificante', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        })
        .finally(() => {
            setLoadingSendMail(false);
            setOpenDialog(false);
        });
    };

    try {
        return (
            <>
            <Grid item md={1}></Grid>
            <Grid item md={11} xs={12}>
                <Container maxWidth="xl">
                <Paper elevation={3} sx={{ marginTop: theme.spacing(3), padding: theme.spacing(3), display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                        <Grid container>
                            <Grid item md={11} xs ={11}>
                                <Typography variant="h6">{`CITA DE HOY: ${AgendaUtils.formatCitaDateTime(props.cita)}`}&nbsp;</Typography>
                            </Grid>
                            
                            {props.paciente.authEmail && correo && (asistenciaDoc || Utilities.isMoheUser()) && <Grid item md={1} xs={1} sx={{ display: 'grid', justifyContent: 'end' }}>
                                <Tooltip title={justificanteSent ? "VOLVER A ENVIAR JUSTIFICANTE" : "ENVIAR JUSTIFICANTE"} >
                                    <IconButton onClick={() => setOpenDialog(true)}>
                                        <EmailIcon color={justificanteSent ? "success" : "inherit"} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>}

                            <Grid container >
                                <Grid item md={12} xs={12} style={{ margin: 'auto' }}>
                                    <TextField multiline rows={3} value={motivo} onChange={(e) => setMotivo(e.target.value)} variant="outlined" label="Motivo de la consulta" margin="normal" fullWidth autoFocus />
                                    <TextField multiline rows={3} value={valoracion} onChange={(e) => setValoracion(e.target.value)} variant="outlined" label="Valoración" margin="normal" fullWidth />
                                    <TextField multiline rows={3} value={razonamiento} onChange={(e) => setRazonamiento(e.target.value)} variant="outlined" label="Razonamiento clínico" margin="normal" fullWidth />
                                    <TextField multiline rows={3} value={tratamiento} onChange={(e) => setTratamiento(e.target.value)} variant="outlined" label="Tratamiento" margin="normal" fullWidth />
                                </Grid>

                                <Grid style={{ margin: 'auto' }}>
                                { loading ? <CircularProgress /> : 
                                    <>
                                        <Button sx={{ margin: theme.spacing(2, 3, 1, 0) }} variant="contained" color="primary" onClick={updateCita}>Guardar</Button>
                                        <Button sx={{ margin: theme.spacing(2, 3, 1, 0) }} variant="contained" color="secondary" onClick={clear}>Cancelar</Button>
                                    </>
                                }
                                </Grid>
                            </Grid>

                        </Grid>
                </Paper>
                </Container>
            </Grid>

            {/* ENVIAR JUSTIFICANTE */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>ENVIAR JUSTIFICANTE POR EMAIL</DialogTitle>
                <DialogContent>
                    <DialogContentText>¿Está seguro de que desea enviar el justificante de asistencia?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {loadingSendMail ? 
                    <Grid container>
                        <CircularProgress style={{ margin: '10px auto' }} />
                    </Grid> : 
                    <><Button onClick={sendJustificante} color="primary">SI</Button>
                    <Button onClick={() => setOpenDialog(false)} color="primary" autoFocus>NO</Button></>}
                </DialogActions>
            </Dialog>

            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
            </Snackbar>
            </>
        );

    } catch (e: any) {
        const error: ILog = { method: 'CitaActual.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }
};

export default CitaActual;