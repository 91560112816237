import { useState } from "react";
import { useForm } from "react-hook-form";
import { Typography, TextField, Button, CircularProgress, Alert, Snackbar } from "@mui/material";
import IUser from "../../types/User";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AuthService from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import LogService from "../../services/LogService";
import ILog from "../../types/Log";
import { createTheme } from '@mui/material/styles';
import IAlert from "../../types/Alert";
import * as constantes from '../../common/Constantes';

const schema = yup.object().shape({
    email: yup.string().required('El correo electrónico es obligatorio').email('Deber ser un correo electrónico válido'),
    password: yup.string().required('La contraseña es obligatoria').min(6, 'La contraseña debe tener al menos 8 caracteres').matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, 'Debe contener una mayúscula, una minúscula, un número y un carácter especial'),
});

const SignIn = () => {
    const theme = createTheme();
    const history = useNavigate();

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const {register, handleSubmit, formState: { errors }} = useForm<IUser>({ resolver: yupResolver(schema) });

    const onSubmit = (data: IUser) => {
        setLoading(true);
        authUser(data);
    };

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const authUser = (data: IUser) => {
        AuthService.signIn(data)
        .then((response: any) => {
            localStorage.setItem(constantes.LS_profile, JSON.stringify({...response.data[0]}));
            localStorage.setItem(constantes.LS_config, JSON.stringify(response.data[1]));
            setLoading(false);
            history(constantes.R_HOME);
            window.dispatchEvent(new Event('popstate'));
        })
        .catch ((e: any) => {
            const error: ILog = { method: 'SignIn.Component.authUser', level: 'error', message: e.message, meta: e.response };
            LogService.logError(error);
            setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            setLoading(false);
        });
    };

    try {
        return (
            <>
            <Typography variant="h4" sx={{ textAlign: 'center', margin: theme.spacing(1, 0, 1) }}>Iniciar sesión</Typography>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <TextField {...register("email")} variant="outlined" label="Email" margin="normal" fullWidth required autoFocus helperText={errors.email?.message} error={!!errors.email?.message} />
                <TextField {...register("password")} variant="outlined" label="Contraseña" margin="normal" fullWidth required type="password" helperText={errors.password?.message} error={!!errors.password?.message} />
                <Button sx={{ margin: theme.spacing(3, 0, 2) }} variant="contained" color="primary" fullWidth type="submit" >{loading ? <CircularProgress size={25} color="inherit" /> : `Iniciar sesión`}</Button>
            </form>

            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
            </Snackbar>
            </>
        );
    } catch (e: any) {
        const error: ILog = { method: 'SignIn.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }

};

export default SignIn;