import { useState } from "react";
import { Avatar, Container, Paper, Grid, Link, Alert } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import ILog from "../../types/Log";
import LogService from "../../services/LogService";
import { createTheme } from '@mui/material/styles';
import ChangePass from "./ChangePass";

const Auth = () => {
    const theme = createTheme();
    const [isSignup, setIsSignUp] = useState(false);
    const [isChangePass, setIsChangePass] = useState(false);

    const switchMode = () => {
        setIsSignUp(!isSignup);
    };

    try {
        return (
            <Container maxWidth="sm">
                <Paper elevation={3} sx={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3), padding: theme.spacing(3), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar sx={{ margin: theme.spacing(1), backgroundColor: theme.palette.secondary.main }}>
                        <LockIcon />
                    </Avatar>
    
                    { isChangePass ? <ChangePass /> : isSignup ? <SignUp /> : <SignIn />}
    
                    <Grid container>
                        <Grid item xs={6}>
                            <Link sx={{ cursor: 'pointer' }} variant="body2" onClick={() => { setIsChangePass(!isChangePass) }}>{isChangePass ? 'Volver' : '¿Se te olvidó la contraseña?'}</Link>
                        </Grid>
                        <Grid item xs={6}>
                            {!isChangePass && <Link sx={{ cursor: 'pointer' }} onClick={switchMode} variant="body2">{isSignup ? '¿Ya tiene una cuenta? Inicie sesión' : '¿No tiene una cuenta? Regístrese'}</Link>}
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        );
    } catch (e: any) {
        const error: ILog = { method: 'Auth.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }
};

export default Auth;