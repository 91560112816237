import LogService from "../services/LogService";
import ICita from "../types/Cita";
import IEvent from "../types/Event";
import ILog from "../types/Log";
import IPaciente from "../types/Paciente";
import * as constantes from "../common/Constantes";
import ConfigUtils from "./Config";

const formatCitas = (data: ICita[], pacientes: IPaciente[]): IEvent[] => {
    try {
        const formattedCitas: IEvent[] = [];

        data.map(cita => {
            const paciente = pacientes.filter(x => x._id === cita.pacienteId)[0];

            if (!paciente) return null;

            let title;
            if (cita.primeraCita) title = `1º Visita - ${paciente.nombre} ${paciente.apellidos}`;
            else if (cita.asistencia === false)title = `${paciente.nombre} ${paciente.apellidos} - Ausente`;
            else title = `${paciente.nombre} ${paciente.apellidos}`;

            let formattedCita: IEvent = {
                id: cita._id,
                title,
                start: new Date(cita.start),
                end: new Date (cita.end),
                resource: { sala: cita.salaId, especialista: cita.especialistaId, repetirId: cita.repetirId, asistencia: cita.asistencia },
                pacienteId: paciente._id
            };
            formattedCitas.push(formattedCita);
            return formattedCita;
        });
        return formattedCitas;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Agenda.formatCitas', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return [];
    }
};

const formatEvent = (event: IEvent): ICita | undefined => {
    try {
        const formattedEvent: ICita = {
            _id: event.id?.toString(),
            pacienteId: event.pacienteId ? event.pacienteId : '',
            especialistaId: event.resource.especialista,
            salaId: event.resource.sala,
            start: event.start,
            end: event.end,
            repetirId: event.resource.repetirId,
            pacienteNombre: event.title,
            motivo: event.motivo,
            valoracion: event.valoracion,
            razonamiento: event.razonamiento,
            tratamiento: event.tratamiento,
            userId: ConfigUtils.getUserId(),
            asistencia: event.resource.asistencia
        };
        return formattedEvent;

    } catch (e: any) {
        const error: ILog = { method: 'Utils.Agenda.formatEvent', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const formatTimeEvent = (event: IEvent | undefined) => {
    try {
        if (event === undefined) return;

        var d = new Date(event.start),
        hoursStart = '' + (d.getHours()),
        minutesStart = '' + d.getMinutes();
    
        var d2 = new Date(event.end),
        hoursEnd = '' + (d2.getHours()),
        minutesEnd = '' + d2.getMinutes();
    
        if (minutesStart.length < 2)
            minutesStart = '0' + minutesStart;
    
        if (minutesEnd.length < 2)
            minutesEnd = '0' + minutesEnd;
    
        if (event.allDay) {
            return "Todo el día";  
        } else {
            return `de ${hoursStart}:${minutesStart} a ${hoursEnd}:${minutesEnd}`;
        }
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Agenda.formatTimeEvent', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const formatDateEvent = (event: IEvent | undefined) => {
    try {
        if (event === undefined) return;

        var d = new Date(event.start),
        dayWeek = '' + d.getDay(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    
        if (event.allDay) {
            return `${constantes.weekNames[parseInt(dayWeek) - 1]}, ${day} de ${constantes.monthNames[parseInt(month) - 1]} de ${year}`;  
        } else {
            return `${constantes.weekNames[parseInt(dayWeek) - 1]}, ${day} de ${constantes.monthNames[parseInt(month) - 1]} de ${year}`;
        }
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Agenda.formatDateEvent', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const formatCitaDate = (cita: ICita) => {
    try {
        var d = new Date(cita.start),
        dayWeek = '' + d.getDay(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    
        return `${constantes.weekNames[parseInt(dayWeek) - 1]}, ${day} de ${constantes.monthNames[parseInt(month) - 1]} de ${year}`;
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Agenda.formatCitaDate', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const formatCitaTime = (cita: ICita) => {
    try {
        var d = new Date(cita.start),
        hoursStart = '' + (d.getHours()),
        minutesStart = '' + d.getMinutes();
    
        var d2 = new Date(cita.end),
        hoursEnd = '' + (d2.getHours()),
        minutesEnd = '' + d2.getMinutes();
    
        if (minutesStart.length < 2)
            minutesStart = '0' + minutesStart;
    
        if (minutesEnd.length < 2)
            minutesEnd = '0' + minutesEnd;
    
        return `de ${hoursStart}:${minutesStart} a ${hoursEnd}:${minutesEnd}`;
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Agenda.formatCitaTime', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const formatCitaDateTime = (cita: ICita) => {
    try {
        var d = new Date(cita.start),
        dayWeek = '' + d.getDay(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        hoursStart = '' + (d.getHours()),
        minutesStart = '' + d.getMinutes();

        if (minutesStart.length < 2)
            minutesStart = '0' + minutesStart;
    
        return `${constantes.weekNames[parseInt(dayWeek) - 1]} ${day} de ${constantes.monthNames[parseInt(month) - 1]} a las ${hoursStart}:${minutesStart}`;
    } catch (e: any) {
        const error: ILog = { method: 'Utils.Agenda.formatCitaDateTime', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
    }
};

const AgendaUtils = {
    formatCitas,
    formatTimeEvent,
    formatDateEvent,
    formatEvent,
    formatCitaDate,
    formatCitaTime,
    formatCitaDateTime
};

export default AgendaUtils;