import { useState } from "react";
import { Typography, Paper, Container, Grid, Tabs, Tab, Alert, TextField, InputAdornment, IconButton, CircularProgress, Snackbar, Button, Stack, Chip, Dialog, DialogContent, Avatar, MenuItem, DialogActions, DialogTitle, DialogContentText, Box, Card, CardHeader, CardContent, FormControl, InputLabel, Select, SelectChangeEvent, ButtonBase, ListItemIcon, ListItemText } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { TabContext, TabPanel } from "@mui/lab";
import ILog from "../../types/Log";
import LogService from "../../services/LogService";
import Empleados from "./Empleados";
import ConfigUtils from "../../utils/Config";
import EditIcon from '@mui/icons-material/Edit';
import OkIcon from '@mui/icons-material/CheckCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import Utilities from "../../common/Utilities";
import IAlert from "../../types/Alert";
import AuthService from "../../services/AuthService";
import { CONFIGTYPES } from "../../types/Config";
import { useNavigate } from "react-router-dom";
import IActividad from "../../types/Actividad";
import * as constantes from "../../common/Constantes";
import ConfigService from "../../services/ConfigService";
import ISala from "../../types/Sala";
import Holidays from "./Holidays";
import { isMobile } from "react-device-detect";
import Mail from "./Mail";
import JA from "./JA";
import CI from "./CI";

import WavesIcon from '@mui/icons-material/Waves';
import LandscapeIcon from '@mui/icons-material/Landscape';
import DiamondIcon from '@mui/icons-material/Diamond';
import GrassIcon from '@mui/icons-material/Grass';
import AcUnitIcon from '@mui/icons-material/AcUnit';

interface ConfigProps {
    selectedTab?: string;
    alert?: IAlert;
}

const Config = (props: ConfigProps) => {
    const theme = createTheme();
    const history = useNavigate();

    const [alert, setAlert] = useState<IAlert>(props.alert ? props.alert : { open: false, severity: 'info', msg: '' });
    const [value, setValue] = useState<string>(props.selectedTab ? props.selectedTab : "1");
    const [clinicName, setClinicName] = useState<string>(ConfigUtils.getClinicName());
    const [editClinicName, setEditClinicName] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clinicCity, setClinicCity] = useState<string>(ConfigUtils.getClinicCity());
    const [editClinicCity, setEditClinicCity] = useState(false);
    const [clinicDomicilio, setClinicDomicilio] = useState<string>(ConfigUtils.getClinicDomicilio());
    const [editClinicDomicilio, setEditClinicDomicilio] = useState(false);
    const [clinicEmail, setClinicEmail] = useState<string>(ConfigUtils.getClinicEmail());
    const [editClinicEmail, setEditClinicEmail] = useState(false);
    const [clinicTelefono, setClinicTelefono] = useState<string>(ConfigUtils.getClinicTelefono());
    const [editClinicTelefono, setEditClinicTelefono] = useState(false);

    const [loadingProvincia, setLoadingProvincia] = useState(false);
    const [provincia, setProvincia] = useState<string>(ConfigUtils.getProvincia());
    const [editProvincia, setEditProvincia] = useState(false);
    const [modalEditProvincia, setModalEditProvincia] = useState(false);

    //ACTIVIDADES
    const [actividades, setActividades] = useState<Array<IActividad>>(ConfigUtils.getActividades());
    const [modalActividad, setModalActividad] = useState(false);
    const [loadingActividad, setLoadingActividad] = useState(false);
    const [actividadName, setActividadName] = useState<string>('');
    const [duracion, setDuracion] = useState<number>(60);
    const [capacidad, setCapacidad] = useState<number>(1);
    const [isEditActividad, setIsEditActividad] = useState(false);
    const [modalDeleteActividad, setModalDeleteActividad] = useState(false);
    const [selectedActividad, setSelectedActividad] = useState<IActividad>();

    //SALAS
    const [salas, setSalas] = useState<Array<ISala>>(ConfigUtils.getSalas());
    const [modalSala, setModalSala] = useState(props.selectedTab === "2" ? true : false);
    const [loadingSala, setLoadingSala] = useState(false);
    const [salaName, setSalaName] = useState<string>('');
    const [salaActs, setSalaActs] = useState<Array<string>>([]);
    const [salaIcon, setSalaIcon] = useState<string>('');
    const [isEditSala, setIsEditSala] = useState(false);
    const [modalDeleteSala, setModalDeleteSala] = useState(false);
    const [selectedSala, setSelectedSala] = useState<ISala>();

    const userRole: number = ConfigUtils.getUserRole();

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const saveClinicName = () => {
        setLoading(true);
        if (Utilities.isNullEmptyUndefined(clinicName)) {
            setAlert({ open: true, severity: 'warning', msg: 'El nombre de la clínica está vacío' });
            setLoading(false);
            return;
        }

        AuthService.updateClinicName(ConfigUtils.getUserId(), { clinicName })
            .then((response: any) => {
                ConfigUtils.editConfig(CONFIGTYPES.clinicName.toString(), clinicName);
                setAlert({ open: true, severity: 'success', msg: response.data.message });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Config.Component.saveClinicName', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoading(false);
                setEditClinicName(false);
            });
    };

    const saveClinicCity = () => {   
        if (Utilities.isNullEmptyUndefined(clinicCity)) {
            setAlert({ open: true, severity: 'warning', msg: 'La ciudad de la clínica está vacía' });
            return;
        }
        setLoading(true);
        AuthService.updateClinicCity(ConfigUtils.getUserId(), { clinicCity })
            .then((response: any) => {
                ConfigUtils.editConfig(CONFIGTYPES.clinicCity.toString(), clinicCity);
                setAlert({ open: true, severity: 'success', msg: response.data.message });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Config.Component.saveClinicCity', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoading(false);
                setEditClinicCity(false);
            });
    };

    const saveClinicDomicilio = () => {
        setLoading(true);
        if (Utilities.isNullEmptyUndefined(clinicDomicilio)) {
            setAlert({ open: true, severity: 'warning', msg: 'La dirección de la clínica está vacía' });
            setLoading(false);
            return;
        }

        AuthService.updateClinicDomicilio(ConfigUtils.getUserId(), { clinicDomicilio })
            .then((response: any) => {
                ConfigUtils.editConfig(CONFIGTYPES.clinicDomicilio.toString(), clinicDomicilio);
                setAlert({ open: true, severity: 'success', msg: response.data.message });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Config.Component.saveClinicDomicilio', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoading(false);
                setEditClinicDomicilio(false);
            });
    };

    const saveClinicEmail = () => {
        setLoading(true);
        if (Utilities.isNullEmptyUndefined(clinicEmail)) {
            setAlert({ open: true, severity: 'warning', msg: 'El email de la clínica está vacío' });
            setLoading(false);
            return;
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(clinicEmail)) {
            setAlert({ open: true, severity: 'warning', msg: 'El email no tiene un formato válido' });
            setLoading(false);
            return;
        }

        AuthService.updateClinicEmail(ConfigUtils.getUserId(), { clinicEmail })
            .then((response: any) => {
                ConfigUtils.editConfig(CONFIGTYPES.clinicEmail.toString(), clinicEmail);
                setAlert({ open: true, severity: 'success', msg: response.data.message });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Config.Component.saveClinicEmail', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoading(false);
                setEditClinicEmail(false);
            });
    };

    const saveClinicTelefono = () => {
        setLoading(true);
        if (Utilities.isNullEmptyUndefined(clinicTelefono)) {
            setAlert({ open: true, severity: 'warning', msg: 'El teléfono de la clínica está vacío' });
            setLoading(false);
            return;
        }

        if (!/^\d{9}$/.test(clinicTelefono)) {
            setAlert({ open: true, severity: 'warning', msg: 'El teléfono no tiene un formato válido' });
            setLoading(false);
            return;
        }

        AuthService.updateClinicTelefono(ConfigUtils.getUserId(), { clinicTelefono })
        .then((response: any) => {
            ConfigUtils.editConfig(CONFIGTYPES.clinicTelefono.toString(), clinicTelefono);
            setAlert({ open: true, severity: 'success', msg: response.data.message });
        })
        .catch((e: any) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'Config.Component.saveClinicTelefono', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        })
        .finally(() => {
            setLoading(false);
            setEditClinicTelefono(false);
        });
    };

    const saveProvincia = () => {
        setModalEditProvincia(false);
        setLoadingProvincia(true);

        AuthService.updateProvincia(ConfigUtils.getUserId(), { provincia })
            .then((response: any) => {
                ConfigUtils.editConfig(CONFIGTYPES.provincia.toString(), provincia);
                ConfigUtils.deleteConfig(CONFIGTYPES.holidays.toString(), null);
                setAlert({ open: true, severity: 'success', msg: response.data.message });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Config.Component.saveProvincia', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoadingProvincia(false);
                setEditProvincia(false);
            });
    };

    const closeModalActividad = () => {
        setActividadName('');
        setDuracion(60);
        setCapacidad(1);
        setSelectedActividad(undefined);
        setModalActividad(false);
    };

    const setIsUpdateActividad = (actividad: IActividad) => {
        setIsEditActividad(true);
        setSelectedActividad(actividad);
        setActividadName(actividad.title);
        setDuracion(actividad.duracion);
        setCapacidad(actividad.capacity);
        setModalActividad(true);
    };

    const saveActividad = () => {
        setLoadingActividad(true);

        if (Utilities.isNullEmptyUndefined(actividadName)) {
            setAlert({ open: true, severity: 'warning', msg: 'Es necesario poner un nombre a la actividad' });
            setLoadingActividad(false);
            return;
        }

        const act: IActividad = {
            title: actividadName,
            duracion,
            capacity: capacidad,
            userId: ConfigUtils.getUserId()
        };

        ConfigService.createActividad(act)
            .then((response: any) => {
                const newActividad: IActividad = response.data;
                ConfigUtils.addConfig(CONFIGTYPES.actividades.toString(), newActividad);
                const newActividades: IActividad[] = [...actividades, newActividad];
                setActividades(newActividades);
                setAlert({ open: true, severity: 'success', msg: 'Actividad guardada correctamente' });
                window.dispatchEvent(new Event('popstate'));
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Config.Component.saveActividad', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoadingActividad(false);
                closeModalActividad();
            });
    };

    const updateActividad = () => {
        if (!selectedActividad || !selectedActividad._id) return;

        setLoadingActividad(true);

        const updatedActividad: IActividad = {
            userId: selectedActividad?.userId,
            title: actividadName,
            duracion,
            capacity: capacidad
        };

        ConfigService.updateActividad(selectedActividad._id, updatedActividad)
            .then((response: any) => {
                const newActividades: IActividad[] = [response.data, ...actividades.filter((e) => e._id !== response.data._id)];
                setActividades(newActividades);
                ConfigUtils.editConfig(CONFIGTYPES.actividades.toString(), response.data);
                setAlert({ open: true, severity: 'success', msg: 'Actividad actualizada correctamente' });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Config.Component.updateOutOffice', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoadingActividad(false);
                closeModalActividad();
            });
    };

    const deleteActividad = () => {
        if (!selectedActividad || !selectedActividad._id) return;

        ConfigService.deleteActividad(selectedActividad?._id)
            .then((response: any) => {
                const newActividades: IActividad[] = [...actividades.filter((e) => e._id !== selectedActividad._id)];
                setActividades(newActividades);
                const newSalas = ConfigUtils.updateSalas(salas, selectedActividad?._id);
                setSalas(newSalas);
                ConfigUtils.deleteConfig(CONFIGTYPES.actividades.toString(), selectedActividad._id);
                ConfigUtils.editConfig(CONFIGTYPES.salas.toString(), newSalas);
                setAlert({ open: true, severity: 'success', msg: 'Actividad eliminada correctamente' });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Config.Component.deleteActividad', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setSelectedActividad(undefined);
                setModalDeleteActividad(false);
            });
    };

    const closeModalSala = () => {
        setSalaName('');
        setSalaIcon('');
        setSalaActs([]);
        setSelectedSala(undefined);
        setModalSala(false);
    };

    const setIsUpdateSala = (sala: ISala) => {
        setIsEditSala(true);
        setSelectedSala(sala);
        setSalaName(sala.title);
        setSalaIcon(sala.icon != undefined ? sala.icon : "");
        setSalaActs(sala.actividades);
        setModalSala(true);
    };

    const handleChangeSala = (event: SelectChangeEvent<typeof salaActs>) => {
        const { target: { value }, } = event;
        setSalaActs(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

    };

    const handleChangeIcon = (event: SelectChangeEvent) => {
        setSalaIcon(event.target.value);
    };

    const saveSala = () => {
        setLoadingSala(true);

        if (Utilities.isNullEmptyUndefined(salaName)) {
            setAlert({ open: true, severity: 'warning', msg: 'Es necesario poner un nombre a la sala' });
            setLoadingSala(false);
            return;
        }

        const sala: ISala = {
            title: salaName,
            icon: salaIcon,
            actividades: salaActs,
            userId: ConfigUtils.getUserId()
        };

        ConfigService.createSala(sala)
            .then((response: any) => {
                const newSala: ISala = response.data;
                ConfigUtils.addConfig(CONFIGTYPES.salas.toString(), newSala);
                const newSalas: ISala[] = [...salas, newSala];
                setSalas(newSalas);
                setAlert({ open: true, severity: 'success', msg: 'Sala guardada correctamente' });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Config.Component.saveSala', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoadingSala(false);
                closeModalSala();
            });
    };

    const updateSala = () => {
        if (!selectedSala || !selectedSala._id) return;

        setLoadingSala(true);

        const updatedSala: ISala = {
            userId: selectedSala?.userId,
            title: salaName,
            icon: salaIcon,
            actividades: salaActs
        };

        ConfigService.updateSala(selectedSala._id, updatedSala)
            .then((response: any) => {
                const newSalas: ISala[] = [response.data, ...salas.filter((e) => e._id !== response.data._id)];
                setSalas(newSalas);
                ConfigUtils.editConfig(CONFIGTYPES.salas.toString(), response.data);
                setAlert({ open: true, severity: 'success', msg: 'Sala actualizada correctamente' });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Config.Component.updateSala', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoadingSala(false);
                closeModalSala();
            });

    };

    const deleteSala = () => {
        if (!selectedSala || !selectedSala._id) return;

        ConfigService.deleteSala(selectedSala?._id)
            .then((response: any) => {
                const newSalas: ISala[] = [...salas.filter((e) => e._id !== selectedSala._id)];
                setSalas(newSalas);
                ConfigUtils.deleteConfig(CONFIGTYPES.salas.toString(), selectedSala._id);
                setAlert({ open: true, severity: 'success', msg: 'Sala eliminada correctamente' });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Config.Component.deleteSala', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setSelectedSala(undefined);
                setModalDeleteSala(false);
            });
    };

    const closeModalProvincia = () => {
        setEditProvincia(false);
        setProvincia(ConfigUtils.getProvincia());
        setModalEditProvincia(false);
    };

    try {
        return (
            userRole == constantes.ROLES.ADMINISTRADOR ?
            <Grid>
                <Grid container>
                    <Grid item md={12}>
                        <Container>
                            <Paper elevation={3} sx={{ marginTop: theme.spacing(3), padding: theme.spacing(3), marginBottom: theme.spacing(3) }}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h4" sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1), fontWeight: 'bold' }}>CONFIGURACIÓN</Typography>
                                </Grid>

                                <TabContext value={value}>
                                    <Tabs variant="scrollable" value={value} onChange={handleChange}>
                                        <Tab label="Usuarios" value="1" />
                                        <Tab label="Clínica" value="2" />
                                        <Tab label="Festivos" value="3" />
                                        {Utilities.isMoheUser() && <Tab label="Correo" value="4" />}
                                        {!Utilities.isMoheUser() && false && <Tab label={isMobile ? "J.A." : "Justificante asistencia"} value="5" />}
                                        <Tab label={isMobile ? "C.I." : "Consentimiento informado"} value="6" />
                                    </Tabs>
                                    <TabPanel value="1">
                                        <Empleados />
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <Grid container spacing={2} sx={{ minWidth: '680px' }}>
                                            {loading ? <Grid container><CircularProgress style={{ margin: '20px auto' }} /></Grid> :
                                                <Grid item md={3} xs={9}>
                                                    <TextField fullWidth sx={{ ml: theme.spacing(3) }} value={clinicName} onChange={(e) => setClinicName(e.target.value)} variant="outlined" label="Nombre de la clínica" margin="normal" inputProps={{ readOnly: !editClinicName }} InputProps={{ startAdornment: (editClinicName ? <InputAdornment position="start"><IconButton onClick={saveClinicName}><OkIcon titleAccess="Guardar nombre de clínica" /></IconButton></InputAdornment> : <InputAdornment position="start"><IconButton onClick={() => { setEditClinicName(!editClinicName) }}><EditIcon titleAccess="Editar nombre de clínica" /></IconButton></InputAdornment>) }} />
                                                </Grid>}
                                            {loadingProvincia ? <Grid container><CircularProgress style={{ margin: '20px auto' }} /></Grid> :
                                                <Grid item md={3} xs={9}>
                                                    <TextField select fullWidth sx={{ ml: theme.spacing(3) }} value={provincia} onChange={(e) => setProvincia(e.target.value)} variant="outlined" label="Provincia" margin="normal" inputProps={{ readOnly: !editProvincia }} InputProps={{ startAdornment: (editProvincia ? <InputAdornment position="start"><IconButton onClick={() => setModalEditProvincia(true)}><OkIcon titleAccess="Guardar provincia" /></IconButton></InputAdornment> : <InputAdornment position="start"><IconButton onClick={() => { setEditProvincia(!editProvincia) }}><EditIcon titleAccess="Editar provincia" /></IconButton></InputAdornment>) }} >
                                                        {
                                                            constantes.provincias.map((provincia: any, index: number) => {
                                                                return (
                                                                    <MenuItem key={index} value={provincia.id}>{provincia.name}</MenuItem>
                                                                );
                                                            })
                                                        }
                                                    </TextField>
                                                </Grid>}
                                            {loading ? <Grid container><CircularProgress style={{ margin: '20px auto' }} /></Grid> :
                                                <Grid item md={3} xs={9}>
                                                    <TextField fullWidth sx={{ ml: theme.spacing(3) }} value={clinicCity} onChange={(e) => setClinicCity(e.target.value)} variant="outlined" label="Ciudad" margin="normal" inputProps={{ readOnly: !editClinicCity }} InputProps={{ startAdornment: (editClinicCity ? <InputAdornment position="start"><IconButton onClick={saveClinicCity}><OkIcon titleAccess="Guardar ciudad" /></IconButton></InputAdornment> : <InputAdornment position="start"><IconButton onClick={() => { setEditClinicCity(!editClinicCity) }}><EditIcon titleAccess="Editar ciudad" /></IconButton></InputAdornment>) }} />
                                                </Grid>}

                                                <Grid item md={3} xs={9}></Grid>

                                            {loading ? <Grid container><CircularProgress style={{ margin: '20px auto' }} /></Grid> :
                                                <Grid item md={3} xs={9}>
                                                    <TextField fullWidth sx={{ ml: theme.spacing(3) }} value={clinicDomicilio} onChange={(e) => setClinicDomicilio(e.target.value)} variant="outlined" label="Dirección de la clínica" margin="normal" inputProps={{ readOnly: !editClinicDomicilio }} InputProps={{ startAdornment: (editClinicDomicilio ? <InputAdornment position="start"><IconButton onClick={saveClinicDomicilio}><OkIcon titleAccess="Guardar dirección de clínica" /></IconButton></InputAdornment> : <InputAdornment position="start"><IconButton onClick={() => { setEditClinicDomicilio(!editClinicDomicilio) }}><EditIcon titleAccess="Editar dirección de clínica" /></IconButton></InputAdornment>) }} />
                                                </Grid>}
                                            {loading ? <Grid container><CircularProgress style={{ margin: '20px auto' }} /></Grid> :
                                                <Grid item md={3} xs={9}>
                                                    <TextField fullWidth sx={{ ml: theme.spacing(3) }} value={clinicEmail} onChange={(e) => setClinicEmail(e.target.value)} variant="outlined" label="Email de la clínica" margin="normal" inputProps={{ readOnly: !editClinicEmail }} InputProps={{ startAdornment: (editClinicEmail ? <InputAdornment position="start"><IconButton onClick={saveClinicEmail}><OkIcon titleAccess="Guardar email de clínica" /></IconButton></InputAdornment> : <InputAdornment position="start"><IconButton onClick={() => { setEditClinicEmail(!editClinicEmail) }}><EditIcon titleAccess="Editar email de clínica" /></IconButton></InputAdornment>) }} />
                                                </Grid>}
                                            {loading ? <Grid container><CircularProgress style={{ margin: '20px auto' }} /></Grid> :
                                                <Grid item md={3} xs={9}>
                                                    <TextField fullWidth sx={{ ml: theme.spacing(3) }} value={clinicTelefono} onChange={(e) => setClinicTelefono(e.target.value)} variant="outlined" label="Teléfono de la clínica" margin="normal" inputProps={{ readOnly: !editClinicTelefono }} InputProps={{ startAdornment: (editClinicTelefono ? <InputAdornment position="start"><IconButton onClick={saveClinicTelefono}><OkIcon titleAccess="Guardar teléfono de clínica" /></IconButton></InputAdornment> : <InputAdornment position="start"><IconButton onClick={() => { setEditClinicTelefono(!editClinicTelefono) }}><EditIcon titleAccess="Editar teléfono de clínica" /></IconButton></InputAdornment>) }} />
                                                </Grid>}

                                            <Grid item md={12} xs={12}>
                                                <Grid>
                                                    <Grid container>
                                                        <Grid item md={12}>
                                                            <Container>
                                                                <Grid>

                                                                    <Grid container spacing={2}>
                                                                        <Grid item md={12}>
                                                                            <Button onClick={() => setModalActividad(true)} sx={{ backgroundColor: '#63ad3c', "&:hover": { backgroundColor: '#7dc557' } }} variant="contained">
                                                                                <Typography variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', color: 'white' }}>Añadir actividad</Typography>
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>

                                                                    {actividades.length === 0 ? <div>No se han encontrado actividades</div> :
                                                                        <Stack direction="row" spacing={2} sx={{ mt: theme.spacing(2) }}>

                                                                            {actividades.map((actividad: IActividad) => {
                                                                                return (
                                                                                    <Chip key={actividad._id} label={actividad.title} variant="outlined" onClick={() => setIsUpdateActividad(actividad)} /> //onDelete={() => { setSelectedActividad(actividad); setModalDeleteActividad(true) }}
                                                                                );
                                                                            })}
                                                                        </Stack>
                                                                    }
                                                                </Grid>
                                                            </Container>
                                                        </Grid>
                                                    </Grid>

                                                    {/* AÑADIR ACTIVIDAD */}
                                                    <Dialog open={modalActividad} maxWidth="lg" fullWidth onClose={closeModalActividad}>
                                                        <DialogContent>
                                                            <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                                                                <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><AddIcon /></Avatar><div style={{ margin: '10px auto' }}>{isEditActividad ? 'Editar actividad' : 'Nueva actividad'}</div>
                                                            </Typography>

                                                            <Grid container spacing={1}>
                                                                <Grid item md={4} xs={12}>
                                                                    <TextField value={actividadName} onChange={(e) => setActividadName(e.target.value)} variant="outlined" label="Nombre de la actividad" margin="normal" fullWidth autoFocus required />
                                                                </Grid>
                                                                <Grid item md={4} xs={12}>
                                                                    <TextField select value={duracion} variant="outlined" label="Duración" margin="normal" fullWidth required onChange={(e) => setDuracion(parseInt(e.target.value))} >
                                                                        {
                                                                            constantes.duracion.map((item, index) => {
                                                                                return (
                                                                                    <MenuItem key={index} value={item}>{`${item.toString()} minutos`}</MenuItem>
                                                                                );
                                                                            })
                                                                        }
                                                                    </TextField>
                                                                </Grid>
                                                                <Grid item md={4} xs={12}>
                                                                    <TextField select value={capacidad} variant="outlined" label="Capacidad" margin="normal" fullWidth required onChange={(e) => setCapacidad(parseInt(e.target.value))} >
                                                                        {
                                                                            constantes.capacidad.map((item, index) => {
                                                                                return (
                                                                                    <MenuItem key={index} value={item}>{`${item.toString()} persona(s)`}</MenuItem>
                                                                                );
                                                                            })
                                                                        }
                                                                    </TextField>
                                                                </Grid>
                                                            </Grid>
                                                        </DialogContent>
                                                        {loadingActividad ?
                                                            <Grid container>
                                                                <CircularProgress style={{ margin: '20px auto' }} />
                                                            </Grid> :
                                                            <DialogActions>
                                                                { isEditActividad && false && <Button color="error" onClick={() => setModalDeleteActividad(true)}>Eliminar</Button>}
                                                                <Button onClick={isEditActividad ? updateActividad : saveActividad}>Guardar</Button>
                                                                <Button onClick={closeModalActividad}>Cancelar</Button>
                                                            </DialogActions>}
                                                    </Dialog>

                                                    {/* ELIMINAR ACTIVIDAD */}
                                                    <Dialog open={modalDeleteActividad} maxWidth="lg" onClose={() => setModalDeleteActividad(false)}>
                                                        <DialogTitle>ELIMINAR ACTIVIDAD</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText>{`¿Está seguro de que desea eliminar la actividad ${selectedActividad?.title}?`}</DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={deleteActividad} color="primary">SI</Button>
                                                            <Button onClick={() => { setModalDeleteActividad(false); setSelectedActividad(undefined) }} color="primary" autoFocus>NO</Button>
                                                        </DialogActions>

                                                    </Dialog>
                                                </Grid>

                                                <Grid>
                                                    <Grid container>
                                                        <Grid item md={12}>
                                                            <Container>
                                                                <Grid>

                                                                    <Grid container spacing={2}>
                                                                        <Grid item md={12}>
                                                                            <Button onClick={() => setModalSala(true)} sx={{ mt: theme.spacing(3), backgroundColor: '#63ad3c', "&:hover": { backgroundColor: '#7dc557' } }} variant="contained">
                                                                                <Typography variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', color: 'white' }}>Añadir sala</Typography>
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>

                                                                    {salas.length === 0 ? <div>No se han encontrado salas</div> :

                                                                        <Grid container direction="row" spacing={2}>

                                                                            {salas.map((sala: ISala, index: number) => {
                                                                                let icon;
                                                                                switch (sala.icon) {
                                                                                    case "0":
                                                                                        icon = <WavesIcon />;
                                                                                        break;
                                                                                    case "1":
                                                                                        icon = <DiamondIcon />;
                                                                                        break;
                                                                                    case "2":
                                                                                        icon = <LandscapeIcon />;
                                                                                        break;
                                                                                    case "3":
                                                                                        icon = <GrassIcon />;
                                                                                        break;
                                                                                    case "4":
                                                                                        icon = <AcUnitIcon />;
                                                                                        break;
                                                                                    default:
                                                                                        break;
                                                                                }
                                                                                return (
                                                                                    <Grid item key={index}>

                                                                                        <Box sx={{ mt: theme.spacing(2) }}>
                                                                                            <Card elevation={2}>
                                                                                                <ButtonBase onClick={() => setIsUpdateSala(sala)} sx={{ backgroundColor: 'rgba(0, 0, 0, 0)', "&:hover": { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}>
                                                                                                    <CardHeader avatar={icon} title={sala.title} />
                                                                                                    <CardContent>
                                                                                                        <Stack direction="row" spacing={2}>
                                                                                                            {
                                                                                                                sala.actividades && sala.actividades.length !== 0 && sala.actividades.map((actividad: string) => {
                                                                                                                    return (<Chip key={actividad} label={actividades.filter(a => a._id === actividad)[0]?.title} variant="outlined" />);
                                                                                                                })
                                                                                                            }
                                                                                                        </Stack>
                                                                                                    </CardContent>
                                                                                                </ButtonBase>
                                                                                            </Card>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                );
                                                                            })}

                                                                        </Grid>

                                                                    }
                                                                </Grid>
                                                            </Container>
                                                        </Grid>
                                                    </Grid>

                                                    {/* AÑADIR SALA */}
                                                    <Dialog open={modalSala} maxWidth="lg" fullWidth onClose={closeModalSala}>
                                                        <DialogContent>
                                                            <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                                                                <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><AddIcon /></Avatar><div style={{ margin: '10px auto' }}>{isEditSala ? 'Editar sala' : 'Nueva sala'}</div>
                                                            </Typography>

                                                            <Grid container spacing={1}>
                                                                <Grid item md={4} xs={12}>
                                                                    <TextField value={salaName} onChange={(e) => setSalaName(e.target.value)} variant="outlined" label="Nombre de la sala" margin="normal" fullWidth autoFocus required />
                                                                </Grid>
                                                                <Grid item md={3} xs={12}>
                                                                    <FormControl>
                                                                        <InputLabel id="icon">Selecciona un icono</InputLabel>
                                                                        <Select value={constantes.icons[parseInt(salaIcon)]} onChange={handleChangeIcon} sx={{ mt: theme.spacing(2), width: "250px" }} required renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected}</Box>)}>
                                                                            <MenuItem value="0">
                                                                                <ListItemIcon>
                                                                                    <WavesIcon />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Agua</ListItemText>
                                                                            </MenuItem>
                                                                            <MenuItem value="1">
                                                                                <ListItemIcon>
                                                                                    <DiamondIcon />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Diamante</ListItemText>
                                                                            </MenuItem>
                                                                            <MenuItem value="2">
                                                                                <ListItemIcon>
                                                                                    <LandscapeIcon />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Montaña</ListItemText>
                                                                            </MenuItem>
                                                                            <MenuItem value="3">
                                                                                <ListItemIcon>
                                                                                    <GrassIcon />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Planta</ListItemText>
                                                                            </MenuItem>
                                                                            <MenuItem value="4">
                                                                                <ListItemIcon>
                                                                                    <AcUnitIcon />
                                                                                </ListItemIcon>
                                                                                <ListItemText>Nieve</ListItemText>
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item md={5} xs={12}>
                                                                    {actividades.length !== 0 && <FormControl>
                                                                        <InputLabel id="acts">Actividades</InputLabel>
                                                                        <Select label="Actividades" labelId="acts" sx={{ mt: theme.spacing(2), minWidth: '200px', width: 'fit-content' }} multiple value={salaActs} onChange={handleChangeSala} renderValue={(selected) => (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>  {selected.map((value) => (<Chip key={value} label={actividades.filter(a => a._id === value)[0].title} />))}</Box>)}>
                                                                            {
                                                                                actividades.map((item, index) => {
                                                                                    return (
                                                                                        <MenuItem key={index} value={item._id ? item._id : undefined}>{item.title.toString()}</MenuItem>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </FormControl>}
                                                                </Grid>
                                                            </Grid>
                                                        </DialogContent>
                                                        {loadingSala ?
                                                            <Grid container>
                                                                <CircularProgress style={{ margin: '20px auto' }} />
                                                            </Grid> :
                                                            <DialogActions>
                                                                { isEditSala && false &&  <Button color="error" onClick={() => setModalDeleteSala(true)}>Eliminar</Button>}
                                                                <Button onClick={isEditSala ? updateSala : saveSala}>Guardar</Button>
                                                                <Button onClick={closeModalSala}>Cancelar</Button>
                                                            </DialogActions>}
                                                    </Dialog>

                                                    {/* ELIMINAR SALA */}
                                                    <Dialog open={modalDeleteSala} maxWidth="lg" onClose={() => setModalDeleteSala(false)}>
                                                        <DialogTitle>ELIMINAR SALA</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText>{`¿Está seguro de que desea eliminar la sala ${selectedSala?.title}?`}</DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={deleteSala} color="primary">SI</Button>
                                                            <Button onClick={() => { setModalDeleteSala(false); setSelectedSala(undefined) }} color="primary" autoFocus>NO</Button>
                                                        </DialogActions>

                                                    </Dialog>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value="3">
                                        <Holidays />
                                    </TabPanel>
                                    <TabPanel value="4">
                                        <Mail />
                                    </TabPanel>
                                    {!Utilities.isMoheUser() && <TabPanel value="5">
                                        <JA />
                                    </TabPanel>}
                                    <TabPanel value="6">
                                        <CI />         
                                    </TabPanel>

                                </TabContext>

                            </Paper>
                        </Container>
                    </Grid>

                </Grid>

                {/* CONFIRMACIÓN CAMBIO DE PROVINCIA */}
                <Dialog open={modalEditProvincia} maxWidth="lg" onClose={closeModalProvincia}>
                    <DialogTitle>MODIFICAR PROVINCIA</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Al modificar la provincia, se modificarán también los festivos. ¿Está seguro de que desea modificar la provincia?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={saveProvincia} color="primary">SI</Button>
                        <Button onClick={closeModalProvincia} color="primary" autoFocus>NO</Button>
                    </DialogActions>

                </Dialog>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </Grid>
            :
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open>
                <Alert severity="warning" sx={{ width: '100%' }} >Usuario no autorizado</Alert>
            </Snackbar>
            
        );
    } catch (e: any) {
        const error: ILog = { method: 'Config.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }

};

export default Config;