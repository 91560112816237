import API from "../api";
import ICita from "../types/Cita";
import IClass from "../types/Class";
import IListaEspera from "../types/ListaEspera";
import IMasterClass from "../types/MasterClass";

const getAll = () => API.get('/citas');
const create = (data: ICita, validateSala: boolean) => API.post('/citas', {data, validateSala});
const update = (_id: string, data: ICita, validateSala: boolean) => API.patch(`/cita${_id}`, {data, validateSala});
const deleteCita = (_id: string) => API.delete(`/cita${_id}`);

const setCitaNoAsistida = (_id: string) => API.patch(`/citaNoAsistida/${_id}`);

const createRecurrence = (data: ICita) => API.post('/citasRec', data);
const deleteRecurrence = (_id: string) => API.delete(`/citasRec/${_id}`); 

const getCitaActualPaciente = (_id: string) => API.get(`/citaactual/${_id}`);
const getCitasPaciente = (_id: string) => API.get(`/cita${_id}`);
const getCitasPasadasPaciente = (_id: string) => API.get(`/oldcita${_id}`);

const createClasses = (data: IMasterClass[]) => API.post('/classes', data);
const getAllClasses = () => API.get('/classes');
const deleteClasses = (_ids: string[]) => API.delete(`/classes/${_ids}`);

const getMasterClasses = () => API.get('/masterclasses');
const updateMasterClasses = (data: IMasterClass[], type: number) => API.patch(`/masterclasses${type}`, data);
const modifyPacientes = (data: IMasterClass[]) => API.patch('/masterpacientes', data);
const removePaciente = (data: { _id: string, claseActiva: boolean, endClass: Date, masterToRemove: IMasterClass }) => API.patch('/masterremovepaciente', data);
const getPacientesActivos = () => API.get('/activos');

const updateClass = (_id: string, data: IClass, validateSala: boolean) => API.patch(`/class${_id}`, {data, validateSala});
const addComentarioClass = (_id: string, data: any) => API.patch(`/comentarios${_id}`, data);
const deleteClase = (_id: string) => API.delete(`/class${_id}`);

const sendJustificante = (data: any) => API.post('/sendJustificante', data);
const sendConfirmacion = (data: any) => API.post('/sendConfirmacion', data);

const getCitaAddToCalendar = (_id: string) => API.get(`/addToCalendar${_id}`);

const createListaEspera = (data: IListaEspera[]) => API.post('/listaespera', data);
const updateListaEspera = (_id: string, data: IListaEspera) => API.patch(`/listaespera${_id}`, data);
const getListaEsperaFiltered = (actividadIds: number[], weekdays: number[], horarioId: number) => API.get(`listaespera/${actividadIds}/${weekdays}/${horarioId}`);
const getAllListaEspera = () => API.get('listaespera');
const deleteListaEspera = (_id: string) => API.delete(`/listaespera${_id}`);

const AgendaService = {
    getAll,
    create,
    update,
    deleteCita,
    getCitaActualPaciente,
    getCitasPaciente,
    getCitasPasadasPaciente,
    createRecurrence,
    deleteRecurrence,
    createClasses,
    getAllClasses,
    deleteClasses,
    getMasterClasses,
    getPacientesActivos,
    updateMasterClasses,
    modifyPacientes,
    removePaciente,
    updateClass,
    deleteClase,
    sendJustificante,
    addComentarioClass,
    setCitaNoAsistida,
    sendConfirmacion,
    getCitaAddToCalendar,
    createListaEspera,
    getAllListaEspera,
    getListaEsperaFiltered,
    deleteListaEspera,
    updateListaEspera
};

export default AgendaService;