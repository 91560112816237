import { Alert, Avatar, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import LogService from "../../services/LogService";
import ILog from "../../types/Log";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import IAlert from "../../types/Alert";
import ConfigUtils from "../../utils/Config";
import IConsentimientoDoc from "../../types/CI";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Utilities from "../../common/Utilities";
import ConfigService from "../../services/ConfigService";
import { CONFIGTYPES } from "../../types/Config";
import * as constantes from "../../common/Constantes";

const CI = () => {
    const theme = createTheme();
    const history = useNavigate();

    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const [loading, setLoading] = useState(false);
    const [CI, setCI] = useState<IConsentimientoDoc | null>(ConfigUtils.getConsentientoDoc());
    const [isEdit, setIsEdit] = useState(ConfigUtils.getConsentientoDoc() === null ? false : true);
    const [modalCI, setModalCI] = useState(false);

    const [clinicDomicilio] = useState<string>(ConfigUtils.getClinicDomicilio());
    const [clinicEmail] = useState<string>(ConfigUtils.getClinicEmail());
    const [clinicTelefono] = useState<string>(ConfigUtils.getClinicTelefono());

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const handleChange = (type: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        let doc: IConsentimientoDoc = {
            _id: CI ? CI._id ? CI._id : undefined : undefined,
            responsable: CI ? CI.responsable ? CI?.responsable : '' : '',
            responsableNIF:  CI ? CI.responsableNIF ? CI?.responsableNIF : '' : '',
            texto: CI ? CI.texto ? CI?.texto : '' : '',
            userId: CI ? CI.userId ? CI.userId : '' : ''
        }

        switch (type) {
            case 'responsable':
                doc.responsable = event.target.value;
                break;
            case 'responsableNIF':
                doc.responsableNIF = event.target.value;
                break;
            case 'texto':
                doc.texto = event.target.value;
                break;
        }

        setCI(doc);
    };

    const saveConsentimiento = () => {
        if (!CI) {
            setAlert({ open: true, severity: 'warning', msg: 'Falta configuración' });
            return;
        }
        if (Utilities.isNullEmptyUndefined(CI.responsable) || Utilities.isNullEmptyUndefined(CI.responsableNIF) || Utilities.isNullEmptyUndefined(CI.texto)) {
            setAlert({ open: true, severity: 'warning', msg: 'Falta configuración' });
            return;
        }
        if (!Utilities.validateSpanishID(CI.responsableNIF)) {
            setAlert({ open: true, severity: 'warning', msg: 'El DNI, CIF o NIE no tiene un formato válido' });
            return;
        }

        setLoading(true);

        if (isEdit) {
            if (!CI._id) return;

            ConfigService.updateCI(CI._id, CI)
                .then((response: any) => {
                    ConfigUtils.editConfig(CONFIGTYPES.consentimientoDoc.toString(), response.data);
                    setAlert({ open: true, severity: 'success', msg: 'Configuración de consentimiento actualizada correctamente' });
                })
                .catch((e: any) => {
                    if (e.response.status === 401) {
                        localStorage.clear();
                        history(constantes.R_HOME);
                    } else {
                        const error: ILog = { method: 'CI.Component.updateCI', level: 'error', message: e.message, meta: e.response };
                        LogService.logError(error);
                        setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                    }
                })
                .finally(() => {
                    setLoading(false);
                    setModalCI(false);
                });
        } else {
            CI.userId = ConfigUtils.getUserId();
            ConfigService.saveCI(CI)
                .then((response: any) => {
                    ConfigUtils.addConfig(CONFIGTYPES.consentimientoDoc.toString(), response.data);
                    setCI(response.data);
                    setIsEdit(true);
                    setAlert({ open: true, severity: 'success', msg: 'Configuración de consentimiento guardada correctamente' });
                })
                .catch((e: any) => {
                    if (e.response.status === 401) {
                        localStorage.clear();
                        history(constantes.R_HOME);
                    } else {
                        const error: ILog = { method: 'CI.Component.saveCI', level: 'error', message: e.message, meta: e.response };
                        LogService.logError(error);
                        setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                    }
                })
                .finally(() => {
                    setLoading(false);
                    setModalCI(false);
                });
        }
    };

    try {
        return (
            <Grid>
                <Grid container>
                    <Grid item md={12}>
                        <Container>
                            <Grid>
                                <Grid container spacing={2}>
                                    <Grid item md={4} xs={12}>
                                        <Button disabled={clinicDomicilio === '' || clinicEmail === '' || clinicTelefono === ''} onClick={() => setModalCI(true)} sx={{ backgroundColor: '#63ad3c', "&:hover": { backgroundColor: '#7dc557' } }} variant="contained" >
                                            <Typography variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', color: 'white' }}>{`${isEdit ? 'Editar configuración' : 'Configurar consentimento'}`}</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                                {isEdit && <Grid container spacing={2} sx={{ mt: theme.spacing(1) }}>
                                    <Grid item md={6} xs={12} style={{ margin: 'auto' }}>
                                        <TextField value={CI?.responsable ? CI.responsable : ''} variant="filled" label="Responsable de protección de datos" margin="normal" fullWidth inputProps={{ readOnly: true }} />
                                    </Grid>
                                    <Grid item md={6} xs={12} style={{ margin: 'auto' }}>
                                        <TextField value={CI?.responsableNIF ? CI.responsableNIF : ''} variant="filled" label="DNI, CIF o NIE del responsable de protección de datos" margin="normal" fullWidth inputProps={{ readOnly: true }} />
                                    </Grid>
                                    <Grid item md={12} xs={12} style={{ margin: 'auto' }}>
                                        <TextField multiline rows={4} value={CI?.texto ? CI.texto : ''} variant="filled" label="Tratamientos que se realizan" margin="normal" fullWidth inputProps={{ readOnly: true }} />
                                    </Grid>
                                </Grid>}
                            </Grid>
                        </Container>
                    </Grid>
                </Grid>

                {/* CONFIGURAR CONSENTIMIENTO */}
                <Dialog open={modalCI} maxWidth="lg" fullWidth onClose={() => setModalCI(false)}>
                    <DialogContent>
                        <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                            <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}>{isEdit ? <EditIcon /> : <AddIcon />}</Avatar><div style={{ margin: '10px auto' }}>{isEdit ? 'Editar configuración' : 'Configurar correo'}</div>
                        </Typography>

                        <Grid container spacing={1}>
                            <Grid item md={6} xs={12} style={{ margin: 'auto' }}>
                                <TextField value={CI?.responsable ? CI?.responsable : ''} onChange={(event) => handleChange('responsable', event)} variant="outlined" label="Nombre y apellidos del responsable de protección de datos" margin="dense" fullWidth required autoFocus />
                            </Grid>
                            <Grid item md={6} xs={12} style={{ margin: 'auto' }}>
                                <TextField value={CI?.responsableNIF ? CI.responsableNIF : ''} onChange={(event) => handleChange('responsableNIF', event)} variant="outlined" label="DNI, CIF o NIE del responsable de protección de datos" margin="dense" fullWidth required />
                            </Grid>
                            <Grid item md={12} sx={{ mt: theme.spacing(1) }}>
                                <TextField multiline rows={4} variant="outlined" label="Tratamientos que se realizan" fullWidth value={CI?.texto ? CI.texto : ''} onChange={(event) => handleChange('texto', event)}  inputProps={{ maxLength: 230 }} />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    {loading ?
                        <Grid container>
                            <CircularProgress style={{ margin: '20px auto' }} />
                        </Grid> :
                        <DialogActions>
                            <Button onClick={saveConsentimiento}>Guardar</Button>
                            <Button onClick={() => setModalCI(false)}>Cancelar</Button>
                        </DialogActions>}

                </Dialog>
                
                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </Grid>
        );
    } catch (e: any) {
        const error: ILog = { method: 'CI.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }
};

export default CI;