import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Button, Card, CardActions, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Snackbar, Typography } from "@mui/material";
import LogService from "../../../../services/LogService";
import ILog from "../../../../types/Log";
import IPaciente from "../../../../types/Paciente";
import { createTheme } from '@mui/material/styles';
import AgendaService from "../../../../services/AgendaService";
import ICita from "../../../../types/Cita";
import * as constantes from "../../../../common/Constantes";
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AgendaUtils from "../../../../utils/Agenda";
import ConfigUtils from "../../../../utils/Config";
import IAlert from "../../../../types/Alert";

type CitasProps = {
    paciente: IPaciente
}

const Citas = (props: CitasProps) => {

    const theme = createTheme();
    const history = useNavigate();

    const totalCitas = 3;

    const [loading, setLoading] = useState(true);

    const [citas, setCitas] = useState<Array<ICita>>([]);
    const [shownCitas, setShownCitas] = useState<Array<ICita>>([]);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [modalDelete, setModalDelete] = useState(false);
    const [citaDelete, setCitaDelete] = useState<ICita>();

    const [sanitarios] = useState(ConfigUtils.getSanitarios());

    useEffect(() => {
        AgendaService.getCitasPaciente(props.paciente._id)
            .then((response) => {
                const xcitas = response.data;
                const ycitas = JSON.parse(JSON.stringify(response.data)).splice((currentPage - 1) * totalCitas, totalCitas);
                setCitas(xcitas);
                setTotalPages(getTotalPages(xcitas.length));
                setShownCitas(ycitas);
            })
            .catch((e) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Citas.Component.useEffect', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoading(false);
            });

    }, [setCitas, setShownCitas, setTotalPages, setLoading, setAlert, history, props.paciente._id, currentPage]);

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const getFromShowingItems = () => {
        let currentItem = 0;

        //Calculamos el inicio de página de los elementos
        if (currentPage > 0) currentItem = (currentPage - 1) * totalCitas + 1;

        return currentItem;
    };

    const getLastShowingItems = () => {
        let maxItems = currentPage * totalCitas;

        //Calculamos el final de página de los elementos
        //Si excede el total, lo igualamos
        if (maxItems < shownCitas.length) maxItems = shownCitas.length;
        if (currentPage === totalPages) maxItems = citas.length;

        return maxItems;
    };

    const getTotalPages = (length: number) => {
        if (length === 0) return 1;
        return Math.ceil(length / totalCitas);
    };

    const setPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const setNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const deleteCita = () => {
        if (!citaDelete?._id) return;
        AgendaService.deleteCita(citaDelete?._id?.toString())
            .then(response => {
                setCitas(citas.filter(x => x._id !== citaDelete._id));
                setShownCitas(citas.filter(x => x._id !== citaDelete._id));
                setTotalPages(getTotalPages(citas.filter(x => x._id !== citaDelete._id).length));
                setAlert({ open: true, severity: 'success', msg: response.data.message });
            })
            .catch(e => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'Citas.Component.deleteCita', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setModalDelete(false);
                setCitaDelete(undefined);
            });
    };

    try {

        return (
            <>
                <Grid item md={12} xs={12}>
                <Container maxWidth="md">
                    <Paper elevation={3} sx={{ marginTop: theme.spacing(3), padding: theme.spacing(3), display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', marginLeft: '10px' }} >PRÓXIMAS CITAS</Typography>

                        {loading ?
                            <Grid container>
                                <CircularProgress style={{ margin: '10px auto' }} />
                            </Grid> :

                            shownCitas && shownCitas.length === 0 ? <div>No se han encontrado próximas citas</div> :
                                <>
                                    <div style={{ display: 'inline-flex' }}>

                                        {shownCitas.map((cita) => {
                                            return (
                                                <Card key={cita._id} sx={{ width: '120px', display: 'grid', justifyItems: 'center', textAlign: 'center', margin: '5px' }}>
                                                    <div style={{ backgroundColor: sanitarios.filter((s) => s.internalID === cita.especialistaId)[0] == undefined ? 'grey' : sanitarios.filter((s) => s.internalID === cita.especialistaId)[0]?.color, width: '100%', height: '25px' }}>
                                                        <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: '15px', mt: '2px' }}>
                                                            {constantes.monthNames[new Date(cita.start).getMonth()].toUpperCase()}
                                                        </Typography>
                                                    </div>
                                                    <CardContent sx={{ padding: '10px' }}>

                                                        <Typography variant="h4" component="div">
                                                            {new Date(cita.start).getDate()}
                                                        </Typography>
                                                        <Typography variant="h6" component="div">
                                                            {constantes.weekNames[new Date(cita.start).getDay() - 1]}
                                                        </Typography>
                                                        <Typography color="text.secondary">
                                                            {ConfigUtils.getSanitarios().filter((s: any) => s.internalID === cita.especialistaId)[0]?.firstName}
                                                        </Typography>
                                                    </CardContent>

                                                    <CardActions sx={{ flexDirection: "column", alignItems: "baseline" }}>
                                                        <Link to={constantes.R_AGENDA} state={cita} style={{ textDecoration: 'none' }}>
                                                            <Button size="small">EDITAR CITA</Button>
                                                        </Link>
                                                        <Button onClick={() => {setModalDelete(true); setCitaDelete(cita);}} color="error" size="small">QUITAR CITA</Button>
                                                    </CardActions>
                                                </Card>
                                            );
                                        })}

                                    </div>

                                    {totalPages > 1 &&

                                        <Grid container >
                                            <Grid item md={8} sm={6} xs={6}>
                                                <div style={{ marginTop: theme.spacing(1.4), float: 'left', fontSize: '14px', fontWeight: 'bold', marginLeft: '10px' }}>{` Mostrando ${getFromShowingItems()}-${getLastShowingItems()} de ${citas.length} próximas citas`}</div>
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={6}>
                                                <div style={{ float: 'right' }}>
                                                    <IconButton onClick={() => setPreviousPage()}><ArrowLeftIcon sx={{ color: '#696969', marginRight: theme.spacing(1) }} titleAccess="Atrás" /></IconButton>
                                                    <IconButton onClick={() => setNextPage()}><ArrowRightIcon sx={{ color: '#696969', marginRight: theme.spacing(1) }} titleAccess="Siguiente" /></IconButton>
                                                </div>
                                            </Grid>
                                        </Grid>

                                    }

                                </>
                        }
                    </Paper>
                </Container>
                </Grid>

                {/* BORRAR CITA */}
                <Dialog open={modalDelete} maxWidth="lg" onClose={() => setModalDelete(false)}>
                    <DialogTitle>ELIMINAR CITA</DialogTitle>
                    {
                        <>
                        <DialogContent>
                            <DialogContentText>{`¿Está seguro de que desea eliminar la cita del ${citaDelete && AgendaUtils.formatCitaDate(citaDelete)} ${citaDelete && AgendaUtils.formatCitaTime(citaDelete)}?`}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => deleteCita()} color="primary">SI</Button>
                            <Button onClick={() => setModalDelete(false)} color="primary" autoFocus>NO</Button>
                        </DialogActions></>
                    }
                </Dialog>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </>
        );

    } catch (e: any) {
        const error: ILog = { method: 'Citas.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }
};

export default Citas;