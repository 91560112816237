import { useState, useEffect } from "react";
import { Alert, Avatar, Container, Paper } from "@mui/material";
import LogService from "../../services/LogService";
import IAlert from "../../types/Alert";
import ILog from "../../types/Log";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { createTheme } from '@mui/material/styles';
import CalendarIcon from '@mui/icons-material/EventAvailable';
import { isIOS, isAndroid } from "react-device-detect";
import * as constantes from "../../common/Constantes";
import AgendaService from "../../services/AgendaService";
import ICita from "../../types/Cita";
import Utilities from "../../common/Utilities";
import ConfigUtils from "../../utils/Config";

const AddToCalendar = () => {
    const theme = createTheme();
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const [cita, setCita] = useState<ICita>();
    const [sanitarios, setSanitarios] = useState<Array<{ internalID: number, firstName: string }>>([]);

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get(constantes.QP_ID);

        if (!id) {
            const error: ILog = { method: 'AddToCalendar.Component.useEffect', level: 'error', message: 'No hay id de cita para añadir al calendario', meta: { message: ''} };
            LogService.logError(error);
            setAlert({ open: true, severity: 'error', msg: 'Se ha producido un error, vuelva a intentarlo más adelante' });
        } else {
            //Recuperamos la cita
            AgendaService.getCitaAddToCalendar(id)
            .then((response) => {
                setCita(response.data.cita);
                setSanitarios(response.data.sanitarios);
                if (new Date(response.data.cita.start).getTime() < new Date().getTime()) {
                    setAlert({ open: true, severity: 'warning', msg: 'La cita ya ha pasado' });
                }
            })
            .catch((e) => {
                const error: ILog = { method: 'AddToCalendar.Component.useEffect', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            });
        }
    }, []);

    try {

        let option = "'Apple', 'Google', 'Outlook.com', 'iCal'";
        if (isIOS) option = "Apple";
        if (isAndroid) option = "Google";

        return (
            <>

            {cita && !alert.open && <Container maxWidth="sm">
                <Paper elevation={3} sx={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3), padding: theme.spacing(3), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar sx={{ margin: theme.spacing(1), backgroundColor: 'black' }}>
                        <CalendarIcon />
                    </Avatar>

                    <AddToCalendarButton iCalFileName="citaMohe" hideIconButton hideCheckmark
                    label="Añadir al calendario"
                    name={`Cita en mohe con ${sanitarios.filter((s: any) => s.internalID === cita.especialistaId)[0].firstName}`}
                    options={option}
                    location="C/ Vélez de Guevara 35, Logroño"
                    startDate={Utilities.formatDate(cita.start.toString())}
                    endDate={Utilities.formatDate(cita.end.toString())}
                    startTime={Utilities.formatTime(cita.start.toString())}
                    endTime={Utilities.formatTime(cita.end.toString())}
                    timeZone="Europe/Madrid"
                    language="es"
                    styleLight="--btn-background: rgb(0, 112, 65); --btn-text: #fff; --font: montserrat;"
                    ></AddToCalendarButton>

                </Paper>
            </Container>}

            {alert.open && <Alert sx={{margin: theme.spacing(2)}} variant="filled" onClose={handleCloseAlert} severity={alert.severity}>{alert.msg}</Alert>}
            </>
        );
    } catch (e: any) {
        const error: ILog = { method: 'AddToCalendar.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }

};

export default AddToCalendar;