import { createTheme } from '@mui/material/styles';
import { Alert, CircularProgress, Container, Grid, List, Paper, Snackbar, Typography } from "@mui/material";
import LogService from "../../../services/LogService";
import ILog from "../../../types/Log";
import IPaciente from "../../../types/Paciente";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import IAlert from "../../../types/Alert";
import * as constantes from "../../../common/Constantes";
import PacienteService from '../../../services/PacienteService';
import IClass from '../../../types/Class';
import IMasterClass from '../../../types/MasterClass';
import IListaEspera from '../../../types/ListaEspera';
import IActividad from '../../../types/Actividad';
import ConfigUtils from '../../../utils/Config';
import ClaseUtils from '../../../utils/Clase';

type ClasesProps = {
    paciente: IPaciente
}

const Clases = (props: ClasesProps) => {

    const theme = createTheme();
    const history = useNavigate();

    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const [loading, setLoading] = useState(true);
    const [display, setDisplay] = useState(false);
    const [actividades] = useState<Array<IActividad>>(ConfigUtils.getActividades());
    const [clase, setClase] = useState<IClass>();
    const [masterClasses, setMastersClasses] = useState<IMasterClass[]>([]);
    const [listaEspera, setListaEspera] = useState<IListaEspera>();

    useEffect(() => {

        PacienteService.getClases(props.paciente._id)
        .then((response: any) => {
            setClase(response.data.clase);
            setMastersClasses(response.data.master);
            setListaEspera(response.data.listaEspera);
            if (response.data.clase || response.data.master.length > 0 || response.data.listaEspera)
                setDisplay(true);
        })
        .catch((e: any) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'Clases.Component.useEffect', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        })
        .finally(() => {
            setLoading(false);
        });

    }, [setLoading, setAlert, history, props.paciente]);

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    try {
        return (
            <div style={{ display: display ? "block" : "none" }}>
                <Grid item md={12} xs={12}>
                    <Container maxWidth="md">
                        <Paper elevation={3} sx={{ marginTop: theme.spacing(3), padding: theme.spacing(3), display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', marginLeft: '10px' }} >CLASES</Typography>

                            {loading ? 
                            <Grid container>
                                <CircularProgress style={{ margin: '10px auto' }} />
                            </Grid>
                             : <div>
                                    <List dense={true}>
                                        {masterClasses.length != 0 && 
                                            masterClasses.map((clase) => {
                                                return (
                                                    <div key={clase._id}>
                                                        <li>{`${actividades.find(a => a.internalID == clase.title)?.title} - ${ClaseUtils.formatClassTitle(clase)}`}</li>
                                                    </div>
                                                );
                                            })
                                        }
                                        {masterClasses.length === 0 && clase &&
                                            <div>
                                                <li>{`Fue a ${actividades.find(a => a.internalID == clase.title)?.title} hasta ${ClaseUtils.formatDate(clase.start.toString())}`}</li>
                                            </div>
                                        }
                                        {listaEspera && 
                                            <div style={{ marginTop: '10px' }}>
                                                <Typography variant="button" style={{ fontWeight: 'bold', marginLeft: '10px' }} >LISTA DE ESPERA</Typography>
                                                <li><strong>{listaEspera.actividadIds.length == 1 ? "Activida: " : "Actividades: "}</strong>
                                                  {listaEspera.actividadIds.map((id) => {
                                                    const actividad = actividades.find(a => a.internalID == id.toString());
                                                    return actividad ? actividad.title : null;
                                                  }).filter(title => title !== null).join(', ')}
                                                </li>
                                                <li><strong>{listaEspera.weekdays.length == 1 ? "Día: " : "Días: "}</strong>{`Los ${listaEspera.weekdays.sort((a, b) => a - b).map(id => constantes.weekNames[id]).join(', ')}`}</li>
                                                <li><strong>Horario: </strong>{constantes.horarioClases[listaEspera.horarioId]}</li>
                                                {listaEspera.comentarios != "" && <li><strong>Comentarios: </strong>{listaEspera.comentarios}</li>}
                                            </div>
                                        }
                                    </List>
                               </div>
                            }

                        </Paper>
                    </Container>
                </Grid>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </div>
        );

    } catch (e: any) {
        const error: ILog = { method: 'Clases.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }

};

export default Clases;