import API from '../api/index';
import IUser from '../types/User';
import IResetPass from '../types/ResetPass';

const signIn = (data: IUser) => API.post('/signin', data);
const signUp = (data: IUser) => API.post('/signup', data);
const resetPass = (email: string) => API.get(`/resetpass${email}`);
const changePass = (_id: string, data: IResetPass) => API.patch(`changepass${_id}`, data);
const updateUser = (_id: string, data: any) => API.patch(`/user${_id}`, data);
const updateClinicName = (_id: string, data: any) => API.patch(`/clinicName${_id}`, data);
const updateProvincia = (_id: string, data: any) => API.patch(`/provincia${_id}`, data);
const updateClinicCity = (_id: string, data: any) => API.patch(`/clinicCity${_id}`, data);
const updateClinicDomicilio = (_id: string, data: any) => API.patch(`/clinicDomicilio${_id}`, data);
const updateClinicEmail = (_id: string, data: any) => API.patch(`/clinicEmail${_id}`, data);
const updateClinicTelefono = (_id: string, data: any) => API.patch(`/clinicTelefono${_id}`, data);
const createPass = (email: string) => API.get(`/createpass${email}`);
const createPassEmpleado = (_id: string, data: IResetPass) => API.patch(`empleadocreatepass${_id}`, data);


const AuthService = {
    signIn,
    signUp,
    resetPass,
    changePass,
    updateUser,
    updateClinicName,
    updateProvincia,
    updateClinicCity,
    updateClinicDomicilio,
    updateClinicEmail,
    updateClinicTelefono,
    createPass,
    createPassEmpleado
};

export default AuthService;