import { createTheme } from '@mui/material/styles';
import { useState } from "react";
import { Alert, Autocomplete, Avatar, Button, Card, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Radio, RadioGroup, Snackbar, TextField, Tooltip, Typography } from "@mui/material";
import LogService from "../../services/LogService";
import ILog from "../../types/Log";
import IMasterClass from "../../types/MasterClass"
import ClaseUtils from "../../utils/Clase";
import EditIcon from '@mui/icons-material/Edit';
import IActividad from '../../types/Actividad';
import ConfigUtils from '../../utils/Config';
import IPaciente from '../../types/Paciente';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import * as constantes from "../../common/Constantes";
import IAlert from '../../types/Alert';
import AgendaService from '../../services/AgendaService';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import Utilities from '../../common/Utilities';
import IListaEspera from '../../types/ListaEspera';

const locale = constantes.locale;

type PacienteActivitiesProps = {
    masterClasses: IMasterClass[],
    pacientes: IPaciente[],
    onClose: () => void,
    callBack: () => void
}

const PacienteActivities = (props: PacienteActivitiesProps) => {
    const theme = createTheme();
    const history = useNavigate();

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const [actividades] = useState<Array<IActividad>>(ConfigUtils.getActividades());

    const [checked, setChecked] = useState<Array<string>>([]);
    const [modalAddPacientes, setModalAddPacientes] = useState(false);

    const [allClasses, setAllClasses] = useState("Todas las clases");
    const [pacientesIdsMultiple, setPacientesIdsMultiple] = useState<Array<Array<IPaciente>>>([]);
    const [startClass, setStartClass] = useState<Date>(new Date());
    const [endClass, setEndClass] = useState<Date>(new Date());

    const [modalRemovePaciente, setModalRemovePaciente] = useState(false);
    const [pacienteToRemove, setPacienteToRemove] = useState<IPaciente>();
    const [claseActiva, setClaseActiva] = useState(false);
    const [masterToRemove, setMasterToRemove] = useState<IMasterClass>();

    const [overBooking, setOverBooking] = useState(false);

    const [listaEspera, setListaEspera] = useState<IListaEspera[]>([]);
    const [verListaEspera, setVerListaEspera] = useState(false);

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    const handleToggle = (item: IMasterClass) => {
        if (item._id === undefined || item._id === null) return;

        const currentValue: boolean = checked.includes(item._id);
        const newChecked = [...checked];

        if (!currentValue) {
            newChecked.push(item._id);
        } else {
            const index = checked.indexOf(item._id);
            newChecked.splice(index, 1);
        }

        setChecked(newChecked);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllClasses((event.target as HTMLInputElement).value);
        setStartClass(new Date());
        setEndClass(new Date());
    };

    const onChangePacientesIds = (pacientesIds: IPaciente[]) => {
        const pacientes = [...pacientesIdsMultiple];
        for (let i=0; i <= checked.length; i++)
        {
            pacientes[i] = pacientesIds;
        }
        setPacientesIdsMultiple(pacientes);
    };

    const close = () => {
        props.onClose();
    };

    const addPacientesToClass = (validateCapacity: boolean) => {
        let modifiedMasters: IMasterClass[] = [];
        let capacidadSuperada: boolean = false;

        checked.map((c, index) => {
            let master: IMasterClass = props.masterClasses.filter(x => x._id === c)[0];
            const masterPacientes: any = [];

            if (pacientesIdsMultiple[index] === undefined) return;

            pacientesIdsMultiple[index].map((p) => {
                if (allClasses === "Todas las clases") masterPacientes.push({ id: p._id, start: moment().startOf('day').toDate(), end: moment(master.terminarRepetir).endOf('day').toDate() });
                else masterPacientes.push({ id: p._id, start: moment(startClass).startOf('day').toDate(), end: moment(endClass).endOf('day').toDate() });
                return masterPacientes;
            });

            if (validateCapacity) {
                //Se valida si se supera la capacidad de las clases
                const capacity = actividades.filter(a => a.internalID?.toString() == master.title.toString())[0].capacity;
                const pacientesActivos = master.pacientes.filter(p => {
                    const now = new Date();
                    const startDate = new Date(p.start);
                    const endDate = new Date(p.end);
                    return startDate <= now && endDate >= now;
                });
                
                if (pacientesActivos.length > capacity) {
                    setOverBooking(true);
                    capacidadSuperada = true;
                    return;
                }
            }

            master.pacientes = [...master.pacientes, ...masterPacientes];

            modifiedMasters.push(master);
            return master;
        });

        if (!capacidadSuperada) {
            setLoading(true);

            AgendaService.modifyPacientes(modifiedMasters)
            .then((response: any) => {
                setAlert({ open: true, severity: 'success', msg: 'Clases actualizadas correctamente' });
                history(0);
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'PacientesActivities.Component.modifyPacientes', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                }
            })
            .finally(() => {
                setLoading(false);
                close();
            });
        }
    };

    const addPacientes = () => {
        if (checked.length === 0) {
            setAlert({ open: true, severity: 'warning', msg: 'No se han seleccionado clases a editar' });
        }

        const actividadIds: number[] = [];
        const weekdays: number[] = [];
        let horario: number = 0;

        checked.map((c) => {
            let master: IMasterClass = props.masterClasses.filter(x => x._id === c)[0];
            actividadIds.push(parseInt(master.title));
            weekdays.push(master.weekDay);
            if (new Date(master.start).getHours() < 15) horario = 1
            else horario = 2;
        });

        const actividadIdsUnicos =  actividadIds.filter((value, index, self) => self.indexOf(value) === index);
        const weekdaysUnicos = weekdays.filter((value, index, self) => self.indexOf(value) === index);

        AgendaService.getListaEsperaFiltered(actividadIdsUnicos, weekdaysUnicos, horario)
        .then((response: any) => {
            setListaEspera(response.data);
        })
        .catch((e: any) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'PacientesActivities.Component.getListaEsperaFiltered', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        })
        .finally(() => {
            setModalAddPacientes(true);
        });
    };

    const removePaciente = () => {
        if (!pacienteToRemove || !masterToRemove) return;

        const filteredPacientes = masterToRemove.pacientes.filter(p => p.id === pacienteToRemove._id);
        const lastPaciente = filteredPacientes.pop();
        const oldEnd = lastPaciente ? lastPaciente.end : undefined;

        if (!oldEnd) return;

        if (new Date(oldEnd).getTime() < new Date(endClass).getTime()) {
            setAlert({ open: true, severity: 'warning', msg: 'La fecha final no puede ser mayor a la anterior' });
            return;
        }
        
        setLoading(true);

        AgendaService.removePaciente({ _id: pacienteToRemove._id, claseActiva, endClass, masterToRemove})
        .then((response: any) => {
            setAlert({ open: true, severity: 'success', msg: 'Clases actualizadas correctamente' });
            history(0);
        })
        .catch((e: any) => {
            if (e.response.status === 401) {
                localStorage.clear();
                history(constantes.R_HOME);
            } else {
                const error: ILog = { method: 'PacientesActivities.Component.removePaciente', level: 'error', message: e.message, meta: e.response };
                LogService.logError(error);
                setAlert({ open: true, severity: 'error', msg: e.response.data.message });
            }
        })
        .finally(() => {
            setLoading(false);
            close();
        });
    };

    const filterPacientes = (pacientes: IPaciente[], masterClasses: IMasterClass[]): IPaciente[] => {
        let filterPacientes: IPaciente[] = [];
        
        checked.map(item => {
            const master: IMasterClass = masterClasses.filter(x => x._id === item)[0];
            
            const pacientesActivos = master.pacientes.filter(p => new Date(p.end).getTime() > new Date().getTime() && new Date(p.start).getTime() < new Date().getTime());
            const pacienteNoActivos = master.pacientes.filter(p => new Date(p.end).getTime() > new Date().getTime() && new Date(p.start).getTime() > new Date().getTime());

            const pacientesId: string[] = [...pacientesActivos, ...pacienteNoActivos].map(p => p.id);

            filterPacientes = pacientes.filter(p => !pacientesId.includes(p._id));
            return filterPacientes;
        });

        return filterPacientes;
    };

    const formatListaEspera = (): string => {  
        const result = listaEspera.map(lista1 => {
            const paciente = props.pacientes.find(p => p._id === lista1.pacienteId);
            const nombreCompleto = `${paciente?.nombre} ${paciente?.apellidos}`;
        
            let actividadesTitle = lista1.actividadIds.map(id => {
                const actividad = actividades.find(a => a.internalID?.toString() === id.toString());
                return actividad ? actividad.title : '';
            }).join(', ');
        
            let weekDaysTitle = lista1.weekdays.sort((a, b) => a - b).map(id => constantes.weekNames[id]).join(', ');
        
            const horario = constantes.horarioClases[lista1.horarioId].toLowerCase();
            const comentarios = lista1.comentarios ? lista1.comentarios : '';
        
            return `${nombreCompleto} - ${actividadesTitle} - ${weekDaysTitle} de ${horario} - ${comentarios}`;
        }).join('\n');

        return result;
    }

    try {

        return (
            <>
                {/* SELECCION DE CLASES */}
                <Dialog open={true} maxWidth="lg" fullWidth onClose={close}>
                    <DialogContent>
                        <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                            <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><EditIcon /></Avatar><div style={{ margin: '10px auto' }}>Selecciona las clases donde modificar pacientes</div>
                        </Typography>
                        <List>
                            {
                                props.masterClasses.map((item) => {

                                    return (
                                        <ListItem key={item._id}>
                                            <ListItemButton onClick={() => handleToggle(item)} dense>
                                                <ListItemIcon>
                                                    <Checkbox edge="start" tabIndex={-1} disableRipple checked={item._id !== undefined && item._id !== null && checked.includes(item._id)} />
                                                </ListItemIcon>
                                                <ListItemText primary={`${actividades.filter(a => a.internalID?.toString() == item.title.toString())[0].title} - ${ClaseUtils.formatClassTitle(item)}`} />
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={addPacientes}>Continuar</Button>
                        <Button onClick={close}>Cancelar</Button>
                    </DialogActions>
                </Dialog>

                {/* AÑADIR PACIENTES */}
                <Dialog open={modalAddPacientes} maxWidth="lg" fullWidth PaperProps={{ sx: { minHeight: '50vh' } }} onClose={close}>
                    <DialogContent>
                        <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                            <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><EditIcon /></Avatar><div style={{ margin: '10px auto' }}>Modificar pacientes de las clases</div>
                        </Typography>

                        {
                            checked.map((item, index) => {
                                const master: IMasterClass = props.masterClasses.filter(x => x._id === item)[0];
                                const pacientesActivos = master.pacientes.filter(p => new Date(p.end).getTime() > new Date().getTime() && new Date(p.start).getTime() < new Date().getTime());
                                const pacienteNoActivos = master.pacientes.filter(p => new Date(p.end).getTime() > new Date().getTime() && new Date(p.start).getTime() > new Date().getTime());
                                
                                return (
                                    <Grid container key={index}>
                                        <Grid item md={12}>
                                        <Card key={index} sx={{ m: theme.spacing(1), p: theme.spacing(1) }}>
                                            <Typography variant="h6" sx={{ display: 'inline-flex', mb: theme.spacing(1) }}>
                                                {`${actividades.filter(a => a.internalID?.toString() === master.title)[0].title} [${master.pacientes ? master.pacientes.filter(p => new Date(p.end).getTime() > new Date().getTime() && new Date(p.start).getTime() < new Date().getTime()).length : 0}/${master && actividades.filter(a => a.internalID?.toString() == master.title.toString())[0].capacity}] - ${ClaseUtils.formatClassTitle(master)}`}
                                            </Typography>
                                            <Grid container>
                                                {pacientesActivos.length > 0 ? 
                                                <Grid item md={6}>
                                                    <Typography variant="button">ACTIVOS</Typography>
                                                    {
                                                        pacientesActivos.map((p) => {
                                                            const paciente: IPaciente = props.pacientes.filter(x => x._id === p.id)[0];
                                                            const pacienteAllClasses: boolean = Utilities.compareDates(new Date(p.end), new Date(master.terminarRepetir));
                                                            return (
                                                                <Grid container key={p.id}>
                                                                    <Grid item md={10} xs={11}>
                                                                        {pacienteAllClasses ? <Typography>{`${paciente.nombre} ${paciente.apellidos}`}</Typography> : <Typography>{`${paciente.nombre} ${paciente.apellidos} - Hasta el ${ClaseUtils.formatDate(p.end.toString())}`}</Typography>}
                                                                    </Grid>
                                                                    <Grid item md={1} xs={1}>
                                                                        <Tooltip title={`Quitar a ${paciente.nombre}`} >
                                                                            <IconButton onClick={() => {setPacienteToRemove(paciente); setModalRemovePaciente(true); setClaseActiva(new Date(p.start).getTime() > new Date().getTime() ? false : true); setMasterToRemove(master)}}>
                                                                                <DeleteIcon style={{ cursor: 'pointer' }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })
                                                    }
                                                </Grid> : <div>No se han encontrado pacientes activos</div>}
                                                {pacienteNoActivos.length > 0 ? 
                                                <Grid item md={6}>
                                                    <Typography variant="button">NO ACTIVOS</Typography>
                                                    {
                                                        pacienteNoActivos.map((p) => {
                                                            const paciente: IPaciente = props.pacientes.filter(x => x._id === p.id)[0];
                                                            const pacienteAllClasses: boolean = Utilities.compareDates(new Date(p.end), new Date(master.terminarRepetir));
                                                            return (
                                                                <Grid container key={p.id}>
                                                                    <Grid item md={10} xs={11}>
                                                                        {pacienteAllClasses ? <Typography>{`${paciente.nombre} ${paciente.apellidos} - Empieza el ${ClaseUtils.formatDate(p.start.toString())}`}</Typography> : <Typography>{`${paciente.nombre} ${paciente.apellidos} - Empieza el ${ClaseUtils.formatDate(p.start.toString())} hasta el ${ClaseUtils.formatDate(p.end.toString())}`}</Typography>}
                                                                    </Grid>
                                                                    <Grid item md={1} xs={1}>
                                                                        <Tooltip title={`Quitar a ${paciente.nombre}`} >
                                                                            <IconButton onClick={() => {setPacienteToRemove(paciente); setModalRemovePaciente(true); setClaseActiva(new Date(p.start).getTime() > new Date().getTime() ? false : true); setMasterToRemove(master)}}>
                                                                                <DeleteIcon style={{ cursor: 'pointer' }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })
                                                    }
                                                </Grid> : <div style={{ marginLeft: 100 }}>No se han encontrado pacientes no activos</div>}
                                            </Grid>
                                        </Card>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                        
                        <FormGroup>
                            <FormControl>
                                <RadioGroup value={allClasses} onChange={handleChange}>
                                    <FormControlLabel value="Todas las clases" control={<Radio />} label="Todas las clases" />
                                    <FormControlLabel value="Seleccionar fechas" control={<Radio />} label="Seleccionar fecha de inicio y final" />
                                </RadioGroup>
                            </FormControl>
                        </FormGroup>

                        {
                            allClasses === "Seleccionar fechas" &&

                            <Grid container item md={12} spacing={1}>
                                <Grid item md={2} sx={{ marginTop: 1 }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                        <DatePicker
                                            renderInput={(props: any) => <TextField required fullWidth {...props} />}
                                            label="Fecha de inicio"
                                            toolbarFormat="dd-mm-yyyy"
                                            value={startClass}
                                            onChange={(date: any) => { date && setStartClass(date); }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item md={2} sx={{ marginTop: 1 }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                        <DatePicker
                                            renderInput={(props: any) => <TextField required fullWidth {...props} />}
                                            label="Fecha final"
                                            toolbarFormat="dd-mm-yyyy"
                                            value={endClass}
                                            onChange={(date: any) => { date && setEndClass(date); }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>

                        }
                        
                        <div>
                            <Autocomplete 
                            disablePortal
                            autoComplete
                            id="pacientes"
                            multiple
                            options={filterPacientes(props.pacientes, props.masterClasses)}
                            noOptionsText={'No se han encontrado pacientes'}
                            renderOption={(props, option) => { return (<li {...props} key={option._id}>{`${option.nombre} ${option.apellidos}`}</li>) }}
                            renderInput={(params) => <TextField required {...params} label="Pacientes" variant="outlined" margin="dense" fullWidth />}
                            onChange={(event, value) => { value && onChangePacientesIds(value) }}
                            getOptionLabel={(option) => `${option.nombre} ${option.apellidos}` || ""}
                            />
                        </div>

                        {listaEspera.length > 0 && 
                            <Alert 
                                severity="info"
                                style={{ whiteSpace: 'pre-line', marginTop: 15 }}
                                action={
                                    <Button color="inherit" size="small" onClick={() => setVerListaEspera(!verListaEspera)}>
                                        {verListaEspera ? "OCULTAR" : "VER"}
                                    </Button>
                                }
                            >{verListaEspera ? formatListaEspera() : "Hay pacientes interesados en la lista de espera"}
                            </Alert>
                        }
                                
                    </DialogContent>
                    {loading ?
                        <Grid container>
                            <CircularProgress style={{ margin: '20px auto' }} />
                        </Grid> :
                        <DialogActions>
                            <DialogActions>
                                <Button onClick={() => addPacientesToClass(true)}>Guardar</Button>
                                <Button onClick={close}>Cancelar</Button>
                            </DialogActions>
                        </DialogActions>
                    }
                </Dialog>

                {/* ELIMINAR PACIENTE */}
                <Dialog open={modalRemovePaciente} maxWidth="sm" fullWidth onClose={close}>
                    <DialogContent>
                        <Typography variant="h4" sx={{ display: 'inline-flex' }}>
                            <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}><EditIcon /></Avatar><div style={{ margin: '10px auto' }}>{`Quitar a ${pacienteToRemove?.nombre} de clase`}</div>
                        </Typography>
                        
                        { claseActiva ?
                            
                        <><DialogContentText>Seleccione desde que fecha el paciente no asistirá a las clases</DialogContentText>
                        
                        <Grid container item md={12} spacing={1}>
                            <Grid item md={4} sx={{ marginTop: 1 }}>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                    <DatePicker
                                        renderInput={(props: any) => <TextField required fullWidth {...props} />}
                                        label="Fecha final"
                                        toolbarFormat="dd-mm-yyyy"
                                        value={endClass}
                                        onChange={(date: any) => { date && setEndClass(date); }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid></> : 
                        <DialogContentText>¿Está seguro de que desea quitar al paciente?</DialogContentText>
                        }

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={removePaciente}>Quitar</Button>
                        <Button onClick={close}>Cancelar</Button>
                    </DialogActions>
                </Dialog>

                {/* CONFIRMAR CAPACIDAD */}
                <Dialog open={overBooking} maxWidth="lg" onClose={() => setOverBooking(false)}>
                        <DialogTitle>CAPACIDAD SUPERADA</DialogTitle>
                        <>
                            <DialogContent>
                                <DialogContentText>La capacidad de la actividad se ha superado ¿Está seguro de que desea añadir a los pacientes seleccionados?</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => {addPacientesToClass(false); setOverBooking(false)}} color="primary">SI</Button>
                                <Button onClick={() => setOverBooking(false)} color="primary" autoFocus>NO</Button>
                            </DialogActions>
                        </>
                    </Dialog>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </>
        );
    } catch (e: any) {
        const error: ILog = { method: 'PacienteActivities.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }

};

export default PacienteActivities;