import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import IPaciente from "../../types/Paciente";
import { createTheme } from '@mui/material/styles';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PacienteService from "../../services/PacienteService";
import { Typography, TextField, Button, Avatar, Paper, Container, Grid, CircularProgress, Alert, Snackbar, InputAdornment, Switch } from "@mui/material";
import PersonAdd from '@mui/icons-material/PersonAdd';
import ILog from "../../types/Log";
import LogService from "../../services/LogService";
import IAlert from "../../types/Alert";
import ConfigUtils from "../../utils/Config";
import * as constantes from "../../common/Constantes";
import Utilities from "../../common/Utilities";

const schema = yup.object().shape({
    nombreTutor: yup.string().required('El nombre es obligatorio'),
    apellidosTutor: yup.string().required('Los apellidos son obligatorios')
});

const TutorForm = () => {
    const theme = createTheme();
    const history = useNavigate();

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState<IAlert>({ open: false, severity: 'info', msg: '' });
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<IPaciente>({ resolver: yupResolver(schema) });

    let location = useLocation();
    const [paciente] = useState<IPaciente>(location.state);

    const [passport, setPassport] = useState(false);
    const [errorDNI, setErrorDNI] = useState('');

    const onSubmit = (data: IPaciente) => {
        if (Utilities.isNullEmptyUndefined(data.DNItutor)) {
            if (passport) {
                setErrorDNI('El pasaporte es obligatorio');
                return;
            } else {
                setErrorDNI('El DNI o NIE es obligatorio');
                return;
            }
        }
        if (passport) {
            //Validamos el pasaporte del tutor
            if (!Utilities.validatePassport(data.DNItutor)) {
                setErrorDNI('El pasaporte no tiene un formato válido');
                return;
            }
        } else {
            //Validamos el DNI o NIE del tutor
            if (Utilities.getSpanichIDType(data.DNItutor) === "CIF") {
                setErrorDNI('El DNI o NIE no tiene un formato válido');
                return;
            }
            if (!Utilities.validateSpanishID(data.DNItutor)) {
                setErrorDNI('El DNI o NIE no tiene un formato válido');
                return;
            }
        }
        setLoading(true);
        paciente ? updateTutor(data) : saveTutor(data);
    };

    const clear = () => {
        reset();
        history(-1);
    };

    const saveTutor = (data: IPaciente) => {
        data.userId = ConfigUtils.getUserId();
        PacienteService.create(data)
            .then((response: any) => {
                setLoading(false);
                history(`${constantes.R_PACIENTE}?${constantes.QP_ID}=${response.data._id}`, { state: { open: true, severity: 'success', msg: 'Tutor guardado correctamente' } });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'TutorForm.Component.saveTutor', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                    setLoading(false);
                }
            });
    };

    const updateTutor = (data: IPaciente) => {
        PacienteService.update(paciente._id, data)
            .then((response: any) => {
                setLoading(false);
                history(`${constantes.R_PACIENTE}?${constantes.QP_ID}=${paciente._id}`, { state: { open: true, severity: 'success', msg: 'Tutor actualizado correctamente' } });
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    history(constantes.R_HOME);
                } else {
                    const error: ILog = { method: 'TutorForm.Component.updateTutor', level: 'error', message: e.message, meta: e.response };
                    LogService.logError(error);
                    setAlert({ open: true, severity: 'error', msg: e.response.data.message });
                    setLoading(false);
                }
            });
    };

    const handleChange = (e: any) => {
        setValue(e.target.name, e.target.value);
    };

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert({ open: false, severity: 'info', msg: '' });
    };

    useEffect(() => {
        reset(paciente);
        setPassport(location.state && location.state.DNItutor ? Utilities.getSpanichIDType(location.state.DNItutor) === "Pasaporte" ? true : false : false);
    }, [paciente, reset]);

    try {
        return (
            <Container maxWidth="lg">
                <Paper elevation={3} sx={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3), padding: theme.spacing(3), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <Typography variant="h4" sx={{ display: 'inline-flex', margin: theme.spacing(1, 0, 1) }}>
                        <Avatar sx={{ margin: theme.spacing(1), backgroundColor: '#7dc557' }}> <PersonAdd /></Avatar><div style={{ margin: '10px auto' }}>{paciente.DNItutor ? 'Editar tutor legal' : 'Añadir tutor legal'}</div>
                    </Typography>

                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Grid container spacing={2} >
                            <Grid item md={4} xs={12} style={{ margin: 'auto' }}>
                                <TextField defaultValue={paciente?.nombreTutor} {...register("nombreTutor")} onChange={handleChange} variant="outlined" label="Nombre del tutor" margin="dense" fullWidth required autoFocus helperText={errors.nombreTutor?.message} error={!!errors.nombreTutor?.message} />
                            </Grid>
                            <Grid item md={4} xs={12} style={{ margin: 'auto' }}>
                                <TextField defaultValue={paciente?.apellidosTutor} {...register("apellidosTutor")} onChange={handleChange} variant="outlined" label="Apellidos del tutor" margin="dense" fullWidth required helperText={errors.apellidosTutor?.message} error={!!errors.apellidosTutor?.message} />
                            </Grid>
                            <Grid item md={4} xs={12} style={{ margin: 'auto' }}>
                                <TextField defaultValue={paciente?.DNItutor} {...register("DNItutor")} onChange={() => {handleChange; setErrorDNI('')}} variant="outlined" label={passport ? "Pasaporte del tutor" : "DNI o NIE del tutor"} margin="dense" fullWidth required helperText={errorDNI} error={!!errorDNI} InputProps={{ endAdornment: (<InputAdornment position="end"><Switch checked={passport} onChange={() => {setPassport(!passport); setErrorDNI('')}} inputProps={{ 'aria-label': 'controlled' }} /></InputAdornment>) }} />
                            </Grid>

                            <Grid style={{ margin: 'auto' }}>
                                {loading ? <CircularProgress /> :
                                    <>
                                        <Button sx={{ margin: theme.spacing(2, 1.2, 1) }} variant="contained" color="primary" type="submit" >Guardar</Button>
                                        <Button sx={{ margin: theme.spacing(2, 1.2, 1) }} variant="contained" color="secondary" onClick={clear}>Cancelar</Button>
                                    </>
                                }
                            </Grid>

                        </Grid>
                    </form>

                </Paper>

                <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert variant="filled" onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>{alert.msg}</Alert>
                </Snackbar>
            </Container>
        );
    } catch (e: any) {
        const error: ILog = { method: 'TutorForm.Component', level: 'error', message: e.message, meta: e.message };
        LogService.logError(error);
        return (
            <Alert severity="error">{e.message}</Alert>
        );
    }

};

export default TutorForm;